import React from 'react';
import { Dialog } from '../../../components/Dialog';

interface Props {
    open: boolean;
    onClose: () => void;
}

export default function VersionDialog(props: Props) {
    return (
        <Dialog
            title="New version"
            open={props.open}
            actions={[
                {
                    onClick: () => {
                        window.location.reload();
                    },
                    children: 'Refresh',
                },
                {
                    onClick: () => {
                        props.onClose();
                    },
                    children: 'Close',
                },
            ]}>
            <p>Dear user, a new version of this website has been released. Please refresh your browser to get the latest version.</p>
            <p>If you have unsaved changes close the dialog and save before refreshing.</p>
        </Dialog>
    );
}
