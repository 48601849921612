import React, { Component } from 'react';
import sumBy from 'lodash/sumBy';
import { calculateDiscountedPrice } from '../../functions/calculateDiscountedPrice';
import { formatPrice } from '../../functions/formatPrice';
import { mergeCurrencies } from '../../functions/mergeCurrencies';
import { Quote } from '../../services/backendTypes';

interface Props {
    data: Quote;
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
}

export class TotalQuoteValue extends Component<Props> {
    render(): JSX.Element {
        const { items } = this.props.data;
        const { hideWholesalePrices, hideEndConsumerPrices } = this.props;
        const currency = mergeCurrencies(items.map((item) => item.quotationCurrency));
        const total = {
            price: sumBy(items, (item) => item.valid !== false ? item.fullPrice || 0 : 0),
            currency: currency,
        };
        const endConsumerTotal = {
            price: sumBy(items, (item) => calculateDiscountedPrice(item.endConsumerPrice, item.endConsumerDiscount)),
            currency: currency,
        };
        const totalWholesalePrice = {
            price: sumBy(items, (item) => item.valid !== false ? item.price || 0 : 0),
            currency: currency,
        };
        return (
            <div className="c--total-quote-value-2">
                {!hideWholesalePrices && (
                    <div>
                        <span className="label">Wholesale price:</span>
                        <span>{formatPrice(totalWholesalePrice.price, totalWholesalePrice.currency) || '---'}</span>
                    </div>
                )}
                {!hideWholesalePrices && (
                    <div>
                        <span className="label">Total value:</span>
                        <span>{formatPrice(total.price, total.currency) || '---'}</span>
                    </div>
                )}
                {!hideEndConsumerPrices && (
                    <div>
                        <span className="label">Price:</span>
                        <span>{formatPrice(endConsumerTotal.price, endConsumerTotal.currency) || '---'}</span>
                    </div>
                )}
            </div>
        );
    }
}
