import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Icon from '@mui/material/Icon';

import Button from '../../components/formFields/Button';
import MenuButton from '../../components/formFields/MenuButton';
import { formService } from '../../services/formService';
import { combineSpinners, SpinnerProps } from '../../components/Spinner/Spinner';

interface Props {
    menuItems: MenuItem[];
}

interface MenuItem {
    slot: 'left' | 'right';
    type?: 'button' | 'submit',
    displayName: string;
    icon: string;
    disabled: boolean;
    visible?: boolean;
    spinner?: SpinnerProps;
    onClick: () => void;
    id?: string;
}

export default function BottomBar(props: Props) {
    const [valid, setValid] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const visibleMenuItems = props.menuItems.filter((menuItem) => menuItem.visible !== false);
    const leftMenuItems = visibleMenuItems.filter((menuItem) => menuItem.slot === 'left');
    const rightMenuItems = visibleMenuItems.filter((menuItem) => menuItem.slot === 'right');

    useEffect(() => {
        formService.registerChangeHandler('bottom-bar', (valid) => {
            setValid(valid);
        }, '^header-card--');

        return () => {
            formService.unregisterChangeHandler('bottom-bar');
        };
    }, []);

    function getMenuItemDisabled(menuItem: MenuItem) {
        if (menuItem.type === 'submit' && !valid) {
            return true;
        }
        return menuItem.disabled;
    }

    return (
        <div className="c--bottom-bar">
            <div className="left-slot">
                {isMobile ? (
                    <MenuButton
                        menuItems={leftMenuItems.map((menuItem) => ({
                            primaryText: menuItem.displayName,
                            onClick: menuItem.onClick,
                            disabled: getMenuItemDisabled(menuItem),
                            id: menuItem.id,
                        }))}
                        buttonProps={{
                            className: 'menu-button',
                            icon: <Icon>keyboard_arrow_up</Icon>,
                            spinner: combineSpinners(leftMenuItems.map((menuItem) => menuItem.spinner)),
                            id: 'bottom-bar-mobile-button',
                        }}
                    />
                ) : leftMenuItems.map((menuItem, index) => (
                    <Button
                        key={index}
                        id={menuItem.id}
                        icon={menuItem.icon}
                        disabled={getMenuItemDisabled(menuItem)}
                        spinner={menuItem.spinner}
                        onClick={menuItem.onClick}>
                        {menuItem.displayName}
                    </Button>
                ))}
            </div>
            <div className="right-slot">
                {rightMenuItems.map((menuItem, index) => (
                    <Button
                        key={index}
                        id={menuItem.id}
                        icon={menuItem.icon}
                        disabled={getMenuItemDisabled(menuItem)}
                        spinner={menuItem.spinner}
                        onClick={menuItem.onClick}>
                        {menuItem.displayName}
                    </Button>
                ))}
            </div>
        </div>
    );
}
