import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

interface Props {
    data;
}

export default function AdvancedTable(props: Props) {
    return (
        <div className="c--advanced-debug-table">
            <RecursiveTable {...props} />
        </div>
    );
}

function RecursiveTable(props): ReactElement {
    if (isArray(props.data)) {
        return (
            <div className="table-group-wrapper">
                {props.data.map((item, index) => (
                    <div className="table-wrapper" key={index}>
                        {props.data.length > 1 && (
                            <div className="table-header"><span>{index + 1}</span></div>
                        )}
                        <RecursiveTable data={item} />
                    </div>
                ))}
            </div>
        );
    } else if (isPlainObject(props.data)) {
        return (
            <Table>
                <TableBody>
                    {Object.keys(props.data).map((key, index) => (
                        <TableRow key={index}>
                            <TableCell><span className="key-wrapper">{key}</span></TableCell>
                            <TableCell><RecursiveTable data={props.data[key]} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    } else {
        return (
            <span className="value-wrapper">{String(props.data)}</span>
        );
    }
}
