import uniq from 'lodash/uniq';

export function mergeCurrencies(currencies: string[]): string {
    const filteredCurrencies = currencies.filter((currency) => !!currency);
    const uniqCurrencies = uniq(filteredCurrencies);

    if (uniqCurrencies.length > 1) {
        console.error(`Error: Found multiple currencies: ${uniqCurrencies.join(', ')}. Defaulting to ${uniqCurrencies[0]}.`);
    }

    return uniqCurrencies[0];
}
