import { convertToNumber } from './convertToNumber';

export function safeMultiply(value1: number, value2: number) {
    return convertToNumber(value1, true) * convertToNumber(value2, true);
}

export function safeSubract(value1: number, value2: number) {
    return convertToNumber(value1, true) - convertToNumber(value2, true);
}

export function safeDivide(value1: number, value2: number) {
    return convertToNumber(value1, true) / convertToNumber(value2, true);
}
