import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import cloneDeep from 'lodash/cloneDeep';

import TextField from '../../components/formFields/TextField';
import Button from '../../components/formFields/Button';
import DataTable from '../../components/DataTable';
import { FilterParameters, PagedTemplate } from '../../services/backendTypes';
import { backendService } from '../../services/backendService';
import { AppDispatch, ReduxState } from '../../store';
import * as appActions from '../../actions';
import format from 'date-fns/format';

const defaultFilter: FilterParameters = {
    search: null,
    sort: 'createdAt',
    direction: 'desc',
    page: 1,
    count: 20,
};

export default function TemplateList() {
    const location = useLocation();
    const history = useHistory();
    const customerNo = useSelector((state: ReduxState) => state.auth.currentCustomer?.key);
    const dispatch = useDispatch<AppDispatch>();

    const [searchString, setSearchString] = useState('');
    const [filter, setFilter] = useState(cloneDeep(defaultFilter));
    const [tableData, setTableData] = useState<PagedTemplate[]>([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [pendingTableData, setPendingTableData] = useState(false);
    const [pendingRemove, setPendingRemove] = useState<{ [key: string]: boolean }>({});
    const [pendingOpenOrderForm, setPendingOpenOrderForm] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params.has('search')) {
            setSearchString(params.get('search'));
            populateTable({ search: params.get('search') });
        } else {
            populateTable();
        }
    }, []);

    useEffect(() => {
        populateTable(cloneDeep(defaultFilter));
    }, [customerNo]);

    function populateTable(nextFilter?: Partial<FilterParameters>) {
        setPendingTableData(true);
        backendService.getTemplatesByPage(customerNo, { ...filter, ...nextFilter }).then(
            (response) => {
                setTableData(response.list);
                setRowTotal(response.total);
                setPendingTableData(false);
            },
            () => {
                setPendingTableData(false);
            },
        );
        setFilter((prevValue) => ({ ...prevValue, ...nextFilter }));
    }

    return (
        <div className="c--template-list">
            <Paper elevation={2} className="search-block">
                <form
                    className="search-field-wrapper"
                    onSubmit={(event) => {
                        event.preventDefault();
                        populateTable({ search: searchString, page: 1 });
                    }}>
                    <TextField
                        placeholder="Search template"
                        value={searchString}
                        onChange={(value) => {
                            setSearchString(value);
                        }}
                    />
                    <div>
                        <Button
                            type="submit"
                            icon="search"
                        />
                    </div>
                </form>
            </Paper>
            <div className="container">
                <Paper elevation={2} className="table-block">
                    {pendingTableData && (
                        <LinearProgress id="table-progress" className="table-progress" />
                    )}
                    <DataTable
                        columns={[
                            {
                                key: 'templateName',
                                title: 'Name',
                            },
                            {
                                key: 'createdAt',
                                title: 'Created at',
                                format: (column: string) => {
                                    return format(new Date(column), 'yyyy-MM-dd');
                                },
                            },
                        ]}
                        rows={tableData}
                        rowActions={[
                            {
                                displayName: 'New quote from template',
                                icon: 'note_add',
                                isQuickAction: true,
                                isPending: (row) => pendingOpenOrderForm[row.id],
                                onClick: (row) => {
                                    setPendingOpenOrderForm((prevValue) => ({ ...prevValue, [row.id]: true }));
                                    return backendService.getTemplate(row.id).then((template) => {
                                        const items = template.items;
                                        setPendingOpenOrderForm((prevValue) => ({ ...prevValue, [row.id]: false }));
                                        history.push(`/auth/${customerNo}/new-quote`, { formData: { items } });
                                    });
                                },
                            },
                            {
                                displayName: 'Remove template',
                                icon: 'delete',
                                isQuickAction: true,
                                isPending: (row) => pendingRemove[row.id],
                                onClick: (row) => {
                                    return dispatch(appActions.confirm('Are you sure?')).then((response) => {
                                        if (response === true) {
                                            setPendingRemove((prevValue) => ({ ...prevValue, [row.id]: true }));
                                            return backendService.deleteTemplate(row.id).then(() => {
                                                setPendingRemove((prevValue) => ({ ...prevValue, [row.id]: false }));
                                                populateTable();
                                            });
                                        }
                                    });
                                },
                            },
                        ]}
                        activeSort={filter.sort}
                        sortDirection={filter.direction}
                        rowTotal={rowTotal}
                        page={filter.page}
                        rowsPerPage={filter.count}
                        onSort={(sort, direction) => {
                            populateTable({ sort, direction });
                        }}
                        onPageChange={(page) => {
                            populateTable({ page });
                        }}
                        onRowsPerPageChange={(count) => {
                            populateTable({ count, page: 1 });
                        }}
                    />
                </Paper>
            </div>
        </div>
    );
}
