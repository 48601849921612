import React from 'react';
import { RouteComponentProps } from 'react-router';
import format from 'date-fns/format';
import { LogoIcon } from '../../components/icons/LogoIcon';
import LoginForm from './LoginForm';
import { AuthConfig } from '../../services/backendTypes';
import { localStorageService } from '../../services/localStorageService';

export class Login extends React.Component<RouteComponentProps> {

    handleLoginSuccess(config: AuthConfig) {
        const selectedCustomerKey = localStorageService.getItem('selectedCustomer');
        if (config.customers.find((customer) => customer.key === selectedCustomerKey)) {
            this.props.history.push('/auth/' + selectedCustomerKey);
        } else {
            const firstCustomerKey = config.customers[0]?.key;
            if (firstCustomerKey) {
                this.props.history.push('/auth/' + firstCustomerKey);
            }
        }
    }

    versionDate(): string {
        return window['buildDate'] !== null ? format(new Date(window['buildDate']), 'y/MM/dd') : 'local';
    }

    render() {
        return (
            <div className="c--login">
                <span className="build-date"> Build date: {this.versionDate()}</span>
                <div className="centered-box">
                    <LogoIcon className="logo" />
                    <LoginForm onLoginSuccess={this.handleLoginSuccess.bind(this)} />
                </div>
            </div>
        );
    }

}
