import React from 'react';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import format from 'date-fns/format';

import TextField from '../../components/formFields/TextField';
import { TotalOrderValue } from './TotalOrderValue';
import { DELIVERY_TERMS, DELIVERY_LOCATION } from '../../constants/deliveryInfo';
import { ItemPrice } from './ItemPrice';
import { LegacyQuote } from '../../services/legacyBackendTypes';

interface Props {
    data: LegacyQuote;
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
}

export class OrderDetails extends React.Component<Props> {

    render() {
        return (
            <article className="c--order-details">
                <section>
                    <h1>Order</h1>
                    <div className="sub-section">
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Order number" value={this.props.data.incrementId?.toString() || '---'} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Created by" value={`${this.props.data.createdBy.contact.firstname} ${this.props.data.createdBy.contact.lastname}`} />
                            </Grid>
                            <Hidden mdUp>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Created at" value={format(new Date(this.props.data.createdTime), 'yyyy-MM-dd')} />
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="PO number/name" value={this.props.data.purchaseOrderNumber || '---'} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Reference number" value={this.props.data.referenceNumber || '---'} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Campaign code" value={this.props.data.campaignCode || (this.props.data.idpCode ? 'IDP: ' + this.props.data.idpCode : '---')} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Delivery terms" value={DELIVERY_TERMS[this.props.data.order.deliveryTerms] || '---'} />
                            </Grid>
                            {this.props.data.order.deliveryTerms === 'express' && !!this.props.data.order.deliveryLocation && (
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Delivery location" value={DELIVERY_LOCATION[this.props.data.order.deliveryLocation] || '---'} />
                                </Grid>
                            )}
                            {this.props.data.order.deliveryAddress && (
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Delivery address number" value={this.props.data.order.deliveryAddress} />
                                </Grid>
                            )}
                            {this.props.data.order.deliveryTo && (
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Delivery address" value={this.props.data.order.deliveryTo.name} />
                                    <div>
                                        <div>{this.props.data.order.deliveryTo.street}</div>
                                        <div>{this.props.data.order.deliveryTo.city}</div>
                                        <div>{this.props.data.order.deliveryTo.country} {this.props.data.order.deliveryTo.zipcode}</div>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </section>
                <section>
                    <h1>Customer</h1>
                    {!!this.props.data.endConsumer && (
                        <div className="sub-section">
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Title" value={this.props.data.endConsumer.title || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="First name" value={this.props.data.endConsumer.firstname || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Last name" value={this.props.data.endConsumer.lastname || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Email" value={this.props.data.endConsumer.email || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Phone number" value={this.props.data.endConsumer.phone || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Street address" value={this.props.data.endConsumer.street || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Apartment number" value={this.props.data.endConsumer.apartmentNumber || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Zip code" value={this.props.data.endConsumer.zipCode || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="City" value={this.props.data.endConsumer.city || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Country" value={this.props.data.endConsumer.country || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Region" value={this.props.data.endConsumer.region || '---'} />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    {!this.props.data.endConsumer && (
                        <span className="no-customer">No customer</span>
                    )}
                </section>
                {!!this.props.data.beds.length && (
                    <section>
                        <h1>Beds</h1>
                        {this.props.data.beds.map((bed, bedIndex) => (
                            <div className="sub-section" key={bedIndex}>
                                <Grid container spacing={2}>
                                    {bed.name && (
                                        <Grid item xs={12} lg={3}>
                                            <TextField readOnly label="Bed type" value={bed.name} />
                                        </Grid>
                                    )}
                                    {bed.parametersDetails.map((parameter, parameterIndex) => !parameter.valueLabel ? null : (
                                        <Grid item xs={12} lg={3} key={parameterIndex}>
                                            <TextField readOnly label={parameter.keyLabel} value={parameter.valueLabel} />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} lg={3}>
                                        <TextField readOnly label="Bed quantity" value={bed.quantity?.toString()} />
                                    </Grid>
                                </Grid>
                                <ItemPrice
                                    item={bed}
                                    hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                                    hideWholesalePrices={this.props.hideWholesalePrices}
                                />
                            </div>
                        ))}
                    </section>
                )}
                {!!Object.keys(this.props.data.accessories).length && (
                    <section>
                        {Object.keys(this.props.data.accessories).map((categoryKey, categoryIndex) => (
                            <div key={categoryIndex}>
                                <h1>{categoryKey}</h1>
                                {this.props.data.accessories[categoryKey].map((accessory, accessoryIndex) => (
                                    <div className="sub-section" key={accessoryIndex}>
                                        <Grid container spacing={2}>
                                            {!!accessory.level2 && (
                                                <Grid item xs={12} lg={3}>
                                                    <TextField readOnly label="Category" value={accessory.level2} />
                                                </Grid>
                                            )}
                                            {!!accessory.name && (
                                                <Grid item xs={12} lg={3}>
                                                    <TextField readOnly label="Name" value={accessory.name} />
                                                </Grid>
                                            )}
                                            {accessory.parametersDetails.map((parameter, parameterIndex) => (
                                                <Grid item xs={12} lg={3} key={parameterIndex}>
                                                    <TextField readOnly label={parameter.keyLabel} value={parameter.valueLabel || '---'} />
                                                </Grid>
                                            ))}
                                            <Grid item xs={12} lg={3}>
                                                <TextField readOnly label="Quantity" value={accessory.quantity?.toString()} />
                                            </Grid>
                                        </Grid>
                                        <ItemPrice
                                            item={accessory}
                                            hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                                            hideWholesalePrices={this.props.hideWholesalePrices}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </section>
                )}
                {!!this.props.data.excludedItems.length && (
                    <section>
                        <div>
                            <h1>Excluded Items</h1>
                            <div className="sub-section" key={0}>
                                {this.props.data.excludedItems.map((excludedItem, index) => (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={12} lg={4}>
                                            <TextField readOnly label="Sku" value={excludedItem.sku}/>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <TextField readOnly label="Description" value={excludedItem.description}/>
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField readOnly label="Quantity" value={excludedItem.quantity.toString()}/>
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
                <TotalOrderValue
                    data={this.props.data}
                    hideWholesalePrices={this.props.hideWholesalePrices}
                    hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                />
            </article>
        );
    }
}
