import React from 'react';
import { StaticContext, useParams } from 'react-router';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import OrderForm, { OrderFormLocationState, OrderFormRouteParams } from '../../containers/OrderForm/OrderForm';
import OrderList from '../../containers/OrderList/OrderList';
import QuoteList from '../../containers/QuoteList/QuoteList';
import TemplateList from '../../containers/TemplateList/TemplateList';
import { AuthProps } from './Auth';
import { RouteLoader } from './RouteLoader';

export function Routes(props: AuthProps) {
    const { customerKey } = useParams<{ customerKey: string }>();
    const pendingAuthData = props.auth.spinners.accessories.spinning || props.auth.beds.spinner.spinning;

    return (
        <Switch>
            <Route
                exact
                path={props.match.url}
                component={(route: RouteComponentProps) => {
                    route.history.replace(`${props.match.url}/new-quote`);
                    return null;
                }}
            />
            <Route
                exact
                path={`${props.match.url}/new-quote`}
                render={(route: RouteComponentProps<OrderFormRouteParams, StaticContext, OrderFormLocationState>) => {
                    // Setting the key to the customer key will force the OrderForm component to remount when switching between customers
                    return pendingAuthData ? <RouteLoader /> : <OrderForm {...props} {...route} key={customerKey} />;
                }}
            />
            <Route
                exact
                path={`${props.match.url}/quote/:quoteId`}
                render={(route: RouteComponentProps<OrderFormRouteParams, StaticContext, OrderFormLocationState>) => {
                    // Setting the key to the quote id will force the OrderForm component to remount when switching between quotes
                    return pendingAuthData ? <RouteLoader /> : <OrderForm {...props} {...route} key={route.match.params.quoteId} />;
                }}
            />
            <Route
                exact
                path={`${props.match.url}/orders`}
                component={OrderList}
            />
            <Route
                exact
                path={`${props.match.url}/quotes`}
                component={QuoteList}
            />
            <Route
                exact
                path={`${props.match.url}/templates`}
                component={TemplateList}
            />
            <Route
                component={(route: RouteComponentProps) => {
                    route.history.replace(props.match.url);
                    return null;
                }}
            />
        </Switch>
    );
}
