import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const latinOneValidator: Validator = {
    validate: (value: string) => {
        const error = {
            errorMessage: errorMessages.invalidCharacters,
        };
        if (!value) {
            return null;
        }
        const valid = !/[^\u0000-\u00ff]/g.test(value);

        return valid ? null : error;
    },
};
