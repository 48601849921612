import { createAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

import { backendService, ErrorData } from '../../services/backendService';
import { addKeys } from '../../functions/addKeys';
import * as orderFormFunctions from './functions';
import * as authActions from '../../layouts/Auth/actions';
import { OrderFormAccessoryItem, OrderFormBedItem, OrderFormData, SimplifiedQuoteBuilderResponseItem } from './reducer';
import { OrderRequestData, Notification, Quote, QuoteBuilderResponseItem, QuoteRequestData, QuoteBuilderRequestData, TemplateRequestData } from '../../services/backendTypes';
import { AppThunkAction } from '../../store';
import { detectChanges } from '../../functions/detectChanges';
import { CUSTOMER_SERVICE_USER } from '../../appConfig';
import { AxiosError } from 'axios';

const materialsQueue: {
    requestData: QuoteBuilderRequestData;
    timeout: number;
} = {
    requestData: {
        id: null,
        customerNumber: null,
        campaignCode: null,
        idpCode: null,
        orderType: null,
        items: [],
    },
    timeout: null,
};

export function loadQuote(id: number): AppThunkAction<Promise<any>> {
    return (dispatch, getState) => {
        return backendService.getQuote(id).then((response) => {
            dispatch(setLoadQuoteComplete({
                originalQuote: cloneDeep(response),
                quote: orderFormFunctions.removeInvalidData(addKeys(response), getState().auth.accessories),
            }));
            return dispatch(loadRelatedQuoteData());
        });
    };
}

export function loadRelatedQuoteData(): AppThunkAction<Promise<any>> {
    return (dispatch, getState) => {
        const promises = [];

        const bedTypes = uniq<string>(getState().orderForm.data.items
            .filter((item) => item.itemType === 'bed')
            .map((item: OrderFormBedItem) => item.type)
            .filter((bedType) => !getState().auth.bedProperties[bedType]));

        const formData = getState().orderForm.data.items.map((item) => {
            switch (item.itemType) {
                case 'bed':
                    return orderFormFunctions.prepareRequestDataBedItem(item);
                case 'accessory':
                    return orderFormFunctions.prepareRequestDataAccessoryItem(item);
            }
        });

        if (bedTypes.length) {
            promises.push(dispatch(authActions.loadBedProperties(bedTypes)));
        }

        if (formData.length) {
            promises.push(dispatch(loadMaterials({
                id: getState().orderForm.data.id,
                customerNumber: getState().auth.currentCustomer.key,
                campaignCode: getState().orderForm.data.campaignCode,
                idpCode: getState().orderForm.data.idpCode,
                orderType: getState().orderForm.data.orderType,
                items: formData,
            }, true)));
        }

        return Promise.all(promises);
    };
}

export const setLoadQuoteComplete = createAction<{ originalQuote: Quote; quote: OrderFormData; }>('LOAD_QUOTE_COMPLETE');

export const setFormIsReady = createAction('SET_FORM_IS_READY');

export const setFormIsUnavailable = createAction('SET_FORM_IS_UNAVAILABLE');

export const resetOrderForm = createAction<Quote>('RESET_ORDER_FORM');

export const updateOrderFormContent = createAction<Partial<OrderFormData>>('UPDATE_ORDER_FORM');

export const updateOrderFormBeds = createAction<{ key: string; values: Partial<OrderFormBedItem> }[]>('UPDATE_ORDER_FORM_BEDS');

export const updateOrderFormAccessories = createAction<{ key: string; values: Partial<OrderFormAccessoryItem> }[]>('UPDATE_ORDER_FORM_ACCESSORIES');

export const addOrderFormBed = createAction('ADD_ORDER_FORM_BED');

export const addOrderFormAccessory = createAction<string>('ADD_ORDER_FORM_ACCESSORY');

export const duplicateOrderFormBed = createAction<string>('DUPLICATE_ORDER_FORM_ITEM');

export const removeOrderFormItem = createAction<string>('REMOVE_ORDER_FORM_ITEM');

export function saveQuote(data: QuoteRequestData): AppThunkAction<Promise<Quote>> {
    return (dispatch, getState) => {
        dispatch(setSaveQuotePending());
        return backendService.saveQuote(data).then(
            (response) => {
                dispatch(setSaveQuoteComplete());

                /**
                 * Proof of concept
                 */
                window['dataLayer']?.push({
                    event: data.id ? 'update_quote' : 'create_quote',
                    config_user_type: getState().app.config.account.username === CUSTOMER_SERVICE_USER ? 'customer_service' : 'default',
                });

                return response;
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setSaveQuoteRejected(error?.response?.data?.message));
                return Promise.reject(error);
            },
        );
    };
}

export const setSaveQuotePending = createAction('SAVE_QUOTE_PENDING');

export const setSaveQuoteComplete = createAction('SAVE_QUOTE_COMPLETE');

export const setSaveQuoteRejected = createAction<string>('SAVE_QUOTE_REJECTED');

export function createOrder(id: number, data: OrderRequestData, trackingData): AppThunkAction<Promise<Quote>> {
    return (dispatch, getState) => {
        dispatch(setCreateOrderPending());
        return backendService.createOrder(id, data).then(
            (response) => {
                dispatch(setCreateOrderComplete());

                /**
                 * Proof of concept
                 */
                window['dataLayer']?.push({
                    event: 'create_order',
                    config_user_type: getState().app.config.account.username === CUSTOMER_SERVICE_USER ? 'customer_service' : 'default',
                    config_delivery_terms: response.order.deliveryTerms,
                });
                if (trackingData.length) {
                    window['dataLayer']?.push({
                        event: 'edit_bed',
                        removed: trackingData,
                    });
                }

                return response;
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setCreateOrderRejected(error?.response?.data?.message));
                return Promise.reject(error);
            },
        );
    };
}

export const setCreateOrderPending = createAction('CREATE_ORDER_PENDING');

export const setCreateOrderComplete = createAction('CREATE_ORDER_COMPLETE');

export const setCreateOrderRejected = createAction<string>('CREATE_ORDER_REJECTED');

export function addToMaterialsQueue(requestData: QuoteBuilderRequestData): AppThunkAction {
    return (dispatch) => {
        if (detectChanges(materialsQueue.requestData, requestData, ['id', 'customerNumber', 'campaignCode', 'idpCode', 'orderType'])) {
            materialsQueue.requestData = requestData;
        } else {
            for (const item of requestData.items) {
                const index = materialsQueue.requestData.items.findIndex((queueItem) => item.id === queueItem.id);

                if (index === -1) {
                    materialsQueue.requestData.items.push(item);
                } else {
                    materialsQueue.requestData.items[index] = item;
                }
            }
        }

        if (materialsQueue.timeout) {
            clearTimeout(materialsQueue.timeout);
        }

        materialsQueue.timeout = window.setTimeout(() => {
            const nextRequestData = cloneDeep(materialsQueue.requestData);
            materialsQueue.requestData.items = [];

            if (!nextRequestData.items.length) {
                return;
            }
            dispatch(loadMaterials(nextRequestData));
        }, 300);
    };
}

export function loadMaterials(requestData: QuoteBuilderRequestData, initialRequest = false): AppThunkAction {
    return (dispatch, getState) => {
        if (!initialRequest) {
            dispatch(setMaterialsPending(requestData.items.map((item) => ({ key: item.id }))));
        }

        /**
         * Proof of concept
         */
        requestData.items.forEach((requestDataItem) => {
            const quoteItem = getState().orderForm.data.items.find((item) => item._key === requestDataItem.id);

            if (quoteItem.itemType === 'bed') {
                window['dataLayer']?.push({
                    event: 'get_price',
                    config_show_end_consumer_prices: !getState().auth.hideEndConsumerPrices,
                    config_show_wholesale_prices: !getState().auth.hideWholesalePrices,
                    config_bottom_list: requestDataItem.configuration.bottom_list,
                    config_color: requestDataItem.configuration.color,
                    config_firmness: requestDataItem.configuration.firmness,
                    config_left_nameplate: requestDataItem.configuration.left_nameplate,
                    config_legs: requestDataItem.configuration.legs,
                    config_length: requestDataItem.configuration.length,
                    config_middle_leg_link: requestDataItem.configuration.middle_leg_link,
                    config_nameplate: requestDataItem.configuration.nameplate,
                    config_nameplate_type: requestDataItem.configuration.nameplate_type,
                    config_right_nameplate: requestDataItem.configuration.right_nameplate,
                    config_split_base: requestDataItem.configuration.split_base,
                    config_split_mattress: requestDataItem.configuration.split_mattress,
                    config_split_top_mattress: requestDataItem.configuration.split_top_mattress,
                    config_top_mattress_type: requestDataItem.configuration.top_mattress_type,
                    config_trim: requestDataItem.configuration.trim,
                    config_type: requestDataItem.configuration.type,
                    config_vividus_legs: requestDataItem.configuration.vividus_legs,
                    config_width: requestDataItem.configuration.width,
                    config_quantity: requestDataItem.configuration.quantity,
                    config_campaign_code: requestData.campaignCode,
                    config_user_type: getState().app.config.account.username === CUSTOMER_SERVICE_USER ? 'customer_service' : 'default',
                });
            }
        });

        return backendService.getMaterials(requestData).then(
            (response) => {
                const materialChanges = [];
                const bedChanges = [];
                const accessoryChanges = [];

                for (const responseItem of response.items) {
                    const quoteItem = getState().orderForm.data.items.find((item) => item._key === responseItem.id);
                    const simplifiedResponseItem = orderFormFunctions.simplifyQuoteBuilderResponseItem(responseItem);

                    if (!quoteItem) {
                        continue;
                    }

                    materialChanges.push({
                        key: responseItem.id,
                        data: responseItem,
                        simplifiedData: simplifiedResponseItem,
                    });

                    if (!initialRequest || !quoteItem.endConsumerPrice) {
                        const quoteItemChange = {
                            key: responseItem.id,
                            values: {
                                endConsumerPrice: simplifiedResponseItem.recommendedPrice,
                                quotationCurrency: simplifiedResponseItem.quotationCurrency,
                            },
                        };

                        switch (quoteItem.itemType) {
                            case 'bed':
                                bedChanges.push(quoteItemChange);
                                break;
                            case 'accessory':
                                accessoryChanges.push(quoteItemChange);
                                break;
                        }
                    }
                }

                if (materialChanges.length) {
                    dispatch(setMaterialsComplete(materialChanges));
                }
                if (bedChanges.length) {
                    dispatch(updateOrderFormBeds(bedChanges));
                }
                if (accessoryChanges.length) {
                    dispatch(updateOrderFormAccessories(accessoryChanges));
                }
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setMaterialsRejected(requestData.items.map((item) => ({ key: item.id, errorMessage: error?.response?.data?.message }))));
            },
        );
    };
}

export const setMaterialsPending = createAction<{ key: string; }[]>('LOAD_MATERIALS_PENDING');

export const setMaterialsComplete = createAction<{ key: string; data: QuoteBuilderResponseItem; simplifiedData: SimplifiedQuoteBuilderResponseItem }[]>('LOAD_MATERIALS_COMPLETE');

export const setMaterialsRejected = createAction<{ key: string; errorMessage: string; }[]>('LOAD_MATERIALS_REJECTED');

export const removeMaterials = createAction<string>('REMOVE_MATERIALS');

export const resetBedPrices = createAction<string>('RESET_BED_PRICES');

export function getNotifications(): AppThunkAction {
    return (dispatch) => {
        return backendService.getActiveNotifications().then((response) => {
            dispatch(setNotificationsComplete(response));
        });
    };
}

export const setNotificationsComplete = createAction<Notification[]>('GET_NOTIFICATIONS_COMPLETE');

export const notificationsRead = createAction<Notification[]>('NOTIFICATIONS_READ');

export const setCreateTemplateDialogOpen = createAction('CREATE_TEMPLATE_DIALOG_OPEN');

export const setCreateTemplateDialogClosed = createAction('CREATE_TEMPLATE_DIALOG_CLOSED');

export function createTemplate(customerNo: string, data: TemplateRequestData): AppThunkAction<Promise<any>> {
    return (dispatch) => {
        dispatch(setCreateTemplatePending());
        return backendService.createTemplate(customerNo, data).then(
            () => {
                dispatch(setCreateTemplateComplete());
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setCreateTemplateRejected(error?.response?.data?.message));
                return Promise.reject(error);
            },
        );
    };
}

export const setCreateTemplatePending = createAction('CREATE_TEMPLATE_PENDING');

export const setCreateTemplateComplete = createAction('CREATE_TEMPLATE_COMPLETE');

export const setCreateTemplateRejected = createAction<string>('CREATE_TEMPLATE_REJECTED');
