import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidator: Validator = {
    validate: (value: string) => {
        const error = {
            errorMessage: errorMessages.email,
        };
        return EMAIL_REGEXP.test(value) ? null : error;
    },
};
