import { createAction } from '@reduxjs/toolkit';
import { AppThunkAction } from '../../store';

export function markAsDirtyIfPristine(): AppThunkAction {
    return (dispatch, getState) => {
        if (getState().form.dirty !== true) {
            dispatch(markAsDirty());
        }
    };
}

export const markAsDirty = createAction('MARK_FORM_AS_DIRTY');

export const markAsPristine = createAction('MARK_FORM_AS_PRISTINE');
