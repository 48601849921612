import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';

import { SortDirection } from '../services/backendTypes';
import MenuButton from '../components/formFields/MenuButton';
import Button from './formFields/Button';

interface Props<T> {
    columns: {
        key: string;
        title: string;
        format?: (column: any) => string;
    }[];
    rows: T[];
    rowActions: {
        displayName: string;
        icon?: string;
        isQuickAction?: boolean;
        isVisible?: (row: T) => boolean;
        isPending?: (row: T) => boolean;
        onClick: (row: T) => void;
    }[];
    activeSort: string;
    sortDirection?: SortDirection;
    rowTotal: number;
    page: number;
    rowsPerPage: number;
    onSort: (columnKey: string, direction: SortDirection) => void;
    onPageChange: (page: number) => void;
    onRowsPerPageChange: (rowCount: number) => void;
}

const tableStyles: SxProps<Theme> = {
    borderBottom: '1px solid #e0e0e0',
    tableLayout: { xs: 'fixed', md: 'auto' },
};

const tableCellStyles: SxProps<Theme> = {
    borderTop: '1px solid #e0e0e0',
    borderBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    height: 56,
};

const actionTableCellStyles: SxProps<Theme> = {
    ...tableCellStyles,
    padding: '0 8px',
    textAlign: 'center',
    width: 56,
};

const moreMenuTableCellStyles: SxProps<Theme> = {
    ...actionTableCellStyles,
    borderLeft: '1px solid #e0e0e0',
};

const tableSortLabelStyles: SxProps<Theme> = {
    fontSize: 14,
    fontWeight: 600,
    color: '#000',
    '&:hover': {
        color: '#000',
    },
    '&:hover .MuiTableSortLabel-icon': {
        opacity: '1',
    },
    '&.Mui-active .MuiTableSortLabel-icon': {
        color: '#000',
    },
};

export default function DataTable<T>(props: Props<T>) {
    const {
        rowActions = [],
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const visibleActions = rowActions.filter((action) => !action.isVisible);
    const moreMenuActions = visibleActions.filter((action) => !action.isQuickAction);
    const quickActions = visibleActions.filter((action) => action.isQuickAction);

    return (
        <div>
            <TableContainer>
                <Table sx={tableStyles}>
                    <TableHead>
                        <TableRow>
                            {props.columns.map((column, index) => (
                                <TableCell sx={tableCellStyles} key={index}>
                                    <TableSortLabel
                                        sx={tableSortLabelStyles}
                                        active={props.activeSort === column.key}
                                        direction={props.activeSort === column.key ? props.sortDirection : 'desc'}
                                        onClick={() => {
                                            const oppositeDirection = props.sortDirection === 'asc' ? 'desc' : 'asc';
                                            const direction = props.activeSort === column.key ? oppositeDirection : 'desc';

                                            props.onSort(column.key, direction);
                                        }}>
                                        {column.title}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {quickActions.map((action, actionIndex) => (
                                <TableCell key={actionIndex} sx={actionTableCellStyles}></TableCell>
                            ))}
                            {!!moreMenuActions.length && (
                                <TableCell sx={actionTableCellStyles}></TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {props.columns.map((column, columnIndex) => (
                                    <TableCell sx={tableCellStyles} key={columnIndex}>{column?.format ? column.format(row[column.key]) : row[column.key]}</TableCell>
                                ))}
                                {quickActions.map((action, actionIndex) => (
                                    <TableCell key={actionIndex} sx={actionTableCellStyles}>
                                        <Button
                                            icon={action.icon}
                                            tooltipLabel={action.displayName}
                                            tooltipPosition="right"
                                            spinner={action.isPending ? { spinning: action.isPending(row) } : undefined}
                                            onClick={() => {
                                                action.onClick(row);
                                            }}
                                        />
                                    </TableCell>
                                ))}
                                {!!moreMenuActions.length && (
                                    <TableCell sx={moreMenuTableCellStyles}>
                                        <MenuButton
                                            buttonProps={{
                                                icon: 'menu',
                                                spinner: { spinning: moreMenuActions.some((action) => action.isPending ? action.isPending(row) : false) },
                                            }}
                                            menuItems={moreMenuActions.map((action) => ({
                                                primaryText: action.displayName,
                                                onClick: () => {
                                                    action.onClick(row);
                                                },
                                            }))}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                labelRowsPerPage={isMobile ? 'Per page' : 'Items per page'}
                rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                count={props.rowTotal}
                page={props.page - 1}
                rowsPerPage={props.rowsPerPage}
                onPageChange={(event, pageNumber) => {
                    props.onPageChange(pageNumber + 1);
                }}
                onRowsPerPageChange={(event) => {
                    props.onRowsPerPageChange(parseInt(event.target.value));
                }}
            />
        </div>
    );
}
