import React from 'react';
import clsx from 'clsx';

interface BedHeaderProps {
    color: {
        image: string;
    };
    type: {
        displayName: string;
    };
}

export const BedHeader = (props: BedHeaderProps) => (
    <div className="c--bed-header">
        <div
            className={clsx('color-image', { empty: !props.color })}
            style={{ backgroundImage: props.color ? `url(${props.color.image})` : null }}>
        </div>
        {props.type && (
            <span className="text">{props.type.displayName}</span>
        )}
    </div>
);
