export const TITLES = [
    {
        label: 'Miss',
        value: 'miss',
    },
    {
        label: 'Mr',
        value: 'mr',
    },
    {
        label: 'Mrs',
        value: 'mrs',
    },
    {
        label: 'None',
        value: null,
    },
];
