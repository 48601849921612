import { createReducer } from '@reduxjs/toolkit';
import { SpinnerProps } from '../../components/Spinner/Spinner';
import { PagedQuote, PagedResponse, Quote } from '../../services/backendTypes';
import * as quoteListActions from './actions';

interface QuoteListReduxState {
    data: PagedResponse<PagedQuote>;
    loadDataPending: boolean;
    fullData: { [quoteId: number]: Quote };
    loadFullDataSpinner: { [quoteId: number]: SpinnerProps };
    tableRowIsExpanded: { [quoteId: number]: boolean };
    moreMenuSpinner: { [quoteId: number]: SpinnerProps };
}

const initialState = {
    data: {
        list: [],
        total: 0,
    },
    loadDataPending: false,
    fullData: {},
    loadFullDataSpinner: {},
    tableRowIsExpanded: {},
    moreMenuSpinner: {},
} as QuoteListReduxState;

export const quoteList = createReducer(initialState, (builder) => {
    builder.addCase(quoteListActions.setLoadQuotesPending, (state) => {
        state.loadDataPending = true;
    });
    builder.addCase(quoteListActions.setLoadQuotesComplete, (state, action) => {
        state.data = action.payload;
        state.loadDataPending = false;
        state.loadFullDataSpinner = {};
        state.tableRowIsExpanded = {};
        state.moreMenuSpinner = {};
    });
    builder.addCase(quoteListActions.setLoadQuotesRejected, (state) => {
        state.loadDataPending = false;
    });
    builder.addCase(quoteListActions.setLoadQuotePending, (state, action) => {
        state.loadFullDataSpinner[action.payload] = { spinning: true };
    });
    builder.addCase(quoteListActions.setLoadQuoteComplete, (state, action) => {
        state.fullData[action.payload.id] = action.payload;
        if (state.loadFullDataSpinner[action.payload.id]) {
            delete state.loadFullDataSpinner[action.payload.id];
        }
    });
    builder.addCase(quoteListActions.expandTableRow, (state, action) => {
        state.tableRowIsExpanded[action.payload] = true;
    });
    builder.addCase(quoteListActions.collapseTableRow, (state, action) => {
        state.tableRowIsExpanded[action.payload] = false;
    });
    builder.addCase(quoteListActions.setMoreMenuPending, (state, action) => {
        state.moreMenuSpinner[action.payload] = { spinning: true };
    });
    builder.addCase(quoteListActions.setMoreMenuComplete, (state, action) => {
        if (state.moreMenuSpinner[action.payload]) {
            delete state.moreMenuSpinner[action.payload];
        }
    });
});
