import * as Sentry from '@sentry/react';
import * as Tracing from '@sentry/tracing';
import axios from 'axios';

enum Environments {
    Production = 'production',
    Stage = 'uat',
}

enum Branches {
    Master = 'master',
    Develop = 'develop',
}

export class SentryService {
    static getEnvironment(_branchName: string | undefined): string | undefined {
        switch (_branchName) {
            case Branches.Master:
                return Environments.Production;
            case Branches.Develop:
                return Environments.Stage;
            default:
                return undefined;
        }
    }

    static getBranchName(releaseName: string | undefined): string | undefined {
        return releaseName?.split('-').slice(0, -1).join('-');
    }

    static initiate() {
        const environment = this.getEnvironment(this.getBranchName(window['releaseName']));

        window['sentryErrorTest'] = () => {
            throw new Error('Sending a sentry test error!');
        };

        Sentry.init({
            environment,
            debug: environment !== Environments.Production,
            maxValueLength: 3000,
            // frontend-specific
            sampleRate: environment ? 1.0 : 0.0,
            dsn: 'https://0d4f1504712b4b79a26d03b61ab24f5b@o1240364.ingest.sentry.io/6524196',
            ignoreErrors: [
                /^Network Error$/,
            ],
            tracesSampler: () => {
                if (environment === Environments.Production) {
                    return 0.05;
                }
                if (environment === Environments.Stage) {
                    return 0.2;
                }

                return 0.0;
            },
            beforeSend(event, hint) {
                if (axios.isAxiosError(hint.originalException)) {
                    event.fingerprint = [
                        '{{ default }}',
                        String(hint?.originalException?.message || '[no message]'),
                    ];

                    const data = hint.originalException?.response?.data as any;
                    if (data) {
                        if (data.message || (data.code && event.exception.values[0].value)) {
                            event.exception.values[0].value = `${
                                data.message || '[no message]'
                            } (code: ${data.code || 'undefined'})`;
                        }
                    }
                }

                const exceptionValue = event?.exception?.values?.[0]?.value;
                const ignoreErrors = [
                    /Username not set/,
                    /Password not set/,
                    /Quote with id \d+ has been converted to an Order/,
                    /Invalid PO number/,
                    /timeout exceeded/,
                    /User not linked to a customer/,
                ];
                const foundIgnoredError = ignoreErrors.find((ignoreError) => ignoreError.test(exceptionValue));
                if (foundIgnoredError) {
                    return null;
                }

                return event;
            },
            integrations: [
                new Tracing.BrowserTracing({
                    tracingOrigins: [
                        process.env.NEXT_PUBLIC_URL,
                        process.env.NEXT_PUBLIC_BACKEND_URL,
                        process.env.NEXT_PUBLIC_MATOMO_URL,
                    ],
                }),
            ],
        });
    }
}
