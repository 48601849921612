import sumBy from 'lodash/sumBy';
import React, { Component, ReactElement } from 'react';

import { formatPrice } from '../../functions/formatPrice';
import { calculateDiscountedPrice } from '../../functions/calculateDiscountedPrice';
import { mergeCurrencies } from '../../functions/mergeCurrencies';
import { OrderFormData, SimplifiedQuoteBuilderResponseItem } from './reducer';

interface Props {
    data: OrderFormData;
    simplifiedQuoteBuilderResponse: {
        [key: string]: SimplifiedQuoteBuilderResponseItem;
    };
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
}

export class TotalQuoteValue extends Component<Props> {

    render(): ReactElement {
        let valid = true;
        const total = {
            price: sumBy(this.props.data.items, (item) => {
                const simplifiedQuoteBuilderResponseItem = this.props.simplifiedQuoteBuilderResponse[item._key];
                if (!simplifiedQuoteBuilderResponseItem?.valid) {
                    valid = false;
                }
                return simplifiedQuoteBuilderResponseItem?.discountedPrice || 0;
            }),
            currency: mergeCurrencies(this.props.data.items.map((item) => {
                const simplifiedQuoteBuilderResponseItem = this.props.simplifiedQuoteBuilderResponse[item._key];
                return simplifiedQuoteBuilderResponseItem?.currency;
            })),
        };

        const endConsumerTotal = {
            price: sumBy(this.props.data.items, (item) => {
                return calculateDiscountedPrice(item.endConsumerPrice, item.endConsumerDiscount);
            }),
            currency: mergeCurrencies(this.props.data.items.map((item) => {
                return item.quotationCurrency;
            })),
        };

        return (
            <section className="c--total-quote-value">
                <div className="inner-wrapper">
                    {this.props.hideWholesalePrices === false && (
                        <div>
                            <span className="label">Total value:</span>
                            <span>{total.price && valid ? formatPrice(total.price, total.currency) : '---'}</span>
                        </div>
                    )}
                    {this.props.hideEndConsumerPrices === false && (
                        <div>
                            <span className="label">Price:</span>
                            <span>{endConsumerTotal.price && valid ? formatPrice(endConsumerTotal.price, endConsumerTotal.currency) : '---'}</span>
                        </div>
                    )}
                </div>
            </section>
        );
    }

}
