import { Validator } from '../services/formService';
import { requiredValidator } from './requiredValidator';

export const conditionalRequiredValidator = (isRequired: boolean): Validator => ({
    validate: (value: number) => {
        if (!isRequired) {
            return null;
        }

        return requiredValidator.validate(value);
    },
});
