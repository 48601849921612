import { createAction } from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';
import { defaultErrorMessage } from './constants/errorMessages';
import { AppConfirmDialog, AppConfirmDialogConfig, AppToast, AppToastConfig } from './reducer';
import { backendService } from './services/backendService';
import { AuthConfig } from './services/backendTypes';
import { localStorageService } from './services/localStorageService';
import resolveService from './services/resolveService';
import { AppThunkAction } from './store';

export function loadConfig(): AppThunkAction {
    return (dispatch) => {
        return backendService.getAuthConfig().then((response) => {
            localStorageService.setUsername(response?.account?.username);
            dispatch(setLoadConfigComplete(response));
        });
    };
}

export const setLoadConfigComplete = createAction<AuthConfig>('LOAD_CONFIG_COMPLETE');

export function loadVersion(): AppThunkAction {
    return (dispatch, getState) => {
        return backendService.getVersion().then((response) => {
            if (getState().app.version === response.version) {
                return;
            }
            dispatch(updateVersion({
                version: response.version,
                newVersionAvailable: !!getState().app.version,
            }));
        });
    };
}

export const updateVersion = createAction<{ version: number; newVersionAvailable: boolean; }>('VERSION_UPDATE');

export const closeVersionDialog = createAction('CLOSE_VERSION_DIALOG');

export function openSnackbar(message = defaultErrorMessage, config: AppToastConfig): AppThunkAction<Promise<boolean>> {
    const id = uniqueId();

    return (dispatch) => {
        dispatch(addSnackbarItem({ id, message, config }));
        return resolveService.createPromise(id);
    };
}

export const addSnackbarItem = createAction<AppToast>('ADD_SNACKBAR_ITEM');

export const removeSnackbarItem = createAction('REMOVE_SNACKBAR_ITEM', (id: string, response: boolean) => {
    resolveService.resolvePromise(id, response);
    return { payload: id };
});

export const openAlertDialog = createAction<string>('OPEN_ALERT_DIALOG');

export const closeAlertDialog = createAction('CLOSE_ALERT_DIALOG');

export function confirm(message: string, config?: AppConfirmDialogConfig): AppThunkAction<Promise<boolean>> {
    const id = uniqueId();

    return (dispatch) => {
        dispatch(openConfirmDialog({ id, message, config, visible: true }));
        return resolveService.createPromise(id);
    };
}

export const openConfirmDialog = createAction<AppConfirmDialog>('OPEN_CONFIRM_DIALOG');

export const closeConfirmDialog = createAction('CLOSE_CONFIRM_DIALOG', (id: string, response: boolean) => {
    resolveService.resolvePromise(id, response);
    return { payload: undefined };
});
