import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import SecondLevelMenuItem, { SecondLevelProps } from './SecondLevelMenuItem';
import { Spinner, SpinnerProps } from '../../components/Spinner/Spinner';

export interface FirstLevelProps {
    icon?: string;
    primaryText?: string;
    to?: string;
    onClick?: () => void;
    showSubmenu?: boolean;
    emphasized?: boolean;
    routes?: SecondLevelProps[];
    spinner?: SpinnerProps;
    divider?: boolean;
    hidden?: boolean;
}

export default function FirstLevelListItem(props: FirstLevelProps): ReactElement {
    const match = useRouteMatch(props.to);
    const [showSubmenu, setShowSubmenu] = useState<boolean>(null);
    const hasChildren = props.routes?.length > 0;

    useEffect(() => {
        if (props.showSubmenu || (!!props.to && !!match)) {
            setShowSubmenu(true);
        }
    }, []);

    if (props.hidden) {
        return null;
    }

    if (props.routes && props.routes.length === 0) {
        return null;
    }

    if (props.divider) {
        return <Divider sx={{ opacity: 0 }} />;
    }

    return (
        <Fragment>
            <ListItem
                sx={(() => {
                    if (props.emphasized) {
                        return {
                            backgroundColor: '#4c4c6e',
                            color: '#fff',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontWeight: 400,
                            paddingTop: '17px',
                            paddingBottom: '17px',
                            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                                backgroundColor: '#434361',
                            },
                        };
                    }
                    if (hasChildren && showSubmenu) {
                        return {
                            paddingRight: '25px',
                            backgroundColor: '#000030',
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: 400,
                            '&:hover': {
                                backgroundColor: '#000030',
                            },
                        };
                    }
                    return {
                        paddingRight: '25px',
                        fontSize: '14px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            },
                        },
                    };
                })()}
                to={props.to}
                selected={!!props.to && !hasChildren && !!match}
                component={props.to && !hasChildren ? Link : 'div'}
                ContainerComponent="div"
                onClick={() => {
                    if (hasChildren) {
                        setShowSubmenu((prevShowSubmenu) => prevShowSubmenu === null ? !showSubmenu : !prevShowSubmenu);
                    } else if (props.onClick) {
                        props.onClick();
                    }
                }}>
                {props.icon && (
                    <ListItemIcon
                        sx={{
                            color: 'inherit',
                            minWidth: '30px',
                        }}>
                        <Icon>{props.icon}</Icon>
                    </ListItemIcon>
                )}
                <ListItemText disableTypography primary={props.primaryText} />
                {hasChildren && (
                    <Spinner
                        {...props.spinner}
                        defaultElement={<Icon>{showSubmenu ? 'expand_less' : 'expand_more'}</Icon>}
                    />
                )}
            </ListItem>
            <Collapse in={showSubmenu} timeout="auto" unmountOnExit>
                {hasChildren && (
                    <List component="div" disablePadding>
                        {props.routes.map((route) => (
                            <SecondLevelMenuItem key={route.key} {...route} />
                        ))}
                    </List>
                )}
            </Collapse>
        </Fragment>
    );

}
