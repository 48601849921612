import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';

import { closeDebugDialog } from './actions';
import { Dialog } from '../../../../components/Dialog';
import BasicTable from './BasicTable';
import AdvancedTable from './AdvancedTable';
import { ReduxState } from '../../../../store';

type ReduxProps = ConnectedProps<typeof connector>;

const mapStateToProps = (store: ReduxState) => ({
    dialog: store.debugDialog,
});

function DebugDialog(props: ReduxProps): ReactElement {
    const [activeTab, setActiveTab] = useState(0);
    const tabs: { name: string; key: string; Component: any; }[] = [];

    if (props.dialog.tabs.basic) {
        tabs.push({
            name: 'Basic',
            key: 'basic',
            Component: BasicTable,
        });
    }

    if (props.dialog.tabs.advanced) {
        tabs.push({
            name: 'Advanced',
            key: 'advanced',
            Component: AdvancedTable,
        });
    }

    useEffect(() => {
        if (activeTab > 0) {
            setActiveTab(0);
        }
    }, [props.dialog.visible]);

    function closeDialog() {
        props.dispatch(closeDebugDialog());
    }

    return (
        <Dialog
            size="fullscreen"
            className="debug-dialog"
            title="Materials"
            disableContentPadding
            open={props.dialog.visible}
            actions={[
                {
                    onClick: () => closeDialog(),
                    children: 'Close',
                },
            ]}
            onClose={() => closeDialog()}>
            <div className="dialog-content">
                {Object.keys(props.dialog.tabs).length && (
                    <Fragment>
                        <AppBar
                            position="static"
                            sx={{
                                boxShadow: 'none',
                            }}>
                            <Tabs
                                sx={{
                                    '@media print': {
                                        display: 'none',
                                    },
                                }}
                                TabIndicatorProps={{
                                    sx: {
                                        backgroundColor: '#989898',
                                        height: '4px',
                                    },
                                }}
                                value={activeTab}
                                onChange={(event, newValue: number) => {
                                    setActiveTab(newValue);
                                }}>
                                {tabs.map(({ name, key }) => (
                                    <Tab
                                        key={key}
                                        label={name}
                                        sx={{
                                            flex: 1,
                                            color: '#fff',
                                            opacity: 0.7,
                                            '&.Mui-selected': {
                                                color: '#fff',
                                                opacity: 1,
                                            },
                                        }}
                                    />
                                ))}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            animateHeight
                            containerStyle={{
                                // Bugfix: Animation doesn't work the first time the index changes
                                transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
                            }}
                            index={activeTab}
                            onChangeIndex={(newValue: number) => {
                                setActiveTab(newValue);
                            }}>
                            {tabs.map(({ Component, key }) => (
                                <Component key={key} data={props.dialog.tabs[key]} />
                            ))}
                        </SwipeableViews>
                    </Fragment>
                )}
            </div>
        </Dialog>
    );

}

const connector = connect(mapStateToProps);
export default connector(DebugDialog);
