import React, { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import Box from '@mui/material/Box';

interface Props {
    in?: boolean;
    className?: string;
}

export default function FadeIn(props: PropsWithChildren<Props>) {
    return (
        <CSSTransition
            in={props.in}
            appear
            unmountOnExit
            timeout={{
                enter: 600,
                exit: 0,
            }}
            className={props.className}>
            <Box
                sx={{
                    '&.enter > div': {
                        opacity: 0,
                    },
                    '&.enter.enter-active > div': {
                        opacity: 1,
                        transition: 'opacity 600ms ease',
                        transform: 'translate3d(0, 0, 0)',
                    },
                }}>
                <div>{props.children}</div>
            </Box>
        </CSSTransition>
    );
}
