import React, { ChangeEvent, Component, createRef, RefObject } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { sprintf } from 'sprintf-js';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

import { convertToNumber } from '../../functions/convertToNumber';
import { detectChanges } from '../../functions/detectChanges';
import { CustomMenuItem } from './CustomMenuItem';
import { SizeNation } from '../../services/backendTypes';
import { isIOS } from '../../functions/isIOS';

export interface SizeFieldProps {
    id: string;
    selectLabel?: string;
    customLabel?: string;
    fullWidth?: boolean;
    value?: SizeFieldValue;
    menuItems: MenuItems;
    error?: boolean;
    errorText?: string;
    selectHelpText?: string;
    customHelpText?: string;
    onChange?: (value: SizeFieldValue, event?: ChangeEvent) => void;
    onBlur?: () => void;
    disabled?: boolean;
    allowCustomSize?: boolean;
    preferredNation?: string;
}

type MenuItems = { [key in SizeNation]: SizeFieldMenuItem[]; };

interface SizeFieldMenuItem {
    width: number;
    length: number;
    displayName: string;
    sizeNationKey?: SizeNation;
}

export interface SizeFieldValue {
    width: number;
    length: number;
    sizeNationKey?: SizeNation;
}

interface State {
    customMode: boolean;
    customValue: string;
    showAllMode: boolean;
    selectIsOpen: boolean;
    autoFocus: boolean;
}

export default class SizeField extends Component<SizeFieldProps, State> {

    state: State = {
        customMode: false,
        customValue: '',
        showAllMode: false,
        selectIsOpen: false,
        autoFocus: false,
    };
    textFieldRef: RefObject<any>;

    constructor(props: SizeFieldProps) {
        super(props);
        this.textFieldRef = createRef();
    }

    componentDidMount() {
        this.switchModeIfNonExistingValue();
    }

    componentDidUpdate(prevProps: SizeFieldProps, prevState: State) {
        const changesDetectedInProps = detectChanges(prevProps, this.props, ['menuItems', 'value']);
        const changesDetectedInState = detectChanges(prevState, this.state, ['showAllMode']);
        if (changesDetectedInProps || changesDetectedInState) {
            this.switchModeIfNonExistingValue();
        }
    }

    switchModeIfNonExistingValue() {
        const menuItems = this.getMenuItems();
        if (!menuItems || !menuItems.length) {
            return;
        }
        const width = convertToNumber(this.props.value?.width);
        const length = convertToNumber(this.props.value?.length);
        if (!width && !length) {
            return;
        }
        const selected = menuItems.find((item: SizeFieldMenuItem) => {
            return item.width === width && item.length === length;
        });
        if (selected) {
            return;
        }
        if (this.state.showAllMode && !this.state.customMode) {
            const cmWidth = String(width / 10).replace('.', ',');
            const cmLength = String(length / 10).replace('.', ',');
            this.setState({
                customMode: true,
                customValue: sprintf('%sx%s', cmWidth, cmLength),
            });
        } else if (!this.state.showAllMode) {
            this.setState({
                showAllMode: true,
            });
        }
    }

    resetValue() {
        this.props.onChange({
            width: undefined,
            length: undefined,
        });
    }

    changeToCustomMode() {
        // Here we want to skip the required validator and display a size message so we set the values to 0 instead of undefined
        this.props.onChange({
            width: 0,
            length: 0,
        });
        this.setState({
            customMode: true,
            selectIsOpen: false,
            autoFocus: true,
        });
    }

    changeToSelectMode() {
        this.resetValue();
        this.setState({
            customMode: false,
            customValue: '',
        });
    }

    changeToShowAllMode() {
        this.setState({
            showAllMode: true,
        });
    }

    prepareValue(): string {
        if (this.props.value) {
            const menuItems = this.getMenuItems();
            const width = convertToNumber(this.props.value.width);
            const length = convertToNumber(this.props.value.length);
            const nationKey = this.props.value.sizeNationKey;
            const selectedWithNationKey = menuItems.find((item: SizeFieldMenuItem) => {
                return item.width === width && item.length === length && item.sizeNationKey === nationKey;
            });
            if (selectedWithNationKey) {
                return selectedWithNationKey.displayName;
            }

            const selected = menuItems.find((item: SizeFieldMenuItem) => {
                return item.width === width && item.length === length;
            });
            if (selected) {
                return selected.displayName;
            }
        }
        return '';
    }

    handleChangeSelect(event: ChangeEvent<any>) {
        const menuItems = this.getMenuItems();
        const selected = menuItems.find((item: SizeFieldMenuItem) => item.displayName === event.target.value);
        if (selected) {
            this.props.onChange({
                width: selected.width,
                length: selected.length,
                sizeNationKey: selected.sizeNationKey,
            }, event);
        } else {
            this.resetValue();
        }
    }

    handleChangeCustom(event: ChangeEvent<HTMLInputElement>) {
        let value = event.target.value;
        // Set inner value
        if (value.length) {
            switch (value[value.length - 1]) {
                case ' ':
                case 'X':
                    value = value.substr(0, value.length - 1) + 'x';
                    break;
                case '.':
                    value = value.substr(0, value.length - 1) + ',';
                    break;
            }
            if (!value.match(/^[1-9][0-9]{0,2}(,[05]?)?(x([1-9][0-9]{0,2}(,[05]?)?)?)?$/)) {
                return;
            }
        }
        this.setState({
            customValue: value,
        });
        // Set outer value
        if (value) {
            const dimensions = value.split('x');
            const width = parseFloat((dimensions[0] || '').replace(',', '.')) || 0;
            const length = parseFloat((dimensions[1] || '').replace(',', '.')) || 0;
            this.props.onChange({
                width: width * 10,
                length: length * 10,
            });
        } else {
            this.resetValue();
        }
    }

    handleKeyDownSelect(event: KeyboardEvent) {
        // Close dropdown when keyboard shortcuts are used
        if (event.ctrlKey && event.keyCode >= 65 && event.keyCode <= 90) {
            this.setState({ selectIsOpen: false });
        }
    }

    filterMenuItemsForNation(menuItems: SizeFieldMenuItem[], preferredMenuItems: SizeFieldMenuItem[]) {
        return menuItems.filter((otherMenuItemForNation) =>
            !preferredMenuItems.find((preferredMenuItem) =>
                preferredMenuItem.length === otherMenuItemForNation.length && preferredMenuItem.width === otherMenuItemForNation.width,
            ),
        );
    }

    addNationCodeToSizes(menuItems: MenuItems) {
        for (const [nationKey, sizeList] of Object.entries(menuItems)) {
            sizeList.map((size) => {
                size.sizeNationKey = nationKey as SizeNation;
            });
        }
    }

    getMenuItems(): SizeFieldMenuItem[] {
        const menuItems = cloneDeep(this.props.menuItems);
        if (!menuItems) {
            return [];
        }
        this.addNationCodeToSizes(menuItems);
        const preferredNation = this.props.preferredNation || 'ROW';
        if (this.state.showAllMode) {
            const preferredMenuItems = menuItems[preferredNation];
            const otherMenuItems = omit(menuItems, preferredNation);
            // Matched preferred items
            return Object.entries(otherMenuItems).reduce((acc, [_, menuItemsForNation]) => [...acc, ...this.filterMenuItemsForNation(menuItemsForNation as SizeFieldMenuItem[], preferredMenuItems)], preferredMenuItems); // eslint-disable-line @typescript-eslint/no-unused-vars
        }
        return menuItems[preferredNation];
    }

    render() {
        return (
            <div className="c--size-field">
                {!this.state.customMode && (
                    <FormControl variant="standard" error={this.props.error} onKeyDown={this.handleKeyDownSelect.bind(this)}>
                        <InputLabel>{this.props.selectLabel}</InputLabel>
                        <Select
                            id={this.props.id}
                            MenuProps={{
                                // Bugfix: Disable animations on iOS devices to fix flickering behaviour when opening dropdown
                                transitionDuration: isIOS() ? 0 : undefined,
                            }}
                            value={this.prepareValue()}
                            disabled={this.props.disabled}
                            open={this.state.selectIsOpen}
                            onOpen={() => {
                                this.setState({ selectIsOpen: true });
                            }}
                            onClose={() => {
                                this.setState({ selectIsOpen: false });
                            }}
                            onChange={this.handleChangeSelect.bind(this)}
                            onBlur={this.props.onBlur}
                            IconComponent={(iconProps) => <Icon {...iconProps}>arrow_drop_down</Icon>}>
                            {this.getMenuItems().map((menuItem, index) => (
                                <MenuItem key={index} value={menuItem.displayName}>
                                    {menuItem.displayName}
                                </MenuItem>
                            ))}
                            {!this.state.showAllMode && (
                                <CustomMenuItem clickHandler={this.changeToShowAllMode.bind(this)}>Show all...</CustomMenuItem>
                            )}
                            {this.props.allowCustomSize && (
                                <li><Divider></Divider></li>
                            )}
                            {this.props.allowCustomSize && (
                                <CustomMenuItem clickHandler={this.changeToCustomMode.bind(this)}>{this.props.customLabel + '...'}</CustomMenuItem>
                            )}
                        </Select>
                        <FormHelperText>{this.props.error ? this.props.errorText : this.props.selectHelpText}</FormHelperText>
                    </FormControl>
                )}
                {this.state.customMode && (
                    <TextField
                        variant="standard"
                        ref={this.textFieldRef}
                        id={this.props.id}
                        label={this.props.customLabel}
                        fullWidth={this.props.fullWidth}
                        value={this.state.customValue}
                        onChange={this.handleChangeCustom.bind(this)}
                        onBlur={this.props.onBlur}
                        helperText={this.props.error ? this.props.errorText : this.props.customHelpText}
                        error={this.props.error}
                        disabled={this.props.disabled}
                        autoFocus={this.state.autoFocus}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={this.changeToSelectMode.bind(this)} color="primary" size="small" edge="end">
                                        <Icon>close</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </div>
        );
    }

}
