import { sprintf } from 'sprintf-js';

import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';
import { Interval } from '../services/backendTypes';

export const stringSizeValidator = (intervals: Interval[] = []): Validator => ({
    validate: (value = '') => {
        const splitValue = value.split('x');

        const valid = intervals.every((interval, index) => {
            if (!interval) {
                return true;
            }
            const stringValueInMm = splitValue[index];
            const numberValueInMm = parseFloat(stringValueInMm);
            return numberValueInMm >= interval.from && numberValueInMm <= interval.to;
        });

        if (valid) {
            return null;
        }

        // If we have 2 intervals here we can assume it's for width and length
        if (intervals.length === 2) {
            return {
                errorMessage: sprintf(
                    errorMessages.size,
                    (intervals[0].from / 10),
                    (intervals[0].to / 10),
                    (intervals[1].from / 10),
                    (intervals[1].to / 10),
                ),
            };
        }

        return {
            errorMessage: sprintf(
                errorMessages.sizeSingle,
                (intervals[0].from / 10),
                (intervals[0].to / 10),
            ),
        };
    },
});
