class ResolveService {

    private resolves: { [id: string]: (value) => void } = {};

    public createPromise<T>(id: string): Promise<T> {
        return new Promise((resolve) => {
            this.resolves[id] = resolve;
        });
    }

    public resolvePromise<T>(id: string, value: T) {
        if (this.resolves[id]) {
            this.resolves[id](value);
            delete this.resolves[id];
        }
    }

}

export default new ResolveService();
