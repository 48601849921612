import React, { Fragment, ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface Props {
    data: BasicDebugTableMaterial[]
}

export interface BasicDebugTableMaterial {
    blockName: string;
    materialName: string;
    productName: string;
    sku: string;
    price: number;
    fullPrice: number;
    quantity: number;
}

export default function BasicTable(props: Props): ReactElement {
    if (!props.data) {
        return null;
    }

    return (
        <div className="c--basic-debug-table">
            <Table>
                <TableHead>
                    <TableRow key="header-row">
                        <TableCell>Block Name</TableCell>
                        <TableCell>Material Name</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{String(item.blockName || '-')}</TableCell>
                            <TableCell>{String(item.materialName || '-')}</TableCell>
                            <TableCell>{String(item.productName || '-')}</TableCell>
                            <TableCell>{String(item.sku || '-')}</TableCell>
                            <TableCell>
                                {item.price !== item.fullPrice && (
                                    <Fragment><s>{String(item.fullPrice)}</s>&nbsp;</Fragment>
                                )}
                                <span>{item.price === null ? '-' : String(item.price)}</span>
                            </TableCell>
                            <TableCell>{String(item.quantity || '-')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
