import { sprintf } from 'sprintf-js';

import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const maxLinesValidator = (max: number): Validator => ({
    validate: (value: string) => {
        if (!value) {
            return null;
        }
        const error = {
            errorMessage: sprintf(errorMessages.maxLines, max),
        };
        return value.split('\n').length > max ? error : null;
    },
});
