import React, { Component, Fragment, ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Grid from '@mui/material/Grid';
import { sprintf } from 'sprintf-js';
import debounce from 'lodash/debounce';

import { FormField } from '../../../../components/formFields/FormField';
import SizeField, { SizeFieldProps } from '../../../../components/formFields/SizeField';
import StringSizeField, { StringSizeFieldProps } from '../../../../components/formFields/StringSizeField';
import SelectField, { SelectFieldProps } from '../../../../components/formFields/SelectField';
import NumberField, { NumberFieldProps } from '../../../../components/formFields/NumberField';
import Button from '../../../../components/formFields/Button';
import { ruleEvaluatorService } from '../../../../services/ruleEvaluatorService';
import { formService } from '../../../../services/formService';
import { requiredValidator } from '../../../../validators/requiredValidator';
import { minNumberValidator } from '../../../../validators/minNumberValidator';
import { sizeValidator } from '../../../../validators/sizeValidator';
import { disabledValidator } from '../../../../validators/disabledValidator';
import { sizeExistsValidator } from '../../../../validators/sizeExistsValidator';
import { requiredSizeValidator } from '../../../../validators/requiredValidator';
import { stringSizeValidator } from '../../../../validators/stringSizeValidator';
import { AccessoryTotal } from './AccessoryTotal';
import * as orderFormActions from '../../actions';
import * as appActions from '../../../../actions';
import { errorMessages } from '../../../../constants/errorMessages';
import { convertToNumber } from '../../../../functions/convertToNumber';
import { detectChanges } from '../../../../functions/detectChanges';
import Card from '../Card';
import { OrderFormAccessoryItem, SimplifiedQuoteBuilderResponseItem } from '../../reducer';
import { AppDispatch, ReduxState } from '../../../../store';
import { AccessoryCategory, AccessoryCategorySetting } from '../../../../services/backendTypes';
import { SpinnerProps } from '../../../../components/Spinner/Spinner';
import { prepareRequestDataAccessoryItem } from '../../functions';

type CombinedProps = Props & ReduxProps;
type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };

interface Props {
    quoteId: number;
    category: AccessoryCategory;
    data: OrderFormAccessoryItem;
    simplifiedQuoteBuilderResponseItem: SimplifiedQuoteBuilderResponseItem;
    quoteBuilderResponseSpinner: SpinnerProps;
    disabled: boolean;
    onAdd: () => void;
    onRemove: () => void;
    onChange: (values: Partial<OrderFormAccessoryItem>) => void;
    onDebugClick: () => void;
}

const mapStateToProps = (store: ReduxState) => ({
    currentCustomer: store.auth.currentCustomer,
    hideEndConsumerPrices: store.auth.hideEndConsumerPrices,
    hideWholesalePrices: store.auth.hideWholesalePrices,
    campaignCode: store.orderForm.data.campaignCode,
    idpCode: store.orderForm.data.idpCode,
    orderType: store.orderForm.data.orderType,
});

class Accessory extends Component<CombinedProps> {

    reachedEndOfCategoryTree: boolean;
    hasApprovedCustomerPriceChange = false;
    willUnmount = false;
    priceManuallyChanged = false;
    confirmDialogIsOpen = false;

    constructor(props) {
        super(props);
        this.loadPrice = debounce(this.loadPrice, 300);
    }

    componentWillUnmount() {
        this.willUnmount = true;
    }

    componentDidUpdate(prevProps: CombinedProps) {
        const changesDetectedInProps = detectChanges(prevProps, this.props, [
            'currentCustomer',
            'campaignCode',
            'idpCode',
        ]);
        const changesDetectedInPropsData = detectChanges(prevProps.data, this.props.data, [
            'quantity',
            'level1',
            'level2',
            'name',
            'parameters',
        ]);
        if (changesDetectedInProps || changesDetectedInPropsData) {
            this.loadPrice();
        }
    }

    shouldComponentUpdate(nextProps: CombinedProps) {
        // Only update if a prop has changed to boost performance for large quotes
        return detectChanges(nextProps, this.props, [
            'quoteId',
            'data',
            'disabled',
            'campaignCode',
            'idpCode',
            'hideEndConsumerPrices',
            'hideWholesalePrices',
            'currentCustomer',
            'simplifiedQuoteBuilderResponseItem',
            'quoteBuilderResponseSpinner',
        ]);
    }

    shouldValueChange(value, event: Event): boolean | Promise<boolean> {
        if (this.willUnmount) {
            return false;
        }
        const endConsumerPrice = convertToNumber(this.props.data.endConsumerPrice, true);
        const recommendedPrice = convertToNumber(this.props.simplifiedQuoteBuilderResponseItem?.recommendedPrice, true);
        const customerPriceHasNotChanged = endConsumerPrice === recommendedPrice;
        const isComputerAction = event === undefined;
        if (customerPriceHasNotChanged || this.hasApprovedCustomerPriceChange || isComputerAction) {
            return true;
        } else if (this.confirmDialogIsOpen === false) {
            this.priceManuallyChanged = true;
            this.confirmDialogIsOpen = true;
            return this.props.dispatch(appActions.confirm('If you change the contents of the quote, the manual value previously added will change.', { trueAction: 'Accept', falseAction: 'Decline' })).then((response) => {
                this.hasApprovedCustomerPriceChange = response;
                this.confirmDialogIsOpen = false;
                return response;
            });
        }
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey) {
            switch (event.keyCode) {
                case 78: // n
                    this.props.onAdd();
                    event.preventDefault();
                    break;
                case 82: // r
                    this.props.onRemove();
                    event.preventDefault();
                    break;
            }
        }
    }

    canLoadPrice(): boolean {
        const requirements = [
            !!this.props.currentCustomer,
            !!this.reachedEndOfCategoryTree,
            !!formService.isValid(sprintf('^accessory-%s--', this.props.data._key)),
        ];
        return requirements.every((requirement: boolean) => requirement);
    }

    loadPrice() {
        if (!this.canLoadPrice()) {
            this.props.dispatch(orderFormActions.removeMaterials(this.props.data._key));
            return;
        }
        this.hasApprovedCustomerPriceChange = false;
        this.props.dispatch(orderFormActions.addToMaterialsQueue({
            id: this.props.quoteId,
            customerNumber: this.props.currentCustomer.key,
            campaignCode: this.props.campaignCode,
            idpCode: this.props.idpCode,
            orderType: this.props.orderType,
            items: [prepareRequestDataAccessoryItem(this.props.data)],
        }));
    }

    render(): ReactElement {
        this.reachedEndOfCategoryTree = false;

        return (
            <Card
                className="c--accessory"
                layout="horizontal"
                onKeyDown={this.handleKeyDown.bind(this)}
                content={
                    <Fragment>
                        <Grid container spacing={2}>
                            {this.getRecursiveFields(this.props.category)}
                            <Grid item xs={12} lg={3} style={{ marginLeft: 'auto' }}>
                                <FormField<NumberFieldProps>
                                    disabled={this.props.disabled}
                                    component={NumberField}
                                    validators={[minNumberValidator(1)]}
                                    min={1}
                                    id={sprintf('accessory-%s--quantity', this.props.data._key)}
                                    label="Quantity *"
                                    value={this.props.data.quantity}
                                    onChange={(value) => this.props.onChange({ quantity: value })}
                                    shouldValueChange={this.shouldValueChange.bind(this)}
                                />
                            </Grid>
                        </Grid>
                        {this.props.simplifiedQuoteBuilderResponseItem?.valid === false && (
                            <span className="error-text">{errorMessages.price}</span>
                        )}
                        {this.props.simplifiedQuoteBuilderResponseItem?.blockStatus === 96 && (
                            <span className="error-text">{errorMessages.notAllowed}</span>
                        )}
                        {this.props.simplifiedQuoteBuilderResponseItem?.blockStatus === 97 && (
                            <span className="error-text">{errorMessages.discontinued}</span>
                        )}
                    </Fragment>
                }
                summary={
                    <AccessoryTotal
                        data={this.props.data}
                        simplifiedQuoteBuilderResponseItem={this.props.simplifiedQuoteBuilderResponseItem}
                        quoteBuilderResponseSpinner={this.props.quoteBuilderResponseSpinner}
                        hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                        hideWholesalePrices={this.props.hideWholesalePrices}
                        onChangeEndConsumerPrice={(value) => {
                            this.props.onChange({ endConsumerPrice: value });
                        }}
                        onChangeEndConsumerDiscount={(value) => {
                            this.props.onChange({ endConsumerDiscount: value });
                        }}
                        onDebugClick={this.props.onDebugClick}
                        disabled={!this.canLoadPrice()}
                    />
                }
                actions={
                    <Button icon="delete" disabled={this.props.disabled} onClick={this.props.onRemove} tooltipLabel="Remove" tooltipPosition="right" />
                }
            />
        );
    }

    getRecursiveFields(group: AccessoryCategory, tree: AccessoryCategory[] = [], depth = 1) {
        tree.push(group);
        if (group && group.children) {
            const selectedChild = this.props.data[group.key] ? group.children.find((child) => child.name === this.props.data[group.key]) : null;
            return [
                <Grid item xs={12} lg={3} key={group.key}>
                    <FormField<SelectFieldProps>
                        disabled={this.props.disabled}
                        component={SelectField}
                        validators={depth === 1 ? null : [requiredValidator, disabledValidator(group.children, 'name')]}
                        autoFocus={depth === 1 && this.props.data._new}
                        id={sprintf('accessory-%s--%s', this.props.data._key, group.key)}
                        label={(group.label || group.name) + ' *'}
                        fullWidth
                        value={this.props.data[group.key]}
                        menuItems={group.children}
                        itemLabel="name"
                        itemValue="name"
                        itemAvatar="image"
                        onChange={(value) => this.props.onChange({ [group.key]: value })}
                        shouldValueChange={this.shouldValueChange.bind(this)}
                    />
                </Grid>,
                this.getRecursiveFields(selectedChild, tree, ++depth),
            ];
        }
        if (group && group.settings) {
            this.reachedEndOfCategoryTree = true;
            return group.settings.map((setting, index) => (
                <Grid item xs={12} lg={3} key={index}>
                    {this.getSettingField(setting, tree)}
                </Grid>
            ));
        }
        return null;
    }

    getSettingField(setting: AccessoryCategorySetting, tree: AccessoryCategory[]) {
        switch (setting.type) {
            case 'dropdown':
                return (
                    <FormField<SelectFieldProps>
                        disabled={this.props.disabled}
                        component={SelectField}
                        validators={[
                            requiredValidator,
                            disabledValidator(setting.data.options, 'code'),
                        ]}
                        id={sprintf('accessory-%s--%s', this.props.data._key, setting.code)}
                        label={setting.name + ' *'}
                        fullWidth
                        value={this.props.data.parameters[setting.code]}
                        menuItems={setting.data.options.filter((option) => ruleEvaluatorService.evaluate(option.rules, this.props.data.parameters))}
                        itemLabel="displayName"
                        itemValue="code"
                        itemAvatar="image"
                        onChange={(value) => this.props.onChange({ parameters: { [setting.code]: value } })}
                        shouldValueChange={this.shouldValueChange.bind(this)}
                        helpText={this.getMessageField(tree, setting)}
                    />
                );
            case 'size':
                return (
                    <FormField<SizeFieldProps>
                        disabled={this.props.disabled}
                        component={SizeField}
                        validators={[
                            requiredSizeValidator,
                            sizeExistsValidator(setting.standardSizes, setting.allowCustomSize),
                            sizeValidator(setting.data.widthInterval.intervals[0], setting.data.lengthInterval.intervals[0]),
                        ]}
                        id={sprintf('accessory-%s--%s', this.props.data._key, setting.code)}
                        selectLabel="Size *"
                        customLabel="Custom size in cm *"
                        customHelpText="Example: 80x190"
                        allowCustomSize={setting.allowCustomSize}
                        fullWidth
                        value={{
                            width: this.props.data.parameters.width,
                            length: this.props.data.parameters.length,
                            sizeNationKey: this.props.data.parameters.sizeNationKey,
                        }}
                        menuItems={setting.standardSizes}
                        preferredNation={this.props.currentCustomer.nation}
                        onChange={(values) => this.props.onChange({ parameters: values })}
                        shouldValueChange={this.shouldValueChange.bind(this)}
                    />
                );
            /**
             * String size handles cases when we have size data in string form and want to allow custom input from the user.
             * Currently we support 1-2 dimensions.
             */
            case 'string_size':
                const intervals = (() => {
                    switch (setting.data.dimensions) {
                        case 'height':
                            return [setting.data.heightInterval.intervals[0]];
                        case 'width':
                            return [setting.data.widthInterval.intervals[0]];
                        case 'width_length':
                            return [setting.data.widthInterval.intervals[0], setting.data.lengthInterval.intervals[0]];
                    }
                })();

                return (
                    <FormField<StringSizeFieldProps>
                        disabled={this.props.disabled}
                        component={StringSizeField}
                        validators={[
                            requiredValidator,
                            disabledValidator(setting.data.options, 'code'),
                            stringSizeValidator(intervals),
                        ]}
                        id={sprintf('accessory-%s--%s', this.props.data._key, setting.code)}
                        selectLabel={`${setting.name} *`}
                        customLabel={`Custom ${setting.name?.toLowerCase()} in cm *`}
                        selectHelpText={this.getMessageField(tree, setting)}
                        allowCustomSize={setting.allowCustomSize}
                        dimensions={setting.data.dimensions?.split('_').length}
                        value={this.props.data.parameters[setting.code]}
                        menuItems={setting.data.options.filter((option) => ruleEvaluatorService.evaluate(option.rules, this.props.data.parameters)).map((option) => ({
                            displayName: option.displayName,
                            value: option.code,
                            disabled: option.disabled,
                        }))}
                        onChange={(value) => {
                            this.props.onChange({ parameters: { [setting.code]: value } });
                        }}
                        shouldValueChange={this.shouldValueChange.bind(this)}
                    />
                );
            default:
                return null;
        }
    }

    getMessageField(tree: AccessoryCategory[], setting: AccessoryCategorySetting) {
        let message: string = null;
        let textClass = '';
        switch (setting.code) {
            case 'height_length':
            case 'height':
                message = this.getHeightMessage(tree);
                break;
            case 'color':
                message = this.getColorMessage(tree);
                textClass = 'error-text';
                break;
        }

        if (message) {
            return <span className={textClass}>{message}</span>;
        }

        return null;
    }

    getHeightMessage(tree: AccessoryCategory[]): string|null {
        const level1 = tree[0].code;
        const level2 = tree[1].code;
        const level3 = tree[2];
        const categoriesWithMessage = ['headboardsandheadboardslipcovers', 'bedskirts'];

        if (!categoriesWithMessage.includes(level1)) {
            return null;
        }

        if (level2 === 'headboards' && level3.code === 'beingilse') {
            return 'Height excluding headboard legs.';
        }

        if (level2 === 'headboardslipcover' && level3.code === 'beingilse_1') {
            return 'Height including standard height of bed legs.';
        }

        if (level1 === 'bedskirts' && level2 === 'beingilsecollection') {
            return 'Recommended height when used with standard height of bed legs.';
        }

        return null;
    }

    getColorMessage(tree: AccessoryCategory[]): string|null {
        const level1 = tree[0].code;
        const discontinuedLevel = ['topmattresses_1', 'headboardsandheadboardslipcovers'];

        if (!discontinuedLevel.includes(level1.toLowerCase())) {
            return null;
        }

        const choosenColor = this.props.data.parameters['color'];
        if (!choosenColor) {
            return null;
        }

        const discontinuedColors = ['denim check', 'wine red check', 'sky blue check', 'lime check'];
        if (discontinuedColors.includes(choosenColor.toLowerCase())) {
            return 'Discontinued color - not to be used.';
        }

        return null;
    }

}

const connector = connect(mapStateToProps);
export default connector(Accessory);
