import React, { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import Box from '@mui/material/Box';

interface Props {
    in?: boolean;
}

export default function SlideAndFade(props: PropsWithChildren<Props>) {
    return (
        <CSSTransition
            in={props.in}
            appear
            unmountOnExit
            timeout={500}>
            <Box
                sx={{
                    '&.enter': {
                        overflow: 'hidden',
                        maxHeight: 0,
                        '& > div': {
                            opacity: 0,
                        },
                    },
                    '&.enter.enter-active': {
                        overflow: 'visible',
                        maxHeight: 500,
                        transition: 'max-height 300ms ease 0ms, overflow 0ms ease 300ms',
                        transform: 'translate3d(0, 0, 0)',
                        '& > div': {
                            opacity: 1,
                            transition: 'opacity 200ms ease 300ms',
                            transform: 'translate3d(0, 0, 0)',
                        },
                    },
                    '&.exit': {
                        overflow: 'visible',
                        maxHeight: 500,
                        transition: 'max-height 300ms ease 200ms, overflow 0ms ease 200ms',
                        transform: 'translate3d(0, 0, 0)',
                        '& > div': {
                            opacity: 1,
                            transition: 'opacity 200ms ease 0ms',
                            transform: 'translate3d(0, 0, 0)',
                        },
                    },
                    '&.exit.exit-active': {
                        overflow: 'hidden',
                        maxHeight: 0,
                        '& > div': {
                            opacity: 0,
                        },
                    },
                }}>
                <div>{props.children}</div>
            </Box>
        </CSSTransition>
    );
}
