import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as appActions from './actions';
import { MessageDialog } from './components/MessageDialog';
import { ReduxState } from './store';

type ReduxProps = ConnectedProps<typeof connector>;

const mapStateToProps = (store: ReduxState) => ({
    confirmDialog: store.app.confirmDialog,
});

function ConfirmDialog(props: ReduxProps) {

    const actions = [
        {
            onClick: () => {
                props.dispatch(appActions.closeConfirmDialog(props.confirmDialog.id, true));
            },
            children: props.confirmDialog.config?.trueAction || 'Yes',
        },
        {
            onClick: () => {
                props.dispatch(appActions.closeConfirmDialog(props.confirmDialog.id, false));
            },
            children: props.confirmDialog.config?.falseAction || 'No',
        },
    ];

    return (
        <MessageDialog
            actions={actions}
            open={props.confirmDialog.visible}
            onClose={() => {
                props.dispatch(appActions.closeConfirmDialog(props.confirmDialog.id, false));
            }}>
            {props.confirmDialog.message}
        </MessageDialog>
    );

}

const connector = connect(mapStateToProps);
export default connector(ConfirmDialog);
