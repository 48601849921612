import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CUSTOMER_SERVICE_USER } from '../../appConfig';
import { backendService, ErrorData } from '../../services/backendService';
import { AuthConfig } from '../../services/backendTypes';
import { AppThunkAction } from '../../store';

export function login(username: string, password: string): AppThunkAction<Promise<AuthConfig>> {
    return (dispatch) => {
        dispatch(setLoginPending());
        return backendService.login({ username, password }).then(
            (response) => {
                dispatch(setLoginComplete(response));

                /**
                 * Proof of concept
                 */
                window['dataLayer']?.push({
                    event: 'login',
                    config_user_type: response.account.username === CUSTOMER_SERVICE_USER ? 'customer_service' : 'default',
                });

                return response;
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLoginRejected(error?.response?.data?.message));
                return Promise.reject(error);
            },
        );
    };
}

export const setLoginPending = createAction('LOGIN_PENDING');

export const setLoginComplete = createAction<AuthConfig>('LOGIN_COMPLETE');

export const setLoginRejected = createAction<string>('LOGIN_REJECTED');
