import React, { KeyboardEvent, ReactNode } from 'react';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';

interface CardProps {
    layout?: 'horizontal' | 'vertical';
    className?: string;
    content: ReactNode;
    summary: ReactNode;
    actions: ReactNode;
    onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
}

Card.defaultProps = {
    layout: 'vertical',
} as CardProps;

export default function Card(props: CardProps) {
    return (
        <Paper
            elevation={2}
            onKeyDown={props.onKeyDown}
            classes={{ root: clsx('c--card', `${props.layout}-layout`, props.className) }}>
            <div className="card-content">{props.content}</div>
            <div className="card-summary">{props.summary}</div>
            <div className="card-actions">{props.actions}</div>
        </Paper>
    );
}
