import React from 'react';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import { sprintf } from 'sprintf-js';

import { SummaryTable, SummaryTableDataItem, SummaryTableField } from './SummaryTable';
import { AccessoryCategory, AccessoryCategorySetting, QuoteAccessoryParameters } from '../../../../services/backendTypes';
import { OrderFormAccessoryItem } from '../../../../containers/reducers';

interface Props {
    accessory: AccessoryCategory;
    data: OrderFormAccessoryItem[];
}

interface KeyValuePair {
    title: string;
    key: string;
    value: string;
}

export class QuoteAccessoryTable extends React.Component<Props> {

    prepareItem(item: OrderFormAccessoryItem, group: AccessoryCategory, fields = []): KeyValuePair[] {
        if (group && group.children) {
            const selected = group.children.find((child) => child.name === item[group.key]);
            return [
                {
                    title: group.label,
                    key: group.key,
                    value: selected ? selected.name : undefined,
                },
                ...this.prepareItem(item, selected, fields),
            ];
        }
        if (group && group.settings) {
            return group.settings.reduce((accumulator, currentValue) => [
                ...accumulator,
                ...this.prepareSetting(currentValue, item.parameters),
            ], []);
        }
        return [];
    }

    prepareSetting(setting: AccessoryCategorySetting, parameters: QuoteAccessoryParameters) {
        switch (setting.type) {
            case 'dropdown':
                const selected = (setting.data.options || []).find((option) => option.code === parameters[setting.code]);
                return [{
                    title: setting.name,
                    key: setting.code,
                    value: selected ? selected.displayName : undefined,
                }];
            case 'size':
                return [
                    {
                        title: 'Width',
                        key: 'width',
                        value: sprintf('%s cm', (parseInt(parameters.width) / 10)),
                    },
                    {
                        title: 'Length',
                        key: 'length',
                        value: sprintf('%s cm', (parseInt(parameters.length) / 10)),
                    },
                ];
            default:
                return [];
        }
    }

    extractFields(items: KeyValuePair[][]): SummaryTableField[] {
        const fields = uniqBy(concat([], ...items), 'key');
        return fields.map((item) => pick(item, ['title', 'key']));
    }

    extractData(items: KeyValuePair[][]): SummaryTableDataItem[] {
        return items.map((item) => item.reduce((accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.key]: currentValue.value,
        }), {}));
    }

    render() {
        const items = (this.props.data || []).map((item) => {
            const preparedItem = this.prepareItem(item, this.props.accessory);
            preparedItem.push({
                title: 'Quantity',
                key: 'quantity',
                value: item.quantity.toString(),
            });
            return preparedItem;
        });

        return (
            <SummaryTable
                fields={this.extractFields(items)}
                data={this.extractData(items)}
            />
        );
    }

}
