import React, { Component, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Grid from '@mui/material/Grid';
import { sprintf } from 'sprintf-js';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';

import { ruleEvaluatorService } from '../../../../services/ruleEvaluatorService';
import { formService } from '../../../../services/formService';
import { BedOption, BedPropertyChoiceData, BedPropertyChoiceDataOption, BedPropertyMeasurementData, Interval, Rule } from '../../../../services/backendTypes';
import { requiredValidator } from '../../../../validators/requiredValidator';
import { minNumberValidator } from '../../../../validators/minNumberValidator';
import { sizeValidator } from '../../../../validators/sizeValidator';
import { disabledValidator } from '../../../../validators/disabledValidator';
import { sizeExistsValidator } from '../../../../validators/sizeExistsValidator';
import { requiredSizeValidator } from '../../../../validators/requiredValidator';
import { conditionalRequiredValidator } from '../../../../validators/conditionalRequiredValidator';
import { maxLinesValidator } from '../../../../validators/maxLinesValidator';
import { maxCharactersPerLineValidator } from '../../../../validators/maxCharactersValidator';
import * as authActions from '../../../../layouts/Auth/actions';
import * as orderFormActions from '../../actions';
import * as appActions from '../../../../actions';
import { convertToNumber } from '../../../../functions/convertToNumber';
import { detectChanges } from '../../../../functions/detectChanges';
import { BedHeader } from './BedHeader';
import { BedFooter } from './BedFooter';
import { FormField } from '../../../../components/formFields/FormField';
import SizeField, { SizeFieldProps } from '../../../../components/formFields/SizeField';
import SelectField, { SelectFieldProps } from '../../../../components/formFields/SelectField';
import NumberField, { NumberFieldProps } from '../../../../components/formFields/NumberField';
import Button from '../../../../components/formFields/Button';
import AutosizeTextField, { AutosizeTextFieldProps } from '../../../../components/formFields/AutosizeTextField';
import { combineSpinners, SpinnerProps } from '../../../../components/Spinner/Spinner';
import { errorMessages } from '../../../../constants/errorMessages';
import Card from '../Card';
import { OrderFormBedItem, SimplifiedQuoteBuilderResponseItem } from '../../reducer';
import { AppDispatch, ReduxState } from '../../../../store';
import { AuthBedProperty } from '../../../../layouts/reducers';
import { prepareRequestDataBedItem } from '../../functions';
import { splitBaseSizeValidator } from '../../../../validators/splitBaseSizeValidator';

type CombinedProps = Props & ReduxProps;
type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };

interface Props {
    quoteId: number;
    data: OrderFormBedItem;
    simplifiedQuoteBuilderResponseItem: SimplifiedQuoteBuilderResponseItem;
    quoteBuilderResponseSpinner: SpinnerProps;
    disabled: boolean;
    onAdd: () => void;
    onDuplicate: () => void;
    onRemove: () => void;
    onChange: (values: Partial<OrderFormBedItem>) => void;
    onChangeBed: () => void;
    onDebugClick: () => void;
}

interface State {
    activeBedCode?: string;
}

const mapStateToProps = (store: ReduxState) => ({
    currentCustomer: store.auth.currentCustomer,
    hideEndConsumerPrices: store.auth.hideEndConsumerPrices,
    hideWholesalePrices: store.auth.hideWholesalePrices,
    beds: store.auth.beds,
    bedProperties: store.auth.bedProperties,
    campaignCode: store.orderForm.data.campaignCode,
    idpCode: store.orderForm.data.idpCode,
    orderType: store.orderForm.data.orderType,
});

class Bed extends Component<CombinedProps> {

    state: State = {};
    hasApprovedCustomerPriceChange = false;
    willUnmount = false;
    priceManuallyChanged = false;
    confirmDialogIsOpen = false;

    constructor(props) {
        super(props);
        this.loadPrice = debounce(this.loadPrice, 300);
    }

    componentWillUnmount() {
        this.willUnmount = true;
    }

    componentDidUpdate(prevProps: CombinedProps) {
        const changesDetectedInProps = detectChanges(prevProps, this.props, [
            'currentCustomer',
            'campaignCode',
            'idpCode',
        ]);
        const changesDetectedInPropsData = detectChanges(prevProps.data, this.props.data, [
            'quantity',
            'width',
            'length',
            'sizeNationKey',
            'color',
            'split_mattress',
            'firmness',
            'split_base',
            'split_top_mattress',
            'top_mattress_type',
            'trim',
            'nameplate_type',
            'nameplate',
            'left_nameplate',
            'right_nameplate',
            'vividus_legs',
            'legs',
            'middle_leg_link',
            'bottom_list',
            'type',
        ]);
        if (changesDetectedInProps || changesDetectedInPropsData) {
            this.loadPrice();
        }
    }

    shouldComponentUpdate(nextProps: CombinedProps) {
        // Only update if a prop has changed to boost performance for large quotes
        return detectChanges(nextProps, this.props, [
            'quoteId',
            'data',
            'disabled',
            'campaignCode',
            'idpCode',
            'hideEndConsumerPrices',
            'hideWholesalePrices',
            'currentCustomer',
            'simplifiedQuoteBuilderResponseItem',
            'quoteBuilderResponseSpinner',
            'beds',
            'bedProperties',
        ]);
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey) {
            switch (event.keyCode) {
                case 78: // n
                    this.props.onAdd();
                    event.preventDefault();
                    break;
                case 82: // r
                    this.props.onRemove();
                    event.preventDefault();
                    break;
                case 68: // d
                    this.props.onDuplicate();
                    event.preventDefault();
                    break;
            }
        }
    }

    loadBedProperties(bedCode: string) {
        const beds = this.prepareBeds(this.props.beds.data);
        const bedExists = !!beds.find((bed) => bed.code === bedCode);
        if (bedCode && !this.props.bedProperties[bedCode] && bedExists) {
            this.props.dispatch(authActions.loadBedProperties([bedCode])).then(() => {
                this.props.onChangeBed();
                this.props.onChange({ type: bedCode });
            });
        } else {
            this.props.onChangeBed();
            this.props.onChange({ type: bedCode });
        }
    }

    canLoadPrice(): boolean {
        const requirements = [
            !!this.props.currentCustomer,
            !!this.props.data.type,
            !!this.props.bedProperties[this.props.data.type]?.data,
            !!formService.isValid(sprintf('^bed-%s--', this.props.data._key)),
        ];
        return requirements.every((requirement) => requirement);
    }

    loadPrice() {
        if (this.canLoadPrice()) {
            this.hasApprovedCustomerPriceChange = false;
            this.props.dispatch(orderFormActions.addToMaterialsQueue({
                items: [prepareRequestDataBedItem(this.props.data)],
                customerNumber: this.props.currentCustomer.key,
                campaignCode: this.props.campaignCode,
                idpCode: this.props.idpCode,
                orderType: this.props.orderType,
                id: this.props.quoteId,
            }));
        } else {
            this.props.dispatch(orderFormActions.removeMaterials(this.props.data._key));
        }
    }

    warnUserRegardingQtyChange(value) {
        if (!this.priceManuallyChanged) {
            this.props.dispatch(appActions.confirm('The quantity of the bed has been changed, make sure the quantity is correct before placing the order.', { trueAction: 'Accept', falseAction: 'Decline' })).then((response) => {
                if (response === true) {
                    this.props.onChange({ quantity: value });
                }
            });
        } else {
            this.props.onChange({ quantity: value });
        }
        this.priceManuallyChanged = false;
    }

    shouldValueChange(value, event: Event): boolean | Promise<boolean> {
        if (this.willUnmount) {
            return false;
        }
        const endConsumerPrice = convertToNumber(this.props.data.endConsumerPrice, true);
        const recommendedPrice = convertToNumber(this.props.simplifiedQuoteBuilderResponseItem?.recommendedPrice, true);
        const customerPriceHasNotChanged = endConsumerPrice === recommendedPrice;
        const isComputerAction = event === undefined;
        if (customerPriceHasNotChanged || this.hasApprovedCustomerPriceChange || isComputerAction) {
            return true;
        } else if (this.confirmDialogIsOpen === false) {
            this.priceManuallyChanged = true;
            this.confirmDialogIsOpen = true;
            return this.props.dispatch(appActions.confirm('If you change the contents of the quote, the manual value previously added will change.', { trueAction: 'Accept', falseAction: 'Decline' })).then((response) => {
                this.hasApprovedCustomerPriceChange = response;
                this.confirmDialogIsOpen = false;
                return response;
            });
        }
    }

    evaluateProperty(propertyCode: keyof OrderFormBedItem): boolean {
        const bedProperties = this.props.bedProperties[this.props.data.type]?.data || [];
        const bedProperty = bedProperties.find((property) => property.code === propertyCode);
        const rules: Rule[] = bedProperty?.rules || [];
        return ruleEvaluatorService.evaluate(rules, this.props.data);
    }

    prepareBeds(beds: BedOption[]): BedOption[] {
        return beds.map((bed) => {
            const campaignIsNotActive = !!bed.campaignCodes && !bed.campaignCodes.includes(this.props.campaignCode);
            return {
                ...bed,
                disabled: campaignIsNotActive,
                hidden: campaignIsNotActive,
            };
        });
    }

    render() {
        const bedProperties = this.props.bedProperties[this.props.data.type] || {} as AuthBedProperty;
        const hasBedProperties = !!bedProperties?.data;
        const activeBedProperties = this.props.bedProperties[this.state.activeBedCode] || {} as AuthBedProperty;
        const beds = this.prepareBeds(this.props.beds.data);
        const namePlateTextIsRequired = this.props.data.type === '2000t';
        const namePlateTextIsRequiredText = namePlateTextIsRequired ? ' *' : '';
        const selectedBed = this.props.beds.data.find((item) => item.code === this.props.data.type);
        const openingOrder = this.props.orderType.serviceType === 'openingOrder';

        const menuItems = (bedProperties.data || []).reduce<{ [code: string]: Pick<BedPropertyChoiceDataOption, 'code' | 'displayName' | 'image'>[] }>((result, bedProperty) => {
            if (bedProperty.dataType === 'choice') {
                result[bedProperty.code] = (bedProperty.data as BedPropertyChoiceData).options
                    .filter((option) => ruleEvaluatorService.evaluate(option.rules, this.props.data))
                    .map((option) => pick(option, ['code', 'displayName', 'image']));
            }
            return result;
        }, {});

        const intervals = (bedProperties.data || []).reduce<{ [code: string]: Interval[] }>((result, bedProperty) => {
            if (bedProperty.dataType === 'measurement') {
                result[bedProperty.code] = (bedProperty.data as BedPropertyMeasurementData).intervals
                    .filter((interval) => ruleEvaluatorService.evaluate(interval.rules, this.props.data))
                    .map((interval) => pick(interval, ['from', 'to']));
            }
            return result;
        }, {});

        const displaySizeHelpText = (bedProperties.data || []).some((bedProperty) => {
            return (bedProperty.code === 'width' || bedProperty.code === 'length') && (bedProperty.data as BedPropertyMeasurementData).intervals.length > 1;
        });

        const selectedColor = menuItems.color?.find((item) => item.code === this.props.data.color);

        return (
            <Card
                className="c--bed"
                layout="vertical"
                onKeyDown={this.handleKeyDown.bind(this)}
                content={
                    <Fragment>
                        <BedHeader
                            color={selectedColor}
                            type={selectedBed}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormField<SelectFieldProps>
                                    disabled={this.props.disabled}
                                    component={SelectField}
                                    validators={[
                                        disabledValidator(beds, 'code'),
                                    ]}
                                    autoFocus={this.props.data._new}
                                    id={sprintf('bed-%s--type', this.props.data._key)}
                                    label="Type *"
                                    fullWidth={true}
                                    value={this.props.data.type}
                                    menuItems={beds}
                                    itemLabel="displayName"
                                    itemValue="code"
                                    itemAvatar="image"
                                    onChange={(value) => {
                                        this.loadBedProperties(value);
                                        this.setState({ activeBedCode: value });
                                    }}
                                    shouldValueChange={this.shouldValueChange.bind(this)}
                                    spinner={combineSpinners([this.props.beds.spinner, activeBedProperties.spinner])}
                                />
                            </Grid>
                            {hasBedProperties && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SizeFieldProps>
                                        disabled={this.props.disabled}
                                        component={SizeField}
                                        validators={[
                                            requiredSizeValidator,
                                            sizeExistsValidator(bedProperties.sizeOptions, bedProperties.allowCustomSize),
                                            sizeValidator(
                                                intervals.width[0],
                                                intervals.length[0],
                                                // Currently we only have multiple intervals for split base so we can assume the help text is for split base.
                                                displaySizeHelpText ? 'Interval may be affected by split base configuration.' : undefined,
                                            ),
                                            splitBaseSizeValidator(this.props.data.split_base === 'yes'),
                                        ]}
                                        id={sprintf('bed-%s--size', this.props.data._key)}
                                        selectLabel="Size *"
                                        allowCustomSize={bedProperties.allowCustomSize}
                                        customLabel="Custom size in cm *"
                                        customHelpText="Example: 80x190"
                                        fullWidth={true}
                                        value={{
                                            width: this.props.data.width,
                                            length: this.props.data.length,
                                            sizeNationKey: this.props.data.sizeNationKey,
                                        }}
                                        menuItems={bedProperties.sizeOptions}
                                        preferredNation={this.props.currentCustomer.nation}
                                        onChange={(values) => {
                                            this.props.onChange(values);
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('color') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[
                                            requiredValidator,
                                            disabledValidator(menuItems.color, 'code'),
                                        ]}
                                        id={sprintf('bed-%s--color', this.props.data._key)}
                                        label="Color/Fabric *"
                                        fullWidth={true}
                                        value={this.props.data.color}
                                        menuItems={menuItems.color}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ color: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('split_mattress') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        defaultToFirst
                                        id={sprintf('bed-%s--split-mattress', this.props.data._key)}
                                        label="Split mattress *"
                                        fullWidth={true}
                                        value={this.props.data.split_mattress}
                                        menuItems={menuItems.split_mattress}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ split_mattress: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('firmness') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        id={sprintf('bed-%s--firmness', this.props.data._key)}
                                        label="Firmness *"
                                        fullWidth={true}
                                        value={this.props.data.firmness}
                                        menuItems={menuItems.firmness}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ firmness: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={2}>
                            {hasBedProperties && this.evaluateProperty('split_base') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        defaultToFirst
                                        id={sprintf('bed-%s--split-base', this.props.data._key)}
                                        label="Split base *"
                                        fullWidth={true}
                                        value={this.props.data.split_base}
                                        menuItems={menuItems.split_base}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ split_base: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('split_top_mattress') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        defaultToFirst
                                        id={sprintf('bed-%s--split-top-mattress', this.props.data._key)}
                                        label="Split top mattress *"
                                        fullWidth={true}
                                        value={this.props.data.split_top_mattress}
                                        menuItems={menuItems.split_top_mattress}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ split_top_mattress: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('top_mattress_type') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        id={sprintf('bed-%s--top-mattress-type', this.props.data._key)}
                                        label="Top mattress type *"
                                        fullWidth={true}
                                        value={this.props.data.top_mattress_type}
                                        menuItems={menuItems.top_mattress_type}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ top_mattress_type: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('trim') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        id={sprintf('bed-%s--trim', this.props.data._key)}
                                        label="Trim metal details *"
                                        fullWidth={true}
                                        value={this.props.data.trim}
                                        menuItems={menuItems.trim}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ trim: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('nameplate_type') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        id={sprintf('bed-%s--nameplate-type', this.props.data._key)}
                                        label="Nameplate *"
                                        fullWidth={true}
                                        defaultToFirst={this.props.data.type !== '2000t'}
                                        value={this.props.data.nameplate_type}
                                        menuItems={menuItems.nameplate_type}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ nameplate_type: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('nameplate') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<AutosizeTextFieldProps>
                                        disabled={this.props.disabled}
                                        validators={[
                                            conditionalRequiredValidator(namePlateTextIsRequired),
                                            maxLinesValidator(2),
                                            maxCharactersPerLineValidator(20),
                                        ]}
                                        component={AutosizeTextField}
                                        id={sprintf('bed-%s--nameplate', this.props.data._key)}
                                        helpText="2 lines maximum"
                                        label={'Nameplate text' + namePlateTextIsRequiredText}
                                        value={this.props.data.nameplate || ''}
                                        onChange={(value) => {
                                            this.props.onChange({ nameplate: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                        backgroundStriped
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('left_nameplate') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<AutosizeTextFieldProps>
                                        disabled={this.props.disabled}
                                        validators={[
                                            conditionalRequiredValidator(namePlateTextIsRequired),
                                            maxLinesValidator(2),
                                            maxCharactersPerLineValidator(20),
                                        ]}
                                        component={AutosizeTextField}
                                        id={sprintf('bed-%s--left-nameplate', this.props.data._key)}
                                        helpText="2 lines maximum"
                                        label={'Nameplate left base text' + namePlateTextIsRequiredText}
                                        value={this.props.data.left_nameplate || ''}
                                        onChange={(value) => {
                                            this.props.onChange({ left_nameplate: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                        backgroundStriped
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('right_nameplate') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<AutosizeTextFieldProps>
                                        disabled={this.props.disabled}
                                        validators={[
                                            conditionalRequiredValidator(namePlateTextIsRequired),
                                            maxLinesValidator(2),
                                            maxCharactersPerLineValidator(20),
                                        ]}
                                        component={AutosizeTextField}
                                        id={sprintf('bed-%s--right-nameplate', this.props.data._key)}
                                        helpText="2 lines maximum"
                                        label={'Nameplate right base text' + namePlateTextIsRequiredText}
                                        value={this.props.data.right_nameplate || ''}
                                        onChange={(value) => {
                                            this.props.onChange({ right_nameplate: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                        backgroundStriped
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={2}>
                            {hasBedProperties && this.evaluateProperty('vividus_legs') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        id={sprintf('bed-%s--vividus-legs', this.props.data._key)}
                                        label="Legs *"
                                        fullWidth={true}
                                        value={this.props.data.vividus_legs}
                                        menuItems={menuItems.vividus_legs}
                                        defaultToFirst
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ vividus_legs: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('legs') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        id={sprintf('bed-%s--legs', this.props.data._key)}
                                        label="Legs *"
                                        fullWidth={true}
                                        value={this.props.data.legs}
                                        menuItems={menuItems.legs}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ legs: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('middle_leg_link') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        defaultToFirst
                                        id={sprintf('bed-%s--middle-leg-link', this.props.data._key)}
                                        label="Link Leg"
                                        fullWidth={true}
                                        value={this.props.data.middle_leg_link}
                                        menuItems={menuItems.middle_leg_link}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        itemAvatar="image"
                                        onChange={(value) => {
                                            this.props.onChange({ middle_leg_link: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && this.evaluateProperty('bottom_list') && (
                                <Grid item xs={12} md={4}>
                                    <FormField<SelectFieldProps>
                                        disabled={this.props.disabled}
                                        component={SelectField}
                                        validators={[requiredValidator]}
                                        defaultToFirst
                                        id={sprintf('bed-%s--bottom-list', this.props.data._key)}
                                        label="Bottom List"
                                        fullWidth={true}
                                        value={this.props.data.bottom_list}
                                        menuItems={menuItems.bottom_list}
                                        itemLabel="displayName"
                                        itemValue="code"
                                        onChange={(value) => {
                                            this.props.onChange({ bottom_list: value });
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                            {hasBedProperties && (
                                <Grid item xs={12} md={4}>
                                    <FormField<NumberFieldProps>
                                        disabled={this.props.disabled}
                                        component={NumberField}
                                        validators={[minNumberValidator(1)]}
                                        min={1}
                                        id={sprintf('bed-%s--quantity', this.props.data._key)}
                                        label="Bed quantity *"
                                        value={this.props.data.quantity}
                                        onChange={(value) => {
                                            this.warnUserRegardingQtyChange(value);
                                        }}
                                        shouldValueChange={this.shouldValueChange.bind(this)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <div className="bed-notice-container">
                            {'yes' === this.props.data.split_base && 'yes' === this.props.data.split_mattress && (
                                <p>“Split mattress” together with “Split base” is not a recommended configuration</p>
                            )}
                            {this.props.simplifiedQuoteBuilderResponseItem?.blockStatus === 96 && (
                                <p>{errorMessages.notAllowed}</p>
                            )}
                            {this.props.simplifiedQuoteBuilderResponseItem?.blockStatus === 97 && (
                                <p>{errorMessages.discontinued}</p>
                            )}
                        </div>
                    </Fragment>
                }
                summary={
                    <BedFooter
                        data={this.props.data}
                        simplifiedQuoteBuilderResponseItem={this.props.simplifiedQuoteBuilderResponseItem}
                        quoteBuilderResponseSpinner={this.props.quoteBuilderResponseSpinner}
                        hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                        hideWholesalePrices={this.props.hideWholesalePrices}
                        openingOrder={openingOrder}
                        onChangeEndConsumerPrice={(value) => {
                            this.props.onChange({ endConsumerPrice: value });
                        }}
                        onChangeEndConsumerDiscount={(value) => {
                            this.props.onChange({ endConsumerDiscount: value });
                        }}
                        onDebugClick={this.props.onDebugClick}
                        disabled={!this.canLoadPrice()}
                    />
                }
                actions={
                    <Fragment>
                        <Button icon="content_copy" disabled={this.props.disabled} onClick={this.props.onDuplicate} tooltipLabel="Copy" tooltipPosition="right" />
                        <Button icon="delete" disabled={this.props.disabled} onClick={this.props.onRemove} tooltipLabel="Remove" tooltipPosition="right" />
                    </Fragment>
                }
            />
        );
    }
}

const connector = connect(mapStateToProps);
export default connector(Bed);
