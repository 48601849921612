import React, { ReactElement } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import { Spinner, SpinnerProps } from '../../components/Spinner/Spinner';
import { QuoteAccessoryItem, QuoteBedItem, QuoteItem } from '../../services/backendTypes';

export interface TooltipProps {
    spinner: SpinnerProps;
    onOpen: () => void;
    items: QuoteItem[];
    active?: boolean;
    children?: ReactElement;
}

Tooltip.defaultProps = {
    items: [],
};

export default function Tooltip(props: TooltipProps): ReactElement {
    if (props.active === false) {
        return props.children;
    }

    const bedItems = props.items.filter((item) => item.itemType === 'bed') as QuoteBedItem[];
    const accessoryItems = props.items.filter((item) => item.itemType === 'accessory') as QuoteAccessoryItem[];

    return (
        <MuiTooltip
            arrow
            disableTouchListener
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.14)',
                        padding: 0,
                        borderRadius: 0,
                    },
                },
                arrow: {
                    sx: {
                        color: '#fff',
                    },
                },
            }}
            enterDelay={400}
            enterNextDelay={400}
            placement="right"
            onOpen={props.onOpen}
            title={
                <div className="c--sidebar-tooltip">
                    <Spinner
                        {...props.spinner}
                        defaultElement={(
                            <div>
                                {!!bedItems && (
                                    <ul className="bed-list">
                                        {bedItems.map((bedItem, index) => {
                                            const details = mapValues(keyBy(bedItem.parametersDetails, 'key'), 'valueLabel');
                                            return (
                                                <li key={index}>
                                                    <span className="primary-content">{bedItem.name}</span>
                                                    <span className="secondary-content">{details.size}</span>
                                                    <span className="secondary-content">{details.color}</span>
                                                    <span className="secondary-content">{details.firmness}</span>
                                                    <span className="secondary-content">{details.top_mattress_type}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {!!accessoryItems?.length && (
                                    <div className="accessories-count">
                                        <span>{accessoryItems.length} {accessoryItems.length === 1 ? 'accessory' : 'accessories'}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
            }>
            {props.children}
        </MuiTooltip>
    );

}
