import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

export function removeUnderscoreProperties(data: any) {
    if (isArray(data)) {
        return data.map(removeUnderscoreProperties);
    }

    if (isPlainObject(data)) {
        const validKeys = pickBy(data, (value: any, key: string) => {
            return !key.startsWith('_');
        });
        return mapValues(validKeys, removeUnderscoreProperties);
    }
    return data;
}
