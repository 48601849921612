import React from 'react';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

interface Props {
    size?: 'small' | 'medium';
}

export function NotAvailableIcon(props: Props) {
    return (
        <Tooltip title="Not available" placement="right">
            <Icon
                sx={{
                    margin: props.size === 'small' ? 0 : '10px',
                    fontSize: props.size === 'small' ? '16px' : null,
                    verticalAlign: 'middle',
                }}
                color="disabled">
                do_not_disturb
            </Icon>
        </Tooltip>
    );
}
