class SessionStorageService {

    private readonly name = 'session';
    private readonly prefix = 'Bearer ';

    public setToken(token: string) {
        if (!token) {
            return;
        }
        if (token.startsWith(this.prefix)) {
            token = token.substr(this.prefix.length);
        }
        this.setItem(this.name, token);
    }

    public getToken(): string {
        const token = this.getTokenWithoutPrefix();
        if (token) {
            return this.prefix + token;
        }
    }

    public hasToken(): boolean {
        return !!this.getItem(this.name);
    }

    public removeToken() {
        this.removeItem(this.name);
    }

    public getTokenWithoutPrefix(): string {
        return this.getItem(this.name);
    }

    public setItem(key: string, value: string) {
        if (key) {
            window.sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    public getItem(key: string): string {
        const item = window.sessionStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        }
    }

    public removeItem(key: string) {
        window.sessionStorage.removeItem(key);
    }
}

export const sessionStorageService = new SessionStorageService();
