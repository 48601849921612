import React, { ReactElement, Component } from 'react';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';

import * as orderFormActions from '../actions';
import { NotificationIcon } from '../../../components/icons/NotificationIcon';
import Button from '../../../components/formFields/Button';
import { OrderFormProps } from '../OrderForm';

interface State {
    popoverVisible: boolean;
    seen: boolean;
}

export class NotificationButton extends Component<OrderFormProps, State> {

    state = {
        popoverVisible: false,
        seen: false,
    };

    componentDidMount() {
        this.props.dispatch(orderFormActions.getNotifications());
    }

    notificationButtonPressed() {
        this.setState({
            popoverVisible: !this.state.popoverVisible,
            seen: true,
        });
        this.props.dispatch(orderFormActions.notificationsRead(this.props.orderForm.notifications));
    }

    render(): ReactElement {
        if (!this.props.orderForm.notifications || this.props.orderForm.notifications.length === 0) {
            return null;
        }

        const popoverClassName = ['popover'];
        if (this.state.popoverVisible) {
            popoverClassName.push('visible');
        }

        const maxWidth = window.innerWidth - 60;

        const data = this.props.orderForm.notifications;
        const messages = data.map((item) => (
            <div key={item.id}>
                <h1>{item.header}</h1>
                <p>{item.text}</p>
            </div>
        ));

        const newNotifications = data.map((item) => item.id);
        // !buttonClicked && newNotification.diff(oldNotifications) is not empty
        const hasNew = !this.state.seen &&
            newNotifications.filter((x) => !this.props.orderForm.readNotifications.includes(x)).length > 0;

        return (
            <div className="c--notification-button">
                <div className={popoverClassName.join(' ')} style={{ maxWidth: maxWidth }}>
                    <div className="popover-header">
                        <span>Notifications</span>
                        <Button
                            icon="close"
                            color="light"
                            className="close-button"
                            onClick={() => this.setState({ popoverVisible: false })}
                        />
                    </div>
                    <div className="popover-body">
                        {messages}
                    </div>
                </div>
                <Badge
                    classes={{ badge: 'badge-extra' }}
                    badgeContent={data.length}
                    overlap="circular"
                    color={hasNew ? 'error' : 'primary'}>
                    <Fab
                        size="large"
                        color="inherit"
                        className="notification-button"
                        onClick={this.notificationButtonPressed.bind(this)}>
                        <NotificationIcon className="notification-icon" />
                    </Fab>
                </Badge>
            </div>
        );
    }

}


