import React, { Fragment, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import * as authActions from './actions';
import * as appActions from '../../actions';
import * as formActions from '../../components/formFields/actions';
import { Routes } from './Routes';
import LoginDialog from './LoginDialog';
import DebugDialog from './dialogs/DebugDialog/DebugDialog';
import { ERROR_TOAST_CONFIG } from '../../Snackbar';
import SummaryDialog from './dialogs/SummaryDialog/SummaryDialog';
import { VersionBanner } from './VersionBanner';
import { LogoIcon } from '../../components/icons/LogoIcon';
import FirstLevelMenuItem, { FirstLevelProps } from './FirstLevelMenuItem';
import { SecondLevelProps } from './SecondLevelMenuItem';
import { TooltipProps } from './Tooltip';
import { backendService, ErrorData } from '../../services/backendService';
import { sessionStorageService } from '../../services/sessionStorageService';
import { Can } from '../../components/Can/Can';
import MenuButton from '../../components/formFields/MenuButton';
import { MY_HASTENS_URL } from '../../containers/Login/LoginForm';
import { AppDispatch, ReduxState } from '../../store';
import { AppProps } from '../../App';
import { AuthCustomer, AuthPermission, ListQuote } from '../../services/backendTypes';
import { CUSTOMER_SERVICE_USER } from '../../appConfig';
import VersionDialog from './dialogs/VersionDialog';

export type AuthProps = ReduxProps & RouteComponentProps<AuthRouteParams> & AppProps;
type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };

export interface AuthRouteParams {
    customerKey: string;
}

const drawerWidth = 256;

const mapStateToProps = (store: ReduxState) => ({
    auth: store.auth,
});

function Auth(props: AuthProps) {

    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPrint = useMediaQuery('print');
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const isCustomerServiceUser = props.app.config.account.username === CUSTOMER_SERVICE_USER;
    const navItems = getNavItems();
    const jwt = sessionStorageService.getTokenWithoutPrefix();
    const myHastensUrl = `${MY_HASTENS_URL}en/my-hastens/login-jwt?jwt=${jwt}`;
    const versionBannerIsVisible = props.app.versionBannerVisible && !props.app.versionDialogIsVisible;

    useEffect(() => {
        const authCheckInterval = startAuthCheckInterval();
        const versionCheckInterval = startVersionCheckInterval();
        const errorHandlerId = backendService.registerErrorHandler(handleBackendError);
        setCurrentCustomerInState(props.match.params.customerKey);

        return () => {
            window.clearInterval(authCheckInterval);
            window.clearInterval(versionCheckInterval);
            backendService.unregisterErrorHandler(errorHandlerId);
        };
    }, []);

    useEffect(() => {
        if (props.auth.currentCustomer.key !== props.match.params.customerKey) {
            setCurrentCustomerInState(props.match.params.customerKey);
        }
    });

    useEffect(() => {
        if (props.auth.currentCustomer.key) {
            props.dispatch(authActions.loadBeds(props.auth.currentCustomer.key));
            props.dispatch(authActions.loadAccessories(props.auth.currentCustomer.key));
        }
    }, [props.auth.currentCustomer.key]);

    useEffect(() => {
        if (isPrint && drawerIsOpen) {
            setDrawerIsOpen(false);
        }
    }, [isPrint]);

    useEffect(() => {
        if (isMobile) {
            setDrawerIsOpen(false);
        }
    }, [location]);

    function getNavItems(): FirstLevelProps[] {
        return [
            {
                primaryText: 'Partner',
                icon: 'location_on',
                showSubmenu: true,
                hidden: (props.app.config.customers || []).length <= 1 || isMobile === false || isCustomerServiceUser === true,
                routes: (props.app.config.customers || []).map((customer): SecondLevelProps => ({
                    primaryText: customer.name1,
                    onClick: () => setCurrentCustomerInUrl(customer),
                    selected: props.auth.currentCustomer && props.auth.currentCustomer.key === customer.key,
                    key: customer.key,
                })),
            },
            {
                primaryText: 'Quotes',
                icon: 'create',
                spinner: props.auth.quotes?.spinner,
                to: `/auth/${props.auth.currentCustomer.key}/quote`,
                routes: (props.auth.quotes?.data || []).map((item): SecondLevelProps => ({
                    primaryText: `#${item.incrementId}`,
                    secondaryText: [getCreatedBy(item), `PO: ${item.purchaseOrderNumber || '---'}`],
                    to: `/auth/${props.auth.currentCustomer.key}/quote/${item.id}`,
                    key: item.id,
                    tooltip: getTooltipProps(item),
                    disabled: item.version === 'V1',
                    actions: [
                        {
                            label: 'Customer quotation',
                            spinner: props.auth.spinners.customerQuotation[item.incrementId],
                            icon: 'save_alt',
                            onClick: () => downloadCustomerQuotation(item),
                        },
                        {
                            label: 'Delete',
                            icon: 'delete',
                            onClick: () => deleteQuote({
                                ...item,
                                uri: `/auth/${props.auth.currentCustomer.key}/quote/${item.id}`,
                            }),
                            needsConfirm: true,
                        },
                    ],
                })),
            },
            {
                divider: true,
            },
            {
                primaryText: 'View all quotes',
                to: `/auth/${props.auth.currentCustomer.key}/quotes`,
                emphasized: true,
            },
            {
                divider: true,
            },
            {
                primaryText: 'Online Quotes',
                icon: 'laptop_mac',
                spinner: props.auth.onlineQuotes?.spinner,
                to: `/auth/${props.auth.currentCustomer.key}/quote`,
                routes: (props.auth.onlineQuotes?.data || []).map((item): SecondLevelProps => ({
                    to: `/auth/${props.auth.currentCustomer.key}/quote/${item.id}`,
                    primaryText: `#${item.incrementId}`,
                    secondaryText: [getCreatedBy(item), `PO: ${item.purchaseOrderNumber || '---'}`],
                    key: item.id,
                    tooltip: getTooltipProps(item),
                    disabled: item.version === 'V1',
                    actions: [
                        {
                            label: 'Customer online quotation',
                            spinner: props.auth.spinners.customerQuotation[item.incrementId],
                            icon: 'save_alt',
                            onClick: () => downloadCustomerQuotation(item),
                        },
                        {
                            label: 'Delete',
                            icon: 'delete',
                            onClick: () => deleteQuote({
                                ...item,
                                uri: `/auth/${props.auth.currentCustomer.key}/quote/${item.id}`,
                            }),
                            needsConfirm: true,
                        },
                    ],
                })),
            },
            {
                primaryText: 'Orders',
                icon: 'local_shipping',
                spinner: props.auth.orders?.spinner,
                to: `/auth/${props.auth.currentCustomer.key}/orders`,
                routes: (props.auth.orders?.data || []).map((item): SecondLevelProps => ({
                    primaryText: `#${item.incrementId}`,
                    secondaryText: [getCreatedBy(item), `PO: ${item.purchaseOrderNumber || '---'}`],
                    to: `/auth/${props.auth.currentCustomer.key}/orders?id=${item.id}`,
                    key: item.id,
                    tooltip: getTooltipProps(item),
                    actions: [
                        {
                            label: 'Customer quotation',
                            spinner: props.auth.spinners.customerQuotation[item.incrementId],
                            icon: 'save_alt',
                            onClick: () => downloadCustomerQuotation(item),
                        },
                        {
                            label: 'Delivery confirmation',
                            spinner: props.auth.spinners.deliveryConfirmation[item.incrementId],
                            icon: 'save_alt',
                            onClick: () => downloadDeliveryConfirmation(item),
                        },
                    ],
                })),
            },
            {
                divider: true,
            },
            {
                primaryText: 'View all orders',
                to: `/auth/${props.auth.currentCustomer.key}/orders`,
                emphasized: true,
            },
            {
                divider: true,
            },
            {
                primaryText: 'View all templates',
                to: `/auth/${props.auth.currentCustomer.key}/templates`,
                emphasized: true,
            },
            {
                divider: true,
            },
            {
                primaryText: 'New quote',
                icon: 'add',
                to: `/auth/${props.auth.currentCustomer.key}/new-quote`,
            },
            {
                primaryText: 'Logout',
                icon: 'lock',
                onClick: () => {
                    props.history.push('/login');
                    props.dispatch(authActions.logout());
                },
            },
        ];
    }

    function handleBackendError(error: AxiosError<ErrorData>): Promise<boolean> {
        if (error.response.status === 401) {
            return props.dispatch(authActions.promtForPassword());
        }
        if (error.response.data.code === 'DEPRECATED_QUOTE') {
            return;
        }
        if (error.response.data.code === 'INVALID_PO_NUMBER') {
            props.dispatch(appActions.openAlertDialog('The PO number/name must be unique, replace the number with a new one and create order.'));
            return;
        }
        if (error.response.data.canRetry) {
            return props.dispatch(appActions.openSnackbar(error.response.data.message, {
                severity: 'error',
                trueAction: 'Retry',
                falseAction: 'Ignore',
                autohide: false,
            }));
        }
        props.dispatch(appActions.openSnackbar(error.response.data.message, ERROR_TOAST_CONFIG));
    }

    function downloadCustomerQuotation(item: ListQuote) {
        authActions.openCustomerQuotation(item.incrementId, props.auth.hideWholesalePrices);
    }

    function downloadDeliveryConfirmation(item: ListQuote) {
        authActions.openDeliveryConfirmation({
            id: item.id,
            orderNumber: item.incrementId,
        });
    }

    function startAuthCheckInterval(): number {
        let isChecking = false;
        let lastCheck: number = +new Date();
        // Using a short interval to react quickly when computer wakes up from sleep mode
        // Check is done every 30 seconds
        return window.setInterval(() => {
            if (isChecking || +new Date() - lastCheck < 30 * 1000) {
                return;
            }
            isChecking = true;
            backendService.getAuthConfig().then((response) => {
                lastCheck = +new Date();
                isChecking = false;
                if (!response.isLoggedIn) {
                    props.dispatch(authActions.promtForPassword());
                }
            });
        }, 500);
    }

    function startVersionCheckInterval(): number {
        return window.setInterval(() => {
            props.dispatch(appActions.loadVersion());
        }, 10 * 1000);
    }

    function setCurrentCustomerInUrl(customer: AuthCustomer) {
        if (props.location.pathname.includes('/quote/') || props.location.pathname.includes('/order/')) {
            props.history.push(`/auth/${customer.key}/new-quote`);
        } else {
            // Switch customer in url
            const newUrl = props.location.pathname.replace(/(\/auth\/)(\d*)/, '$1' + customer.key);
            if (newUrl !== props.location.pathname) {
                props.history.push(newUrl);
            }
        }
    }

    function setCurrentCustomerInState(customerKey: string) {
        const currentCustomer = props.app.config.customers.find((customer) => customer.key === customerKey);
        if (currentCustomer) {
            props.dispatch(authActions.setCurrentCustomer(currentCustomer));
            props.dispatch(authActions.loadSidebarQuotes(currentCustomer.key));
            props.dispatch(authActions.loadSidebarOrders(currentCustomer.key));
            props.dispatch(authActions.loadCustomerDeliveryAddresses(currentCustomer.key));
        } else {
            props.history.push('/login');
        }
    }

    function deleteQuote(item: ListQuote & { uri: string; }) {
        props.dispatch(authActions.deleteQuote(item.id)).then(() => {
            if (props.location.pathname.includes(item.uri)) {
                // Prevent popup when changing routes
                props.dispatch(formActions.markAsPristine());
                props.history.push(`/auth/${props.auth.currentCustomer.key}/new-quote`);
            }
        });
    }

    function handleToggleWholesalePrices(value: boolean) {
        if (props.auth.hideEndConsumerPrices === false && props.auth.hideWholesalePrices === true) {
            props.dispatch(authActions.hideEndConsumerPrices(true));
            props.dispatch(authActions.hideWholesalePrices(false));
        } else {
            props.dispatch(authActions.hideEndConsumerPrices(value));
        }
    }

    function handleToggleCustomerPrices(value: boolean) {
        if (props.auth.hideWholesalePrices === false && props.auth.hideEndConsumerPrices === true) {
            props.dispatch(authActions.hideWholesalePrices(true));
            props.dispatch(authActions.hideEndConsumerPrices(false));
        } else {
            props.dispatch(authActions.hideWholesalePrices(value));
        }
    }

    function getCreatedBy(item: ListQuote): string {
        const createdBy = item.order ? item.order.createdBy : item.createdBy;
        if (item.autogenerated) {
            return 'Partnerpage';
        } else if (createdBy?.contact?.firstname && createdBy?.contact?.lastname) {
            return `${createdBy.contact.firstname} ${createdBy.contact.lastname}`;
        } else {
            return createdBy?.contact?.primaryEmail?.value || '---';
        }
    }

    function getTooltipProps(quote: ListQuote): TooltipProps {
        const lazyQuotes = props.auth[quote.order ? 'lazyOrders' : 'lazyQuotes'] || {};
        const lazyQuote = lazyQuotes[quote.id]?.data;
        const lazyQuoteSpinner = lazyQuotes[quote.id]?.spinner;

        return {
            spinner: lazyQuoteSpinner,
            onOpen: () => {
                if (!lazyQuote) {
                    const action = quote.order ? authActions.lazyloadSidebarOrder(quote.id) : authActions.lazyloadSidebarQuote(quote.id);
                    props.dispatch(action);
                }
            },
            items: lazyQuote?.items,
        };
    }

    if (!props.auth.currentCustomer.key) {
        return null;
    }

    return (
        <Fragment>
            <Box sx={{ display: 'flex' }} className={clsx({ 'persistent-drawer--open': drawerIsOpen && !isMobile })}>
                <VersionBanner visible={versionBannerIsVisible} />
                <AppBar
                    position="fixed"
                    sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                        top: versionBannerIsVisible ? { xs: '105px', lg: '64px' } : null,
                        width: drawerIsOpen && !isMobile ? `calc(100% - ${drawerWidth}px)` : null,
                        marginLeft: drawerIsOpen && !isMobile ? `${drawerWidth}px` : null,
                        transition: () => {
                            if (drawerIsOpen && !isMobile) {
                                return theme.transitions.create(['margin', 'width'], {
                                    easing: theme.transitions.easing.easeOut,
                                    duration: theme.transitions.duration.enteringScreen,
                                });
                            }
                            return theme.transitions.create(['margin', 'width'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            });
                        },
                    }}>
                    <Toolbar>
                        {(!drawerIsOpen || isMobile) && (
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    setDrawerIsOpen(true);
                                }}
                                edge="start"
                                sx={{ marginRight: theme.spacing(1) }}>
                                <Icon>menu</Icon>
                            </IconButton>
                        )}
                        {isMobile === false && isCustomerServiceUser === false && props.app.config.customers.length > 1 && (
                            <MenuButton
                                buttonProps={{
                                    uppercase: false,
                                    icon: 'location_on',
                                    color: 'light',
                                    children: props.auth.currentCustomer.name1,
                                }}
                                menuItems={props.app.config.customers.map((customer) => ({
                                    primaryText: customer.name1,
                                    onClick: () => setCurrentCustomerInUrl(customer),
                                    active: props.auth.currentCustomer.key === customer.key,
                                }))}
                            />
                        )}
                        <SvgIcon
                            inheritViewBox
                            component={LogoIcon}
                            sx={{
                                width: '140px',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                marginLeft: '-70px',
                                fill: '#fff',
                            }}
                        />
                        <Box
                            sx={{
                                backgroundImage: 'url(https://static.hastens.com/polo/hastens_bluecheck_icon_20x20px.png)',
                                display: 'inline-block',
                                height: '20px',
                                width: '20px',
                                position: 'absolute',
                                top: '50%',
                                right: '16px',
                                marginTop: '-10px',
                            }}
                        />
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant={isMobile ? 'temporary' : 'persistent'}
                    sx={{
                        width: `${drawerWidth}px`,
                        flexShrink: 0,
                    }}
                    PaperProps={{
                        elevation: 2,
                        sx: {
                            width: `${drawerWidth}px`,
                            border: 0,
                            overflow: 'visible',
                        },
                    }}
                    anchor="left"
                    open={drawerIsOpen}
                    onClose={() => {
                        setDrawerIsOpen(false);
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '20px',
                            paddingRight: '14px',
                            justifyContent: 'space-between',
                            ...theme.mixins.toolbar,
                        }}>
                        <Box
                            component="a"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: '#000',
                                letterSpacing: 0.5,
                            }}
                            href={myHastensUrl}
                            target="_blank"
                            rel="noreferrer">
                            {`${props.app.config.account.contact.firstname} ${props.app.config.account.contact.lastname}`}
                        </Box>
                        <IconButton
                            sx={{ color: '#000' }}
                            onClick={() => {
                                setDrawerIsOpen(false);
                            }}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Box>
                    <Box sx={{ flex: 1, overflowY: 'auto' }}>
                        <List component="nav" sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {navItems.map((navItem, index) => (
                                <FirstLevelMenuItem {...navItem} key={index} />
                            ))}
                        </List>
                    </Box>
                    <Can customer={props.auth.currentCustomer} permission={AuthPermission.CAN_SEE_WHOLESALE_PRICE}>
                        <Box sx={{ padding: '12px 6px 12px 16px' }}>
                            <FormControlLabel
                                sx={{
                                    marginLeft: 0,
                                    marginRight: 0,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                componentsProps={{
                                    typography: {
                                        sx: {
                                            fontSize: '12px',
                                            fontWeight: 300,
                                            color: '#8b8b8b',
                                        },
                                    },
                                }}
                                label="Hide end consumer prices"
                                labelPlacement="start"
                                control={
                                    <Switch
                                        checked={props.auth.hideEndConsumerPrices}
                                        onChange={(event) => handleToggleWholesalePrices(event.target.checked)}
                                        name="wholesale-prices"
                                        color="primary"
                                        size="small"
                                    />
                                }
                            />
                            <FormControlLabel
                                sx={{
                                    marginLeft: 0,
                                    marginRight: 0,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                componentsProps={{
                                    typography: {
                                        sx: {
                                            fontSize: '12px',
                                            fontWeight: 300,
                                            color: '#8b8b8b',
                                        },
                                    },
                                }}
                                label="Hide wholesale prices"
                                labelPlacement="start"
                                control={
                                    <Switch
                                        checked={props.auth.hideWholesalePrices}
                                        onChange={(event) => handleToggleCustomerPrices(event.target.checked)}
                                        name="customer-prices"
                                        color="primary"
                                        size="small"
                                    />
                                }
                            />
                        </Box>
                    </Can>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        minWidth: 0,
                        paddingTop: versionBannerIsVisible ? { xs: '105px', lg: '64px' } : null,
                        marginLeft: isMobile ? null : (drawerIsOpen ? 0 : `-${drawerWidth}px`),
                        transition: () => {
                            if (!isMobile) {
                                if (drawerIsOpen) {
                                    return theme.transitions.create('margin', {
                                        easing: theme.transitions.easing.easeOut,
                                        duration: theme.transitions.duration.enteringScreen,
                                    });
                                }
                                return theme.transitions.create('margin', {
                                    easing: theme.transitions.easing.sharp,
                                    duration: theme.transitions.duration.leavingScreen,
                                });
                            }

                            return null;
                        },
                    }}>
                    <Box sx={{ ...theme.mixins.toolbar }} />
                    <Routes {...props} />
                </Box>
            </Box>
            <LoginDialog />
            <SummaryDialog />
            <DebugDialog />
            <VersionDialog
                open={props.app.versionDialogIsVisible}
                onClose={() => {
                    props.dispatch(appActions.closeVersionDialog());
                }}
            />
        </Fragment>
    );

}

const connector = connect(mapStateToProps);
export default connector(Auth);
