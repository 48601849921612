import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { CustomMenuItem } from './CustomMenuItem';
import { isIOS } from '../../functions/isIOS';
import { safeDivide, safeMultiply } from '../../functions/math';

export interface StringSizeFieldProps {
    id?: string;
    selectLabel?: string;
    customLabel?: string;
    value?: string;
    menuItems?: {
        displayName: string;
        value: string;
        disabled?: boolean;
    }[];
    error?: boolean;
    errorText?: string;
    selectHelpText?: ReactNode; // TODO: Change to string
    customHelpText?: string;
    onChange?: (value: string, event?: SelectChangeEvent<string>) => void;
    onBlur?: () => void;
    disabled?: boolean;
    allowCustomSize?: boolean;
    dimensions?: number;
}

const oneDimensionRegex = /^[1-9][0-9]{0,2}(,[05]?)?$/;
const twoDimensionsRegex = /^[1-9][0-9]{0,2}(,[05]?)?(x([1-9][0-9]{0,2}(,[05]?)?)?)?$/;

StringSizeField.defaultProps = {
    selectLabel: '',
    customLabel: '',
    value: '',
    menuItems: [],
    dimensions: 1,
} as StringSizeFieldProps;

export default function StringSizeField(props: StringSizeFieldProps) {
    const [customMode, setCustomMode] = useState(false);
    const [customValue, setCustomValue] = useState('');
    const [customAutoFocus, setCustomAutoFocus] = useState(false);
    const [selectIsOpen, setSelectIsOpen] = useState(false);

    useEffect(() => {
        switchToCustomModeIfNonExistingValue();
    }, []);

    function switchToCustomModeIfNonExistingValue() {
        if (!props.menuItems.length || !props.value || customMode || !props.allowCustomSize) {
            return;
        }
        const selectedMenuItem = props.menuItems.find((item) => item.value === props.value);

        if (!selectedMenuItem) {
            const splitValue = props.value.split('x').map((stringValueInMm) => {
                const numberValueInMm = parseFloat(stringValueInMm);
                const numberValueInCm = safeDivide(numberValueInMm, 10);
                return numberValueInCm.toString().replace('.', ',');
            });

            setCustomMode(true);
            setCustomValue(splitValue.join('x'));
        }
    }

    function prepareSelectValue(): string {
        const selectedByValue = props.menuItems.find((item) => item.value === props.value);
        if (selectedByValue) {
            return selectedByValue.displayName;
        }

        const selectedByDisplayName = props.menuItems.find((item) => item.displayName === props.value);
        if (selectedByDisplayName) {
            return selectedByDisplayName.displayName;
        }

        return '';
    }

    function handleChangeSelect(event: SelectChangeEvent<string>) {
        const selectedMenuItem = props.menuItems.find((item) => item.displayName === event.target.value);

        if (selectedMenuItem) {
            props.onChange(selectedMenuItem.value, event);
        } else {
            props.onChange('');
        }
    }

    function handleChangeCustom({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        const nextCustomValue = value.replace('.', ',');

        if (nextCustomValue && !nextCustomValue.match(props.dimensions === 2 ? twoDimensionsRegex : oneDimensionRegex)) {
            return;
        }
        const splitValue = nextCustomValue.replace(',', '.').split('x').map((stringValueInCm) => {
            const numberValueInCm = parseFloat(stringValueInCm);
            const numberValueInMm = safeMultiply(numberValueInCm, 10);
            return numberValueInMm.toString();
        });
        const nextValue = splitValue.join('x');

        setCustomValue(nextCustomValue);
        if (nextValue !== props.value) {
            props.onChange(nextValue);
        }
    }

    return (
        <>
            {customMode ? (
                <TextField
                    variant="standard"
                    id={props.id}
                    label={props.customLabel}
                    fullWidth
                    value={customValue}
                    onChange={handleChangeCustom}
                    onBlur={props.onBlur}
                    helperText={props.error ? props.errorText : props.customHelpText}
                    error={props.error}
                    disabled={props.disabled}
                    autoFocus={customAutoFocus}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        props.onChange('');
                                        setCustomMode(false);
                                        setCustomValue('');
                                    }}
                                    color="primary"
                                    size="small"
                                    edge="end">
                                    <Icon>close</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ) : (
                <FormControl
                    variant="standard"
                    error={props.error}
                    onKeyDown={(event) => {
                        // Close dropdown when keyboard shortcuts are used
                        if (event.ctrlKey && event.keyCode >= 65 && event.keyCode <= 90) {
                            setSelectIsOpen(false);
                        }
                    }}>
                    <InputLabel>{props.selectLabel}</InputLabel>
                    <Select
                        id={props.id}
                        MenuProps={{
                            // Bugfix: Disable animations on iOS devices to fix flickering behaviour when opening dropdown
                            transitionDuration: isIOS() ? 0 : undefined,
                        }}
                        value={prepareSelectValue()}
                        disabled={props.disabled}
                        open={selectIsOpen}
                        onOpen={() => {
                            setSelectIsOpen(true);
                        }}
                        onClose={() => {
                            setSelectIsOpen(false);
                        }}
                        onChange={handleChangeSelect}
                        onBlur={props.onBlur}
                        IconComponent={(iconProps) => <Icon {...iconProps}>arrow_drop_down</Icon>}>
                        {props.menuItems.map((menuItem, index) => (
                            <MenuItem key={index} value={menuItem.displayName} disabled={menuItem.disabled}>
                                {menuItem.displayName}
                            </MenuItem>
                        ))}
                        {props.allowCustomSize && (
                            <li><Divider></Divider></li>
                        )}
                        {props.allowCustomSize && (
                            <CustomMenuItem
                                clickHandler={() => {
                                    // Here we want to skip the required validator and display a size message so we set the value to "0" instead of ""
                                    props.onChange('0');
                                    setCustomMode(true);
                                    setSelectIsOpen(false);
                                    setCustomAutoFocus(true);
                                }}>
                                {props.customLabel + '...'}
                            </CustomMenuItem>
                        )}
                    </Select>
                    <FormHelperText>{props.error ? props.errorText : props.selectHelpText}</FormHelperText>
                </FormControl>
            )}
        </>
    );
}
