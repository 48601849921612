import React, { Component } from 'react';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

import { formService, Validator } from '../../services/formService';
import { store } from '../../store';
import * as formActions from './actions';

interface FormFieldProps {
    component: any;
    validators?: Validator[];
    id: string;
    value: any;
    pending?: boolean;
    shouldValueChange?: (nextValue, event) => boolean | Promise<boolean>;
    onBlur?: (event) => void;
    onChange?: (event) => void;
}

interface State {
    error: string | null;
}

export class FormField<T = unknown> extends Component<FormFieldProps & T, State> {

    state = {
        error: null,
    };

    componentDidMount() {
        this.validate();
    }

    componentWillUnmount() {
        formService.remove(this.props.id);
    }

    componentDidUpdate() {
        this.validate();
    }

    validate() {
        const error = formService.validate(this.props.id, this.props.value, this.props.validators);
        if (error !== this.state.error) {
            this.setState({ error: error });
        }
    }

    handleBlur(event: Event) {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    onChange(value, event: Event) {
        if (this.props.shouldValueChange) {
            const shouldValueChange = this.props.shouldValueChange(value, event);
            if (isObject(shouldValueChange)) {
                (shouldValueChange as any).then((response: boolean) => {
                    response && this.changeValue(value, event);
                });
            } else if (shouldValueChange === true) {
                this.changeValue(value, event);
            }
        } else {
            this.changeValue(value, event);
        }
    }

    changeValue(value, event: Event) {
        if (typeof event !== 'undefined') {
            // If event is not undefined then user has triggered the event
            store.dispatch<any>(formActions.markAsDirtyIfPristine());
        }
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { component: Component } = this.props;
        const componentProps = omit(this.props, ['component', 'validators', 'shouldValueChange']);

        return (
            <Component
                {...componentProps}
                onBlur={this.handleBlur.bind(this)}
                errorText={this.state.error}
                onChange={this.onChange.bind(this)}
                error={this.state.error !== null && !this.props.pending}
            />
        );
    }

}
