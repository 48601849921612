import React from 'react';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    id?: string;
    className?: string;
    name?: string;
    controls: {
        label: string;
        value: string;
    }[];
    value: string;
    onChange: (value: string) => void;
}

export default function RadioGroup(props: Props) {
    return (
        <MuiRadioGroup
            id={props.id}
            className={props.className}
            name={props.name}
            value={props.value}
            onChange={(event) => {
                props.onChange(event.target.value);
            }}>
            {props.controls.map((control, index) => (
                <FormControlLabel
                    key={index}
                    componentsProps={{
                        typography: {
                            sx: {
                                fontWeight: 300,
                                fontSize: '13px',
                            },
                        },
                    }}
                    value={control.value}
                    label={control.label}
                    control={<Radio color="primary" size="small" />}
                />
            ))}
        </MuiRadioGroup>
    );

}
