import React, { useState } from 'react';
import { Dialog } from '../../../components/Dialog';
import TextField from '../../../components/formFields/TextField';

interface Props {
    open: boolean;
    onClose: (name?: string) => void;
}

export function CreateTemplateDialog(props: Props) {
    const [name, setName] = useState('');

    return (
        <Dialog
            title="Create template from quote"
            size="small"
            open={props.open}
            actions={[
                {
                    children: 'Cancel',
                    onClick: () => {
                        props.onClose();
                    },
                },
                {
                    children: 'Submit',
                    onClick: () => {
                        if (name) {
                            props.onClose(name);
                        }
                    },
                },
            ]}>
            <TextField
                label="Name"
                value={name}
                onChange={(value) => {
                    setName(value);
                }}
            />
        </Dialog>
    );
}
