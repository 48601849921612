import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface Props {
    error?: boolean;
    errorMessage?: string;
}

export function RouteLoader(props: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)',
                color: 'rgba(0, 0, 0, 0.15)',
                fontSize: '26px',
                fontWeight: 400,
            }}>
            {props.error ? (
                <p>{props.errorMessage}</p>
            ) : (
                <CircularProgress color="inherit" />
            )}
        </Box>
    );
}
