import { createReducer } from '@reduxjs/toolkit';
import { SpinnerProps } from '../../components/Spinner/Spinner';
import * as loginActions from './actions';

interface LoginReduxState {
    loginSpinner: SpinnerProps;
}

const initialState = {
    loginSpinner: {},
} as LoginReduxState;

export const login = createReducer(initialState, (builder) => {
    builder.addCase(loginActions.setLoginPending, (state) => {
        state.loginSpinner = { spinning: true };
    });
    builder.addCase(loginActions.setLoginComplete, (state) => {
        state.loginSpinner = {};
    });
    builder.addCase(loginActions.setLoginRejected, (state, action) => {
        state.loginSpinner = {
            error: true,
            errorMessage: action.payload,
        };
    });
});
