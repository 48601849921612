import { sprintf } from 'sprintf-js';

import { errorMessages } from '../constants/errorMessages';
import { SizeFieldValue } from '../components/formFields/SizeField';
import { Validator } from '../services/formService';
import { Interval } from '../services/backendTypes';

export const sizeValidator = (widthRestrictions: Interval, lengthRestrictions: Interval, helpText?: string): Validator => ({
    validate: (value: SizeFieldValue) => {
        const width = Number(value?.width) || 0;
        const length = Number(value?.length) || 0;
        const validWidth = !widthRestrictions || (width >= widthRestrictions.from && width <= widthRestrictions.to);
        const validLength = !lengthRestrictions || (length >= lengthRestrictions.from && length <= lengthRestrictions.to);

        if (validWidth && validLength) {
            return null;
        }

        return {
            errorMessage: sprintf(
                errorMessages.size,
                widthRestrictions?.from / 10,
                widthRestrictions?.to / 10,
                lengthRestrictions?.from / 10,
                lengthRestrictions?.to / 10,
            ) + (helpText ? ` (${helpText})` : ''),
        };
    },
});
