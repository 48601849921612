import React from 'react';
import mapValues from 'lodash/mapValues';
import { sprintf } from 'sprintf-js';

import { SummaryTable, SummaryTableDataItem } from './SummaryTable';
import * as authActions from '../../../../layouts/Auth/actions';
import { store } from '../../../../store';
import { BED_PROPERTIES } from './bedProperties';
import { OrderFormBedItem } from '../../../../containers/reducers';
import { BedOption, BedPropertyChoiceData, BedPropertyConfig } from '../../../../services/backendTypes';
import { AuthBedProperties } from '../../reducer';

interface Props {
    data: OrderFormBedItem[];
    beds: BedOption[];
    bedProperties: AuthBedProperties;
}

export class QuoteBedTable extends React.Component<Props> {

    componentDidMount() {
        this.props.data.forEach((item) => {
            if (item.type && !this.props.bedProperties[item.type]) {
                store.dispatch<any>(authActions.loadBedProperties([item.type]));
            }
        });
    }

    getDisplayName(value, property: BedPropertyConfig): string {
        switch (property.dataType) {
            case 'choice':
                const choiceData = property.data as BedPropertyChoiceData;
                const selectedOption = choiceData.options.find((option) => option.code === value);
                return selectedOption ? selectedOption.displayName : value;
            case 'measurement':
                const cm = String(value / 10).replace('.', ',');
                return value ? sprintf('%s cm', cm) : value;
            default:
                return value.toString();
        }
    }

    prepareItem(item: OrderFormBedItem): SummaryTableDataItem {
        const selectedBed = this.props.beds.find((bed) => {
            return bed.code === item.type;
        });
        const bedProperties = this.props.bedProperties[item.type]?.data;
        if (selectedBed && bedProperties) {
            return mapValues(item, (value, key) => {
                if (key === 'type') {
                    return selectedBed.displayName;
                }
                const selectedBedProperty = bedProperties.find((property) => property.code === key);
                if (selectedBedProperty) {
                    return this.getDisplayName(value, selectedBedProperty);
                } else {
                    return value?.toString();
                }
            });
        }
        return {};
    }

    render() {
        return (
            <SummaryTable
                fields={BED_PROPERTIES}
                data={this.props.data.map((item) => this.prepareItem(item))}
            />
        );
    }

}
