import React, { Component } from 'react';

import { formatPrice } from '../../functions/formatPrice';
import { LegacyQuote, LegacyQuoteAccessory, LegacyQuoteBed } from '../../services/legacyBackendTypes';

interface Props {
    data: LegacyQuote;
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
}

interface TotalValue {
    price: number;
    currency: string;
}

export class TotalOrderValue extends Component<Props> {

    getBedTotal(priceFunc: (data: LegacyQuoteBed) => number, currencyFunc: (data: LegacyQuoteBed) => string): TotalValue {
        return this.props.data.beds.reduce(
            (accumulator: TotalValue, currentValue): TotalValue => {
                if (currentValue.valid === false) {
                    return accumulator;
                }
                return {
                    price: accumulator.price + priceFunc(currentValue),
                    currency: accumulator.currency || currencyFunc(currentValue),
                };
            },
            {
                price: 0,
                currency: '',
            },
        );
    }

    getAccessoryTotal(priceFunc: (data: LegacyQuoteAccessory) => number, currencyFunc: (data: LegacyQuoteAccessory) => string): TotalValue {
        const accessories: LegacyQuoteAccessory[] = [].concat(
            ...Object.keys(this.props.data.accessories).map((key) => this.props.data.accessories[key]),
        );

        return accessories.reduce(
            (accumulator: TotalValue, currentValue): TotalValue => {
                return {
                    price: accumulator.price + priceFunc(currentValue),
                    currency: accumulator.currency || currencyFunc(currentValue),
                };
            },
            {
                price: 0,
                currency: '',
            },
        );
    }

    getTotalEndConsumerPrice(): TotalValue {
        const priceFunc = (data) => {
            const price = parseFloat(data.endConsumerPrice) || 0;
            const discount = parseFloat(data.endConsumerDiscount) || 0;
            return price * ((100 - discount) / 100);
        };
        const currencyFunc = (data) => data.quotationCurrency;

        const bedTotal = this.getBedTotal(priceFunc, currencyFunc);
        const accessoryTotal = this.getAccessoryTotal(priceFunc, currencyFunc);

        return {
            price: bedTotal.price + accessoryTotal.price,
            currency: bedTotal.currency || accessoryTotal.currency,
        };
    }

    render(): JSX.Element {
        const totalWholesalePrice = this.props.data.wholeSalePrice;
        const totalFullPrice = this.props.data.totalPrice;
        const totalEndConsumerPrice = this.getTotalEndConsumerPrice();
        const quoteCurrency = this.props.data.currency;

        return (
            <div className="c--total-order-value">
                {this.props.hideWholesalePrices === false && (
                    <div>
                        <span className="label">Wholesale price:</span>
                        <span>{totalWholesalePrice ? formatPrice(totalWholesalePrice, quoteCurrency) : '---'}</span>
                    </div>
                )}
                {this.props.hideWholesalePrices === false && (
                    <div>
                        <span className="label">Total value:</span>
                        <span>{totalFullPrice ? formatPrice(totalFullPrice, quoteCurrency) : '---'}</span>
                    </div>
                )}
                {this.props.hideEndConsumerPrices === false && (
                    <div>
                        <span className="label">Price:</span>
                        <span>{totalEndConsumerPrice.price ? formatPrice(totalEndConsumerPrice.price, totalEndConsumerPrice.currency) : '---'}</span>
                    </div>
                )}
            </div>
        );
    }

}


