import { createReducer } from '@reduxjs/toolkit';
import * as createOrderDialogActions from './actions';

interface CreateOrderDialogReduxProps {
    visible: boolean;
}

const initialState = {
    visible: false,
} as CreateOrderDialogReduxProps;

export const createOrderDialog = createReducer(initialState, (builder) => {
    builder.addCase(createOrderDialogActions.openCreateOrderDialog, (state) => {
        state.visible = true;
    });
    builder.addCase(createOrderDialogActions.closeCreateOrderDialog, (state) => {
        state.visible = false;
    });
});
