import React, { ChangeEvent, Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import format from 'date-fns/format';

import * as orderListActions from './actions';
import TextField from '../../components/formFields/TextField';
import Button from '../../components/formFields/Button';
import FadeIn from '../../components/animations/FadeIn';
import { OrderDetails } from './OrderDetails';
import MenuButton from '../../components/formFields/MenuButton';
import { AppDispatch, ReduxState } from '../../store';
import { AuthCustomer, FilterParameters, SortDirection, PagedQuote, Quote } from '../../services/backendTypes';
import { convertLegacyQuote } from '../../functions/convertLegacyQuote';
import { backendService } from '../../services/backendService';

type OrderListProps = ReduxProps & RouteComponentProps & WithHooksProps;
type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };
interface WithHooksProps { isMobile: boolean; }

interface State {
    searchString: string;
    filter: FilterParameters;
}

const defaultFilter = {
    search: null,
    sort: 'createdTime',
    direction: 'desc' as SortDirection,
    page: 1,
    count: 20,
};

const mapStateToProps = (store: ReduxState) => ({
    orderList: store.orderList,
    auth: store.auth,
});

class OrderList extends React.Component<OrderListProps> {

    state = {
        searchString: '',
        filter: cloneDeep(defaultFilter),
    } as State;

    getMoreMenu(item: PagedQuote) {
        return [
            {
                primaryText: 'Preview details',
                onClick: () => {
                    this.props.dispatch(orderListActions.openOrderSummary(item.id));
                },
            },
            {
                primaryText: 'Delivery confirmation',
                notAvailable: item.version === 'V1',
                onClick: () => {
                    orderListActions.openDeliveryConfirmation(item);
                },
            },
            {
                primaryText: 'Customer quotation',
                notAvailable: item.version === 'V1',
                onClick: () => {
                    orderListActions.openCustomerQuotation(item, this.props.auth.hideWholesalePrices);
                },
            },
            {
                primaryText: 'Make copy',
                notAvailable: item.version === 'V1',
                onClick: () => {
                    this.props.dispatch(orderListActions.setMoreMenuPending(item.id));
                    backendService.getOrder(item.id).then((response) => {
                        this.props.dispatch(orderListActions.setMoreMenuComplete(item.id));
                        this.props.history.push(
                            `/auth/${this.props.auth.currentCustomer.key}/new-quote`,
                            { formData: pick<Quote, keyof Quote>(convertLegacyQuote(response.quote), ['items']) },
                        );
                    });
                },
            },
        ];
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (params.has('search')) {
            this.setState({ searchString: params.get('search') });
            this.populateTable(this.props.auth.currentCustomer, { search: params.get('search') });
        } else if (params.has('id')) {
            this.populateTable(this.props.auth.currentCustomer, null, parseInt(params.get('id'), 10));
        } else {
            this.populateTable(this.props.auth.currentCustomer);
        }
    }

    componentDidUpdate(prevProps: OrderListProps) {
        const oldParams = new URLSearchParams(prevProps.location.search);
        const newParams = new URLSearchParams(this.props.location.search);

        if (prevProps.auth.currentCustomer.key !== this.props.auth.currentCustomer.key) {
            this.populateTable(this.props.auth.currentCustomer, cloneDeep(defaultFilter));
        }

        if (!isEqual(newParams.get('id'), oldParams.get('id'))) {
            const id = newParams.has('id') ? parseInt(newParams.get('id'), 10) : null;
            this.populateTable(this.props.auth.currentCustomer, cloneDeep(defaultFilter), id);
        }
    }

    populateTable(currentCustomer: AuthCustomer, params?: Partial<FilterParameters>, rowToExpand?: number) {
        this.props.dispatch(orderListActions.loadOrders(
            currentCustomer.key,
            { ...this.state.filter, ...params },
            rowToExpand,
        ));
        this.setFilterParameters(params);
    }

    handleSearch() {
        this.populateTable(this.props.auth.currentCustomer, {
            search: this.state.searchString,
            page: 1,
        });
    }

    handleChangePage(event: Event, newPage: number) {
        this.populateTable(this.props.auth.currentCustomer, {
            page: newPage + 1,
            count: this.state.filter.count,
        });
    }

    handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
        this.populateTable(this.props.auth.currentCustomer, {
            page: 1,
            count: parseInt(event.target.value),
        });
    }

    handleSort(column: string) {
        const isActiveColumn = this.state.filter.sort === column;
        const oppositeDirection = this.state.filter.direction === 'asc' ? 'desc' : 'asc';
        const direction = isActiveColumn ? oppositeDirection : 'desc';
        this.populateTable(this.props.auth.currentCustomer, {
            sort: column,
            direction: direction,
        });
    }

    setFilterParameters(params: Partial<FilterParameters>) {
        this.setState({
            filter: {
                ...this.state.filter,
                ...params,
            },
        });
    }

    isActiveSort(column: string): boolean {
        return this.state.filter.sort === column;
    }

    getSortDirection(column: string): SortDirection {
        if (this.state.filter.sort === column) {
            return this.state.filter.direction;
        } else {
            return 'desc';
        }
    }

    toggleRow(id: number) {
        if (this.props.orderList.tableRowIsExpanded[id]) {
            this.props.dispatch(orderListActions.collapseTableRow(id));
        } else {
            this.props.dispatch(orderListActions.loadOrderAndExpandTableRow(id));
        }
    }

    render() {
        return (
            <div className="c--order-list">
                <Paper elevation={2} className="search-block">
                    <div className="search-field-wrapper">
                        <TextField
                            placeholder="Search order"
                            value={this.state.searchString}
                            onChange={(value) => {
                                this.setState({ searchString: value });
                            }}
                            onKeyPress={(event) => event.key === 'Enter' && this.handleSearch()}
                        />
                        <div>
                            <Button
                                icon="search"
                                onClick={() => this.handleSearch()}
                            />
                        </div>
                    </div>
                </Paper>
                <div className="container">
                    <Paper elevation={2} className="table-block">
                        {this.props.orderList.loadDataPending && (
                            <LinearProgress id="table-progress" className="table-progress" />
                        )}
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {this.props.isMobile ? (
                                            <TableCell>
                                                <span className="primary">Order number</span>
                                                <span className="secondary">PO number/name</span>
                                            </TableCell>
                                        ) : (
                                            <>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={this.isActiveSort('orderNumber')}
                                                        direction={this.getSortDirection('orderNumber')}
                                                        onClick={() => this.handleSort('orderNumber')}>
                                                        Order number
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={this.isActiveSort('purchaseOrderNumber')}
                                                        direction={this.getSortDirection('purchaseOrderNumber')}
                                                        onClick={() => this.handleSort('purchaseOrderNumber')}>
                                                        PO number/name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={this.isActiveSort('referenceNumber')}
                                                        direction={this.getSortDirection('referenceNumber')}
                                                        onClick={() => this.handleSort('referenceNumber')}>
                                                        Reference number
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={this.isActiveSort('createdBy')}
                                                        direction={this.getSortDirection('createdBy')}
                                                        onClick={() => this.handleSort('createdBy')}>
                                                        Created by
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={this.isActiveSort('createdTime')}
                                                        direction={this.getSortDirection('createdTime')}
                                                        onClick={() => this.handleSort('createdTime')}>
                                                        Order date
                                                    </TableSortLabel>
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.orderList.data.list.map((item) => (
                                        <Fragment key={item.id}>
                                            <TableRow>
                                                {this.props.isMobile ? (
                                                    <TableCell className="letter-spacing">
                                                        <span className="primary">{item.orderNumber}</span>
                                                        <span className="secondary">{item.purchaseOrderNumber}</span>
                                                    </TableCell>
                                                ) : (
                                                    <>
                                                        <TableCell className="letter-spacing">{item.orderNumber}</TableCell>
                                                        <TableCell className="letter-spacing">{item.purchaseOrderNumber}</TableCell>
                                                        <TableCell>{item.referenceNumber}</TableCell>
                                                        <TableCell>{item.createdBy}</TableCell>
                                                        <TableCell>{format(new Date(item.createdTime), 'yyyy-MM-dd')}</TableCell>
                                                    </>
                                                )}
                                                <TableCell>
                                                    <Button
                                                        icon={this.props.orderList.tableRowIsExpanded[item.id] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                        onClick={() => this.toggleRow(item.id)}
                                                        spinner={this.props.orderList.loadFullDataSpinner[item.id]}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <MenuButton
                                                        buttonProps={{
                                                            icon: 'menu',
                                                            spinner: this.props.orderList.moreMenuSpinner[item.id],
                                                        }}
                                                        menuItems={this.getMoreMenu(item)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="expandable-row">
                                                <TableCell colSpan={this.props.isMobile ? 3 : 7}>
                                                    <FadeIn key={item.id} className="fade-in" in={this.props.orderList.tableRowIsExpanded[item.id]}>
                                                        <OrderDetails
                                                            data={this.props.orderList.fullData[item.id]}
                                                            hideWholesalePrices={this.props.auth.hideWholesalePrices}
                                                            hideEndConsumerPrices={this.props.auth.hideEndConsumerPrices}
                                                        />
                                                    </FadeIn>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            classes={{ selectLabel: 'pagination-text', displayedRows: 'pagination-text' }}
                            component="div"
                            labelRowsPerPage={this.props.isMobile ? 'Per page' : 'Items per page'}
                            rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                            count={this.props.orderList.data.total}
                            page={this.state.filter.page - 1}
                            rowsPerPage={this.state.filter.count}
                            onPageChange={this.handleChangePage.bind(this)}
                            onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
                        />
                    </Paper>
                </div>
            </div>
        );
    }

}

const withHooks = () => (Component) => {
    return function WrappedComponent(props) {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

        return <Component {...props} isMobile={isMobile} />;
    };
};

const connector = connect(mapStateToProps);
export default connector(withHooks()(OrderList));

