/*
 * CSS entry point
 */

require('./sass/index.scss');

/*
 * Polyfills
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

/*
 * Render our top level component
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/en-GB';

import App from './App';
import { store } from './store';
import { lightTheme } from './muiTheme';
import { SentryService } from './services/sentryService';

SentryService.initiate();

const root = createRoot(window.document.getElementById('partner-order'));

root.render(
    <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <BrowserRouter>
                <ThemeProvider theme={lightTheme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </LocalizationProvider>
    </ReduxProvider>,
);
