import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import uniqueId from 'lodash/uniqueId';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import * as Sentry from '@sentry/react';

import { sessionStorageService } from '../services/sessionStorageService';
import { removeUnderscoreProperties } from '../functions/removeUnderscoreProperties';
import {
    AccessoryCategory,
    AuthConfig,
    BedOption,
    BedPropertiesResponse,
    Contact,
    Country,
    DeleteQuoteResponse,
    DeliveryAddress,
    ListQuote,
    LoginRequestData,
    Notification,
    PagedResponse,
    Quote,
    VerifyCampaignCodeRequestData,
    VerifyIdpCodeRequestData,
    VerifyCampaignCodeResponse,
    VerifyIdpCodeResponse,
    FilterParameters,
    QuoteBuilderResponse,
    QuoteBuilderRequestData,
    QuoteRequestData,
    OrderRequestData,
    Template,
    TemplateRequestData,
    PagedTemplate, PagedQuote,
} from './backendTypes';
import { LegacyQuote } from './legacyBackendTypes';

interface ErrorHandlerConfig {
    request?: () => Promise<AxiosResponse>;
    disableErrorHandlers?: boolean;
}

type ErrorHandler = (error: AxiosError<ErrorData>) => Promise<boolean>;

export interface ErrorData {
    code: string;
    message: string;
    canRetry: boolean;
}

class BackendService {

    private backendUrl: string = window['backendUrl'];
    private retryCounter: { [url: string]: number } = {};
    private errorHandlers: { [id: string]: ErrorHandler } = {};

    constructor() {
        axios.interceptors.request.use(function(config) {
            if (!config?.url.includes('login')) {
                Sentry.addBreadcrumb({
                    category: 'axios',
                    message: `${config.method} ${config.url} (pre-request)`,
                    data: {
                        ...config,
                        headers: {
                            ...(config.headers || {}),
                            authorization: undefined,
                        },
                        adapter: undefined,
                        validateStatus: undefined,
                        transformResponse: undefined,
                        transformRequest: undefined,
                        xsrfCookieName: undefined,
                        xsrfHeaderName: undefined,
                    },
                    level: 'info',
                });
            }

            return config;
        });
    }

    public get<T>(uri: string, params?): Promise<AxiosResponse<T>> {
        return axios
            .get(this.backendUrl + uri, this.getRequestConfig(params))
            .then(this.handleSuccess.bind(this))
            .catch((error: AxiosError<{ error: ErrorData }>) => {
                return this.handleError(error, { request: () => this.get(uri, params) });
            });
    }

    public post<T>(uri: string, data?, config?: ErrorHandlerConfig): Promise<AxiosResponse<T>> {
        return axios
            .post(this.backendUrl + uri, removeUnderscoreProperties(data), this.getRequestConfig())
            .then(this.handleSuccess.bind(this))
            .catch((error: AxiosError<{ error: ErrorData }>) => {
                return this.handleError(error, config);
            });
    }

    public urlTo(uri: string): string {
        if (!uri) {
            return null;
        }
        return this.backendUrl + uri;
    }

    public registerErrorHandler(errorHandler: ErrorHandler): string {
        const id = uniqueId();
        this.errorHandlers[id] = errorHandler;
        return id;
    }

    public unregisterErrorHandler(id: string) {
        if (this.errorHandlers[id]) {
            delete this.errorHandlers[id];
        }
    }

    private getRequestConfig(params?): AxiosRequestConfig {
        const sessionToken = sessionStorageService.getToken();
        const config: AxiosRequestConfig = {
            withCredentials: true,
        };
        if (sessionToken) {
            config.headers = config.headers || {};
            config.headers.authorization = sessionToken;
        }
        if (params) {
            config.params = params;
        }
        return config;
    }

    private handleSuccess(response: AxiosResponse): AxiosResponse {
        if (this.isLoggedIn()) {
            sessionStorageService.setToken(response.headers.authorization);
        }
        return response;
    }

    private handleError(error: AxiosError<{ error: ErrorData }>, config: ErrorHandlerConfig = {}): Promise<AxiosResponse> {
        if (!axios.isAxiosError(error)) {
            return Promise.reject(error);
        }

        const errorHandlers = cloneDeep(this.errorHandlers);
        const status = error.response?.status;
        const code = error.response?.data?.error?.code;
        const message = (() => {
            if (!status) {
                return 'Unable to contact the server. Check your internet connection or try again later.';
            } else if (status === 500) {
                return 'There was an error when contacting the server. Please try again later.';
            } else {
                return error.response?.data?.error?.message;
            }
        })();

        set(error, 'response.data', {
            message,
            code,
            canRetry: !!config.request,
        });

        // Retry 2 times if there is a connection error
        if (!status && config.request) {
            if (this.retryCounter[error.config.url] === undefined) {
                this.retryCounter[error.config.url] = 0;
            }
            if (this.retryCounter[error.config.url] < 2) {
                this.retryCounter[error.config.url]++;
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        config.request()
                            .then((nextResponse) => resolve(nextResponse))
                            .catch((nextError) => reject(nextError));
                    }, 2000);
                });
            }
            this.retryCounter[error.config.url] = 0;
        }

        // Allow registered error handlers to intercept errors
        if (config.disableErrorHandlers !== true) {
            for (const id of Object.keys(errorHandlers)) {
                const errorHandlerResult = errorHandlers[id](error as any);
                if (errorHandlerResult) {
                    return errorHandlerResult.then((retry) => {
                        if (retry === true && config.request) {
                            return config.request();
                        }
                        return Promise.reject(error);
                    });
                }
            }
        }

        return Promise.reject(error);
    }

    public getAuthConfig() {
        return this.get<AuthConfig>('en/partner-order/api/auth/config').then((response) => {
            response.data.customers = sortBy(response.data.customers, 'name1');
            return response.data;
        });
    }

    public getVersion() {
        return axios.get<{ version: number; }>('/version.json').then((response) => response.data);
    }

    public isLoggedIn(): boolean {
        return sessionStorageService.hasToken();
    }

    public login(data: LoginRequestData) {
        return this.post<AuthConfig>('en/partner-order/api/auth/login', data, { disableErrorHandlers: true }).then((response) => {
            sessionStorageService.setToken(response.headers.authorization);
            response.data.customers = sortBy(response.data.customers, 'name1');
            return response.data;
        });
    }

    public logout() {
        sessionStorageService.removeToken();
    }

    public getBeds(customerNo: string) {
        return this.get<{ data: BedOption[]; }>('en/partner-order/api/form/bed/list', { customerNumber: customerNo }).then((response) => response.data);
    }

    public getBedProperties(bedCode: string, data: { customerNumber: string }) {
        return this.post<BedPropertiesResponse>('en/partner-order/api/form/bed/rules/' + bedCode, data).then((response) => response.data);
    }

    public getMaterials(data: QuoteBuilderRequestData) {
        return this.post<QuoteBuilderResponse>('en/partner-order/api-v2/quote-builder/items', data).then((response) => response.data);
    }

    public getQuotes(customerNo: string) {
        return this.get<{ quotes: ListQuote[]; }>('en/partner-order/api/form/quote/' + customerNo + '/list').then((response) => response.data);
    }

    public getQuote(id: number) {
        return this.get<Quote>('en/partner-order/api/form/quote/' + id).then((response) => response.data);
    }

    public saveQuote(data: QuoteRequestData) {
        return this.post<Quote>('en/partner-order/api-v2/form/quote', data).then((response) => response.data);
    }

    public deleteQuote(id: number) {
        return this.post<DeleteQuoteResponse>('en/partner-order/api/form/quote/delete/' + id).then((response) => response.data);
    }

    public getOrdersByPage(customerNo: string, params: FilterParameters) {
        return this.get<PagedResponse<PagedQuote>>('en/partner-order/api/form/order/' + customerNo + '/table', params).then((response) => response.data);
    }

    public getQuotesByPage(customerNo: string, params: FilterParameters) {
        return this.get<PagedResponse<PagedQuote>>('en/partner-order/api/form/quote/' + customerNo + '/table', params).then((response) => response.data);
    }

    public getOrders(customerNo: string) {
        return this.get<{ quotes: ListQuote[]; }>('en/partner-order/api/form/order/' + customerNo + '/list').then((response) => response.data);
    }

    public getOrder(id: number) {
        return this.get<{ quote: LegacyQuote; }>('en/partner-order/api/form/order/' + id).then((response) => response.data);
    }

    public createOrder(id: number, data: OrderRequestData) {
        return this.post<Quote>('en/partner-order/api-v2/form/quote/order/' + id, data).then((response) => response.data);
    }

    public getAccessories(customerNumber: string) {
        return this.get<AccessoryCategory[]>('en/partner-order/api/form/accessories-config/list', { customerNumber }).then((response) => response.data);
    }

    public verifyCampaignCode(data: VerifyCampaignCodeRequestData) {
        return this.post<VerifyCampaignCodeResponse>('en/partner-order/api/form/campaigns/verify-code', data).then((response) => response.data);
    }

    public getCountries() {
        return this.get<Country[]>('en/partner-order/api/countries/list').then((response) => response.data);
    }

    public getContact(email: string) {
        return this.post<Contact>('en/partner-order/api/form/end-consumer/contact', { email }).then((response) => response.data);
    }

    public getActiveNotifications() {
        return this.post<Notification[]>('en/partner-order/api/notifications/get-latest').then((response) => response.data);
    }

    public verifyIdpCode(data: VerifyIdpCodeRequestData) {
        return this.post<VerifyIdpCodeResponse>('en/partner-order/api/customer/idp/check', data).then((response) => response.data);
    }

    public getDeliveryAddresses(customerNo: string) {
        return this.get<{ deliveryAddresses: DeliveryAddress[] }>(`en/partner-order/api/form/customer/${customerNo}/delivery-addresses`).then((response) => response.data);
    }

    public getTemplatesByPage(customerNo: string, params: FilterParameters) {
        return this.get<PagedResponse<PagedTemplate>>('en/partner-order/api-v2/form/' + customerNo + '/template/table', params).then((response) => response.data);
    }

    public createTemplate(customerNo: string, data: TemplateRequestData) {
        return this.post<{ quotes: ListQuote[]; }>('en/partner-order/api-v2/form/' + customerNo + '/template/save', data).then((response) => response.data);
    }

    public deleteTemplate(id: number) {
        return this.get('en/partner-order/api-v2/form/template/' + id + '/delete').then((response) => response.data);
    }

    public getTemplate(id: number) {
        return this.get<Template>('en/partner-order/api-v2/form/template/' + id).then((response) => response.data);
    }
}

export const backendService = new BackendService();
