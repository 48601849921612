import { createReducer } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

import { localStorageService } from './../../services/localStorageService';
import { getUniqueId } from '../../functions/getUniqueId';
import { addKeys } from '../../functions/addKeys';
import { Notification, Quote, QuoteAccessoryItem, QuoteBedItem, QuoteBuilderResponseItem } from '../../services/backendTypes';
import { SpinnerProps } from '../../components/Spinner/Spinner';
import * as orderFormActions from './actions';

export interface OrderFormReduxState {
    originalData: Quote;
    data: OrderFormData;
    formIsReady: boolean;
    formIsUnavailable: boolean;
    createTemplateDialogIsOpen: boolean;
    notifications: Notification[];
    readNotifications: number[];
    saveSpinner: SpinnerProps;
    createOrderSpinner: SpinnerProps;
    createTemplateSpinner: SpinnerProps;
    quoteBuilderResponseSpinner: {
        [key: string]: SpinnerProps;
    };
    /**
     * quoteBuilderResponse is used for debug purposes. This property stores the full material data.
     */
    quoteBuilderResponse: {
        [key: string]: QuoteBuilderResponseItem;
    };
    /**
     * simplifiedQuoteBuilderResponse is a simplified version of quoteBuilderResponse. This property stores a summarization of the material data.
     */
    simplifiedQuoteBuilderResponse: {
        [key: string]: SimplifiedQuoteBuilderResponseItem;
    };
}

export interface OrderFormData extends Quote {
    items: OrderFormItem[];
}

export type OrderFormItem = OrderFormBedItem | OrderFormAccessoryItem;

export type OrderFormBedItem = QuoteBedItem & CardProps;
export type OrderFormAccessoryItem = QuoteAccessoryItem & CardProps;

/**
 * Properties starting with "_" are removed before sending the data to the backend
 */
interface CardProps {
    _key: string;
    _new: boolean;
}

export interface SimplifiedQuoteBuilderResponseItem {
    discountedPrice: number;
    price: number;
    recommendedPrice: number;
    colorSurchargeListPrice: number;
    legsIncludedWorth: number;
    currency: string;
    quotationCurrency: string;
    valid: boolean;
    blockStatus?: number; // TODO
    appliedCampaigns: string[];
}

const initialState = {
    originalData: {
        items: [],
    },
    data: {
        items: [],
        orderType: { serviceType: 'standardOrder' },
    },
    saveSpinner: {},
    createOrderSpinner: {},
    createTemplateSpinner: {},
    notifications: [],
    readNotifications: [],
    formIsReady: false,
    formIsUnavailable: false,
    createTemplateDialogIsOpen: false,
    quoteBuilderResponse: {},
    simplifiedQuoteBuilderResponse: {},
    quoteBuilderResponseSpinner: {},
} as OrderFormReduxState;

export const orderForm = createReducer(initialState, (builder) => {
    builder.addCase(orderFormActions.setLoadQuoteComplete, (state, action) => {
        state.quoteBuilderResponse = {};
        state.simplifiedQuoteBuilderResponse = {};
        state.originalData = action.payload.originalQuote;
        state.data = action.payload.quote;
    });
    builder.addCase(orderFormActions.setFormIsReady, (state) => {
        state.formIsReady = true;
    });
    builder.addCase(orderFormActions.setFormIsUnavailable, (state) => {
        state.formIsUnavailable = true;
    });
    builder.addCase(orderFormActions.resetOrderForm, (state, action) => {
        Object.keys(omit(initialState, ['notifications', 'readNotifications'])).forEach((key) => {
            state[key] = initialState[key];
        });
        if (action.payload) {
            state.data = {
                ...state.data,
                ...addKeys(action.payload),
            };
        }
    });
    builder.addCase(orderFormActions.updateOrderFormContent, (state, action) => {
        merge(state.data, action.payload);
    });
    builder.addCase(orderFormActions.updateOrderFormBeds, (state, action) => {
        for (const payloadItem of action.payload) {
            const targetItem = state.data.items.find((item) => item._key === payloadItem.key) as OrderFormBedItem;
            if (targetItem) {
                for (const key of Object.keys(payloadItem.values)) {
                    targetItem[key] = payloadItem.values[key];
                }
                for (const key of Object.keys(targetItem)) {
                    if (targetItem[key] === '') {
                        delete targetItem[key];
                    }
                }
            }
        }
    });
    builder.addCase(orderFormActions.updateOrderFormAccessories, (state, action) => {
        for (const payloadItem of action.payload) {
            const targetItem = state.data.items.find((item) => item._key === payloadItem.key) as OrderFormAccessoryItem;
            if (targetItem) {
                merge(targetItem, payloadItem.values);
                for (const key of Object.keys(targetItem.parameters)) {
                    if (targetItem.parameters[key] === '') {
                        delete targetItem.parameters[key];
                    }
                }
            }
        }
    });
    builder.addCase(orderFormActions.addOrderFormBed, (state) => {
        state.data.items.push({
            itemType: 'bed',
            quantity: 1,
            _key: getUniqueId(),
            _new: true,
        } as OrderFormBedItem);
    });
    builder.addCase(orderFormActions.addOrderFormAccessory, (state, action) => {
        state.data.items.push({
            itemType: 'accessory',
            level1: action.payload,
            parameters: {},
            quantity: 1,
            _key: getUniqueId(),
            _new: true,
        } as OrderFormAccessoryItem);
    });
    builder.addCase(orderFormActions.duplicateOrderFormBed, (state, action) => {
        const index = state.data.items.findIndex((item) => item._key === action.payload);
        const newKey = getUniqueId();
        state.data.items = [
            ...state.data.items.slice(0, index + 1),
            {
                ...state.data.items[index],
                _key: newKey,
                _new: true,
            },
            ...state.data.items.slice(index + 1),
        ];
        state.quoteBuilderResponse[newKey] = cloneDeep(state.quoteBuilderResponse[action.payload]);
        state.simplifiedQuoteBuilderResponse[newKey] = cloneDeep(state.simplifiedQuoteBuilderResponse[action.payload]);
    });
    builder.addCase(orderFormActions.removeOrderFormItem, (state, action) => {
        const index = state.data.items.findIndex((item) => item._key === action.payload);
        if (index !== -1) {
            state.data.items.splice(index, 1);
        }
        if (state.quoteBuilderResponse[action.payload]) {
            delete state.quoteBuilderResponse[action.payload];
        }
        if (state.simplifiedQuoteBuilderResponse[action.payload]) {
            delete state.simplifiedQuoteBuilderResponse[action.payload];
        }
    });
    builder.addCase(orderFormActions.setSaveQuotePending, (state) => {
        state.saveSpinner = {
            spinning: true,
        };
    });
    builder.addCase(orderFormActions.setSaveQuoteComplete, (state) => {
        state.saveSpinner = {};
    });
    builder.addCase(orderFormActions.setSaveQuoteRejected, (state, action) => {
        state.saveSpinner = {
            error: true,
            errorMessage: action.payload,
        };
    });
    builder.addCase(orderFormActions.setCreateOrderPending, (state) => {
        state.createOrderSpinner = {
            spinning: true,
        };
    });
    builder.addCase(orderFormActions.setCreateOrderComplete, (state) => {
        state.createOrderSpinner = {};
    });
    builder.addCase(orderFormActions.setCreateOrderRejected, (state, action) => {
        state.createOrderSpinner = {
            error: true,
            errorMessage: action.payload,
        };
    });
    builder.addCase(orderFormActions.setMaterialsPending, (state, action) => {
        for (const payloadItem of action.payload) {
            state.quoteBuilderResponseSpinner[payloadItem.key] = { spinning: true };
        }
    });
    builder.addCase(orderFormActions.setMaterialsComplete, (state, action) => {
        for (const payloadItem of action.payload) {
            state.quoteBuilderResponse[payloadItem.key] = payloadItem.data;
            state.simplifiedQuoteBuilderResponse[payloadItem.key] = payloadItem.simplifiedData;
            state.quoteBuilderResponseSpinner[payloadItem.key] = null;
        }
    });
    builder.addCase(orderFormActions.setMaterialsRejected, (state, action) => {
        for (const payloadItem of action.payload) {
            state.quoteBuilderResponseSpinner[payloadItem.key] = { error: true, errorMessage: payloadItem.errorMessage };
        }
    });
    builder.addCase(orderFormActions.removeMaterials, (state, action) => {
        if (state.quoteBuilderResponse[action.payload]) {
            delete state.quoteBuilderResponse[action.payload];
        }
        if (state.simplifiedQuoteBuilderResponse[action.payload]) {
            delete state.simplifiedQuoteBuilderResponse[action.payload];
        }
    });
    builder.addCase(orderFormActions.resetBedPrices, (state, action) => {
        const index = state.data.items.findIndex((bed) => bed._key === action.payload);
        if (index !== -1) {
            delete state.data.items[index].endConsumerPrice;
        }
        if (state.quoteBuilderResponse[action.payload]) {
            delete state.quoteBuilderResponse[action.payload];
        }
        if (state.simplifiedQuoteBuilderResponse[action.payload]) {
            delete state.simplifiedQuoteBuilderResponse[action.payload];
        }
    });
    builder.addCase(orderFormActions.setNotificationsComplete, (state, action) => {
        if (action.payload.length === 0) {
            localStorageService.emptyNotifications();
        }
        state.readNotifications = localStorageService.getItem('notifications') || [];
        state.notifications = action.payload;
    });
    builder.addCase(orderFormActions.notificationsRead, (state, action) => {
        localStorageService.notificationsRead(action.payload);
        state.notifications = action.payload;
    });
    builder.addCase(orderFormActions.setCreateTemplateDialogOpen, (state) => {
        state.createTemplateDialogIsOpen = true;
    });
    builder.addCase(orderFormActions.setCreateTemplateDialogClosed, (state) => {
        state.createTemplateDialogIsOpen = false;
    });
    builder.addCase(orderFormActions.setCreateTemplatePending, (state) => {
        state.createTemplateSpinner = {
            spinning: true,
        };
    });
    builder.addCase(orderFormActions.setCreateTemplateComplete, (state) => {
        state.createTemplateSpinner = {};
    });
    builder.addCase(orderFormActions.setCreateTemplateRejected, (state, action) => {
        state.createTemplateSpinner = {
            error: true,
            errorMessage: action.payload,
        };
    });
});
