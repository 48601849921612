import React, { Fragment, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import { FormField } from '../../components/formFields/FormField';
import TextField, { TextFieldProps } from '../../components/formFields/TextField';
import Button from '../../components/formFields/Button';
import CampaignCodeField, { CampaignCodeFieldProps } from './CampaignCodeField/CampaignCodeField';
import { latinOneValidator } from '../../validators/latinOneValidator';
import { requiredValidator } from '../../validators/requiredValidator';
import { trimValidator } from '../../validators/trimValidator';
import { getCountries, openCustomerDialog } from './dialogs/CustomerDialog/actions';
import { darkTheme } from '../../muiTheme';
import { DatePicker, DatePickerProps } from '../../components/formFields/DatePicker';
import { OrderFormProps } from './OrderForm';
import { backendService } from '../../services/backendService';
import { orderReasons, QuoteContact, serviceTypes } from '../../services/backendTypes';
import { OrderFormData } from './reducer';
import { maxCharactersValidator } from '../../validators/maxCharactersValidator';

interface Props extends OrderFormProps {
    onChange: (values: Partial<OrderFormData>) => void;
}

export function OrderFormHeader({ orderForm, dispatch, countries, onChange, auth }: Props) {
    const [createdBy, setCreatedBy] = useState('');

    useEffect(() => {
        const createdByFromStore = orderForm.data.createdBy || null;
        if (createdByFromStore) {
            setCreatedBy(getNameOrEmail(createdByFromStore));
        }
    }, [orderForm]);

    const showCustomerDialog = () => {
        if (countries.length <= 0) {
            dispatch(getCountries());
        }
        dispatch(openCustomerDialog());
    };

    const getNameOrEmail = (createdByData: { contact: QuoteContact }): string => {
        if (createdByData?.contact?.firstname && createdByData?.contact?.lastname) {
            return `${createdByData.contact.firstname} ${createdByData.contact.lastname}`;
        } else {
            return createdByData?.contact?.primaryEmail?.value || '---';
        }
    };

    const getOrderReason = () => {
        const serviceType = orderForm.data.orderType?.serviceType;
        const reason = orderReasons[orderForm.data.orderType?.reason];

        if (reason) {
            if (serviceType === 'freeOfChargeOrder') {
                return `FOC ${reason}`;
            }
            return reason;
        }
        return '---';
    };

    return (
        <header className="c--order-form-header">
            <div className="content-wrapper">
                <div className="container">
                    <div className="container-background">
                        <ThemeProvider theme={darkTheme}>
                            <div className="title">
                                {!!orderForm.data.id && (
                                    <span>Quote #{orderForm.data.incrementId}</span>
                                )}
                                {!orderForm.data.id && (
                                    <span className="unsaved">Unsaved</span>
                                )}
                            </div>
                            <div className="column-holder">
                                <div className="column">
                                    <FormField<TextFieldProps>
                                        component={TextField}
                                        disableUnderline
                                        id="header-card--username"
                                        label="Created by"
                                        value={createdBy}
                                        readOnly
                                    />
                                    <FormField<TextFieldProps>
                                        component={TextField}
                                        validators={[requiredValidator, latinOneValidator, trimValidator, maxCharactersValidator(30)]}
                                        id="header-card--po-number"
                                        label="PO number/name"
                                        value={orderForm.data.purchaseOrderNumber || ''}
                                        onChange={(value) => {
                                            onChange({ purchaseOrderNumber: value });
                                        }}
                                    />
                                    <FormField<TextFieldProps>
                                        component={TextField}
                                        validators={[latinOneValidator, maxCharactersValidator(30)]}
                                        id="header-card--reference-number"
                                        label="Reference number"
                                        value={orderForm.data.referenceNumber || ''}
                                        onChange={(value) => {
                                            onChange({ referenceNumber: value });
                                        }}
                                    />
                                    <FormField<CampaignCodeFieldProps>
                                        component={CampaignCodeField}
                                        label="Campaign code"
                                        id="campaign-code-field"
                                        uppercase
                                        minChars={5}
                                        disabled={orderForm.data.readOnly}
                                        value={orderForm.data.campaignCode}
                                        onChange={(campaignCode) => {
                                            onChange({ campaignCode });
                                        }}
                                        validationFunction={(campaignCode) => {
                                            const requestData = {
                                                campaignCode,
                                                customerNumber: auth.currentCustomer.key,
                                            };
                                            return backendService.verifyCampaignCode(requestData).then((response) => response.isValid === 'yes');
                                        }}
                                    />
                                    <FormField<CampaignCodeFieldProps>
                                        component={CampaignCodeField}
                                        label="IDP-number"
                                        id="idp-code-field"
                                        minChars={6}
                                        disabled={orderForm.data.readOnly}
                                        value={orderForm.data.idpCode}
                                        onChange={(idpCode) => {
                                            onChange({ idpCode });
                                        }}
                                        validationFunction={(idpCode) => {
                                            const requestData = {
                                                idpCode,
                                                customerNumber: auth.currentCustomer.key,
                                            };
                                            return backendService.verifyIdpCode(requestData).then((response) => response.isValid === true);
                                        }}
                                    />
                                    <FormField<DatePickerProps>
                                        component={DatePicker}
                                        id="header-card--last-day-of-approval"
                                        label="Last day of approval"
                                        value={orderForm.data.lastDayOfApproval}
                                        onChange={(value) => {
                                            onChange({ lastDayOfApproval: value });
                                        }}
                                    />
                                    <Button
                                        className="edit-customer-button"
                                        icon={orderForm.data.endConsumer ? null : 'add'}
                                        onClick={showCustomerDialog}>
                                        {orderForm.data.endConsumer ? 'Edit customer' : 'Add customer'}
                                    </Button>
                                </div>
                                {orderForm.data.orderType?.serviceType && orderForm.data.orderType?.serviceType !== 'standardOrder' && (
                                    <div className="column">
                                        <FormField<TextFieldProps>
                                            component={TextField}
                                            disableUnderline
                                            id="header-card--order-type"
                                            label="Order type"
                                            value={serviceTypes[orderForm.data.orderType?.serviceType]}
                                            readOnly
                                        />
                                        {(orderForm.data.orderType?.serviceType == 'serviceOrder' || orderForm.data.orderType?.serviceType == 'freeOfChargeOrder') && (
                                            <Fragment>
                                                <FormField<TextFieldProps>
                                                    component={TextField}
                                                    disableUnderline
                                                    id="header-card--order-reason"
                                                    label="Order reason"
                                                    value={getOrderReason()}
                                                    readOnly
                                                />
                                            </Fragment>
                                        )}
                                    </div>
                                )}
                            </div>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </header>
    );
}
