import React, { useCallback, useEffect, useState } from 'react';
import Icon from '@mui/material/Icon';
import debounce from 'lodash/debounce';
import { AxiosError } from 'axios';

import TextField from '../../../components/formFields/TextField';
import { SpinnerProps } from '../../../components/Spinner/Spinner';
import { ErrorData } from '../../../services/backendService';

export interface CampaignCodeFieldProps {
    label: string;
    id: string;
    disabled: boolean;
    minChars: number;
    uppercase?: boolean;
    value: string;
    onChange: (campaignCode: string) => void;
    validationFunction: (value: string) => Promise<boolean>;
}

CampaignCodeField.defaultProps = {
    value: '',
} as CampaignCodeFieldProps;

export default function CampaignCodeField(props: CampaignCodeFieldProps) {
    const [inputText, setInputText] = useState('');
    const [spinner, setSpinner] = useState<SpinnerProps>(null);
    const [valid, setValid] = useState<boolean | null>(null);
    const debounceVerifyInputText = useCallback(debounce(verifyInputText, 600), []);

    useEffect(() => {
        if (props.value) {
            setInputText(props.value);
            setValid(true);
        }
    }, []);

    function verifyInputText(value: string) {
        setSpinner({ spinning: true });

        // TODO: Active requests should be canceled if the value changes
        props.validationFunction(value).then(
            (response) => {
                setSpinner({ spinning: false });
                setValid(response === true);

                if (response === true) {
                    props.onChange(value);
                }
            },
            (error: AxiosError<ErrorData>) => {
                setSpinner({ error: true, errorMessage: error?.response?.data?.message });
            },
        );
    }

    return (
        <TextField
            autoComplete="off"
            id={props.id}
            label={props.label}
            disabled={props.disabled}
            spinner={!inputText ? null : {
                ...spinner,
                defaultElement: (() => {
                    if (valid === null) {
                        return null;
                    }

                    return (
                        <Icon
                            sx={{
                                fontSize: '11px',
                                height: '15px',
                                width: '15px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: valid ? '#fff' : '#ef3f4a',
                                color: valid ? '#000' : '#fff',
                            }}>
                            {valid ? 'done' : 'close'}
                        </Icon>
                    );
                })(),
            }}
            value={inputText}
            onChange={(value) => {
                const nextInputText = props.uppercase ? value?.trim().toUpperCase() : value?.trim();
                setInputText(nextInputText);

                if (value.length >= props.minChars) {
                    debounceVerifyInputText(nextInputText);
                }
                if (value.length === 0) {
                    setSpinner(null);
                    setValid(null);
                }
                if (props.value) {
                    props.onChange('');
                }
            }}
        />
    );
}
