import React, { ReactElement, useState } from 'react';
import MuiTextField from '@mui/material/TextField';

export interface NumberFieldProps {
    id?: string;
    value?: number;
    label?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
    lineThrough?: boolean;
    textAlign?: 'left' | 'right';
    size?: 'small' | 'medium';
    type?: 'number' | 'text';
    error?: boolean;
    errorText?: string;
    helpText?: string;
    onChange?: (value: number, event?) => void;
    formatValueOnBlur?: (value: number) => string;
}

NumberField.defaultProps = {
    value: 0,
    lineThrough: false,
    disabled: false,
    error: false,
    type: 'number',
    textAlign: 'left',
    size: 'medium',
    min: 0,
    /**
     * Max limit is set to Number.MAX_SAFE_INTEGER to avoid bugs related to large numbers.
     * (Using 9007199254740991 instead of Number.MAX_SAFE_INTEGER for IE support.)
     */
    max: 9007199254740991,
} as NumberFieldProps;

export default function NumberField(props: NumberFieldProps): ReactElement {
    const [focused, setFocused] = useState(false);

    function shouldValueChange(newValue: number, oldValue: number): boolean {
        if (newValue === oldValue) {
            return false;
        }
        if (newValue > props.max) {
            return false;
        }
        if (newValue < props.min) {
            return false;
        }
        return true;
    }

    return (
        <MuiTextField
            fullWidth
            variant="standard"
            size={props.size}
            id={props.id}
            type={props.type}
            disabled={props.disabled}
            label={props.label}
            value={props.formatValueOnBlur && props.type === 'text' && !focused ? props.formatValueOnBlur(props.value) : props.value}
            error={props.error}
            helperText={props.error ? props.errorText : props.helpText}
            inputProps={{
                sx: {
                    textDecoration: props.lineThrough && !focused ? 'line-through' : null,
                    textAlign: props.textAlign === 'right' ? 'right' : null,
                    fontSize: props.size === 'small' ? '14px' : null,
                },
            }}
            onChange={(event) => {
                const outputValue = parseInt(event.target.value) || props.min;
                if (shouldValueChange(outputValue, props.value)) {
                    props.onChange(outputValue, event);
                }
            }}
            onFocus={() => {
                setFocused(true);
            }}
            onBlur={() => {
                setFocused(false);
            }}
            onKeyPress={(event) => {
                const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                if (!event.ctrlKey && !event.altKey && !event.metaKey && validKeys.indexOf(event.key) === -1) {
                    event.preventDefault();
                }
            }}
        />
    );

}
