import { AxiosError } from 'axios';
import { createAction } from '@reduxjs/toolkit';

import { backendService, ErrorData } from '../../services/backendService';
import { sessionStorageService } from '../../services/sessionStorageService';
import { openSummaryDialog } from '../../layouts/Auth/dialogs/SummaryDialog/actions';
import { AppThunkAction } from '../../store';
import { FilterParameters, PagedQuote, PagedResponse } from '../../services/backendTypes';
import { LegacyQuote } from '../../services/legacyBackendTypes';
import { convertLegacyQuote } from '../../functions/convertLegacyQuote';

export function loadOrders(customerNo: string, params: FilterParameters, rowToExpand?: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadOrdersPending());
        return backendService.getOrdersByPage(customerNo, params).then(
            (response) => {
                dispatch(setLoadOrdersComplete(response));
                // Expand table row if only one result
                if (response.list.length === 1) {
                    dispatch(loadOrderAndExpandTableRow(response.list[0].id));
                } else if (rowToExpand) {
                    dispatch(loadOrderAndExpandTableRow(rowToExpand));
                }
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLoadOrdersRejected(error?.response?.data?.message));
            },
        );
    };
}

export const setLoadOrdersPending = createAction('LOAD_ORDERS_PENDING');

export const setLoadOrdersComplete = createAction<PagedResponse<PagedQuote>>('LOAD_ORDERS_COMPLETE');

export const setLoadOrdersRejected = createAction<string>('LOAD_ORDERS_REJECTED');

export function loadOrderAndExpandTableRow(id: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadOrderPending(id));
        return backendService.getOrder(id).then((response) => {
            dispatch(setLoadOrderComplete(response.quote));
            dispatch(expandTableRow(response.quote.id));
        });
    };
}

export function openOrderSummary(id: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setMoreMenuPending(id));
        return backendService.getOrder(id).then((response) => {
            dispatch(setLoadOrderComplete(response.quote));
            dispatch(setMoreMenuComplete(id));
            dispatch(openSummaryDialog(convertLegacyQuote(response.quote)));
        });
    };
}

export const setLoadOrderPending = createAction<number>('LOAD_ORDER_PENDING');

export const setLoadOrderComplete = createAction<LegacyQuote>('LOAD_ORDER_COMPLETE');

export const expandTableRow = createAction<number>('EXPAND_TABLE_ROW');

export const collapseTableRow = createAction<number>('COLLAPSE_TABLE_ROW');

export const setMoreMenuPending = createAction<number>('MORE_MENU_PENDING');

export const setMoreMenuComplete = createAction<number>('MORE_MENU_COMPLETE');

export function openDeliveryConfirmation(item: PagedQuote) {
    const jwt = sessionStorageService.getTokenWithoutPrefix();
    const url = backendService.urlTo(`en/partner-order/api/pdf/delivery-confirmation/${item.orderNumber}.pdf?jwt=${jwt}&inline=1`);
    window.open(url, '_blank');
}

export function openCustomerQuotation(item: PagedQuote, showWholesalePrice: boolean) {
    const jwt = sessionStorageService.getTokenWithoutPrefix();
    const hideCampaign = false === showWholesalePrice ? '&showWholesalePrices=false' : '';
    const url = backendService.urlTo(`en/partner-order/api/pdf/customer-quotation/${item.orderNumber}.pdf?jwt=${jwt}${hideCampaign}&inline=1`);
    window.open(url, '_blank');
}
