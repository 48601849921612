import { createReducer } from '@reduxjs/toolkit';
import * as formActions from './actions';

export interface FormReduxState {
    dirty: boolean;
}

const initialState = {
    dirty: false,
} as FormReduxState;

export const form = createReducer(initialState, (builder) => {
    builder.addCase(formActions.markAsDirty, (state) => {
        state.dirty = true;
    });
    builder.addCase(formActions.markAsPristine, (state) => {
        state.dirty = false;
    });
});
