import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import { AxiosError } from 'axios';

import SelectField from '../../../../components/formFields/SelectField';
import TextField from '../../../../components/formFields/TextField';
import Button from '../../../../components/formFields/Button';
import { backendService, ErrorData } from '../../../../services/backendService';
import { emailValidator } from '../../../../validators/emailValidator';
import * as dialogActions from './actions';
import * as formActions from '../../../../components/formFields/actions';
import { TITLES } from './titles';
import { Dialog } from '../../../../components/Dialog';
import { ListQuote, QuoteEndConsumer } from '../../../../services/backendTypes';
import { OrderFormData } from '../../reducer';
import { AppDispatch, ReduxState } from '../../../../store';
import { SpinnerProps } from '../../../../components/Spinner/Spinner';

type CombinedProps = Props & ReduxProps & WithHooksProps;
type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };
interface WithHooksProps { isWidthUpMd: boolean; }

interface Props {
    data: QuoteEndConsumer;
    quotes: {
        data: ListQuote[];
        spinner: SpinnerProps;
    };
    onChange: (values: Partial<OrderFormData>) => void;
}

interface State {
    formData: QuoteEndConsumer;
    emailSpinner: SpinnerProps;
    applyButtonVisible: boolean;
    importCustomerSpinner: SpinnerProps;
    importCustomerQuoteId: number;
    importCustomerHelpText: string;
    endConsumerData: QuoteEndConsumer;
}

const mapStateToProps = (store: ReduxState) => ({
    dialog: store.customerDialog,
    countries: store.customerDialog.countries || [],
});

class CustomerDialog extends Component<CombinedProps, State> {

    state = {
        formData: {},
        emailSpinner: {},
        applyButtonVisible: false,
        importCustomerSpinner: {},
        importCustomerQuoteId: undefined,
        endConsumerData: undefined,
    } as State;

    constructor(props: CombinedProps) {
        super(props);
        this.debounceGetContact = debounce(this.debounceGetContact, 500);
    }

    componentDidUpdate(prevProps: CombinedProps) {
        if (this.props.dialog.visible && !prevProps.dialog.visible) {
            this.setState({ formData: this.props.data || {} as QuoteEndConsumer });
        }
    }

    done() {
        this.props.onChange({
            endConsumer: this.state.formData,
        });
        this.props.dispatch(formActions.markAsDirtyIfPristine());
        this.close();
    }

    close() {
        this.props.dispatch(dialogActions.closeCustomerDialog());
    }

    updateForm(value) {
        this.setState({
            formData: {
                ...this.state.formData,
                ...value,
            },
        });
    }

    debounceGetContact(value: string) {
        this.getContact(value);
    }

    getContact(value: string) {
        if (emailValidator.validate(value) !== null) {
            return;
        }
        this.setState({
            emailSpinner: { spinning: true },
        });
        backendService.getContact(value).then(
            (response) => {
                if (response && response.id) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            firstname: response.firstname,
                            lastname: response.lastname,
                            phone: response.mobilePhone,
                            apartmentNumber: '',
                            country: response.address?.country?.name,
                            zipCode: response.address?.zipCode,
                            city: response.address?.city,
                            street: response.address?.street,
                            region: '',
                            title: '',
                        },
                        emailSpinner: {},
                    });
                } else {
                    this.setState({
                        emailSpinner: {},
                    });
                }
            },
            (error: AxiosError<ErrorData>) => {
                this.setState({
                    emailSpinner: { error: true, errorMessage: error?.response?.data?.message },
                });
            },
        );
    }

    importCustomerChanged(quoteId: number) {
        if (quoteId) {
            this.setState({
                importCustomerQuoteId: quoteId,
                importCustomerSpinner: { spinning: true },
            });
            return backendService.getQuote(quoteId).then(
                (response) => {
                    this.setState({
                        importCustomerSpinner: { spinning: false },
                    });

                    if (response?.endConsumer) {
                        this.setState({
                            importCustomerHelpText: undefined,
                            applyButtonVisible: true,
                            endConsumerData: response.endConsumer,
                        });
                    } else {
                        this.setState({
                            importCustomerHelpText: 'No customer found on quote',
                            applyButtonVisible: false,
                        });
                    }
                },
                (error: AxiosError<ErrorData>) => {
                    this.setState({
                        importCustomerSpinner: {
                            error: true,
                            errorMessage: error?.response?.data?.message,
                        },
                    });
                },
            );
        }
    }

    changeEndConsumer() {
        this.setState({
            formData: cloneDeep(this.state.endConsumerData),
        });
    }

    render() {
        const customerDialogActions = [
            {
                onClick: () => this.done(),
                children: 'Done',
            },
            {
                onClick: () => this.close(),
                children: 'Cancel',
            },
        ];

        const endConsumerList = (this.props.quotes.data || [])
            .filter((element) => !isNil(element.endConsumer) && element.version !== 'V1')
            .map((filteredElement) => ({
                label: `${filteredElement.endConsumer.firstname} ${filteredElement.endConsumer.lastname} #${filteredElement.id}`,
                value: filteredElement.id,
            }));

        return (
            <Dialog
                title="Edit customer"
                className="c--customer-dialog"
                open={this.props.dialog.visible || false}
                actions={customerDialogActions}
                onClose={() => {
                    this.close();
                }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="import-customer-wrapper">
                            <SelectField
                                id="import-customer"
                                label="Import customer"
                                defaultToFirst={false}
                                value={this.state.importCustomerQuoteId}
                                fullWidth={true}
                                className="import-customer-select"
                                menuItems={endConsumerList}
                                onChange={this.importCustomerChanged.bind(this)}
                                helpText={this.state.importCustomerHelpText || undefined}
                                spinner={this.state.importCustomerSpinner}
                            />
                            <Button
                                className="apply-button"
                                disabled={!this.state.applyButtonVisible}
                                onClick={this.changeEndConsumer.bind(this)}>
                                Apply
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 1 : null }}>
                        <TextField
                            id="email"
                            label="Email"
                            value={this.state.formData.email || ''}
                            spinner={this.state.emailSpinner}
                            onChange={(value) => {
                                this.updateForm({ email: value });
                                if (!window['ftEmailLookupDisable']) {
                                    this.debounceGetContact(value);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 3 : null }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <SelectField
                                    id="title"
                                    label="Title"
                                    fullWidth={true}
                                    value={this.state.formData.title}
                                    menuItems={TITLES}
                                    onChange={(value) => {
                                        this.updateForm({ title: value });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField
                                    id="firstname"
                                    label="First name"
                                    value={this.state.formData.firstname || ''}
                                    onChange={(value) => {
                                        this.updateForm({ firstname: value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 5 : null }}>
                        <TextField
                            id="lastname"
                            label="Last name"
                            value={this.state.formData.lastname || ''}
                            onChange={(value) => {
                                this.updateForm({ lastname: value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 7 : null }}>
                        <TextField
                            id="phone"
                            label="Mobile number"
                            value={this.state.formData.phone || ''}
                            onChange={(value) => {
                                this.updateForm({ phone: value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 9 : null }}>
                        <TextField
                            id="apartmentNumber"
                            label="Suite or Apartment"
                            value={this.state.formData.apartmentNumber || ''}
                            onChange={(value) => {
                                this.updateForm({ apartmentNumber: value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 2 : null }}>
                        <SelectField
                            id="country-selectbox"
                            label="Country"
                            fullWidth={true}
                            value={this.state.formData.country}
                            menuItems={this.props.countries.map((element) => element.name)}
                            onChange={(value) => {
                                this.updateForm({ country: value });
                            }}
                            spinner={this.props.dialog.countriesSpinner}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 4 : null }}>
                        <TextField
                            id="zipCode"
                            label="Zip/Postal code"
                            value={this.state.formData.zipCode || ''}
                            onChange={(value) => {
                                this.updateForm({ zipCode: value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 6 : null }}>
                        <TextField
                            id="city"
                            label="City"
                            value={this.state.formData.city || ''}
                            onChange={(value) => {
                                this.updateForm({ city: value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 8 : null }}>
                        <TextField
                            id="street"
                            label="Street address"
                            value={this.state.formData.street || ''}
                            onChange={(value) => {
                                this.updateForm({ street: value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ order: this.props.isWidthUpMd ? 10 : null }}>
                        <TextField
                            id="region"
                            label="State/Province"
                            value={this.state.formData.region || ''}
                            onChange={(value) => {
                                this.updateForm({ region: value });
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        );
    }

}

const withHooks = () => (Component) => {
    return function WrappedComponent(props) {
        const theme = useTheme();
        const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));

        return <Component {...props} isWidthUpMd={isWidthUpMd} />;
    };
};

const connector = connect(mapStateToProps);
export default connector(withHooks()(CustomerDialog));
