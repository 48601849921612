import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { closeSummaryDialog } from './actions';
import { QuoteBedTable } from './QuoteBedTable';
import { QuoteAccessoryTable } from './QuoteAccessoryTable';
import { OrderBedTable } from './OrderBedTable';
import { OrderAccessoryTable } from './OrderAccessoryTable';
import { Dialog } from '../../../../components/Dialog';
import { ReduxState } from '../../../../store';
import { OrderFormAccessoryItem, OrderFormBedItem } from '../../../../containers/reducers';
import { QuoteBedItem } from '../../../../services/backendTypes';
import { groupAccessoryItemsByCategory } from '../../../../containers/OrderForm/functions';

type ReduxProps = ConnectedProps<typeof connector>;

const mapStateToProps = (store: ReduxState) => ({
    summaryDialog: store.summaryDialog,
    auth: store.auth,
});

class SummaryDialog extends React.Component<ReduxProps> {

    closeDialog() {
        this.props.dispatch(closeSummaryDialog());
    }

    render() {
        if (this.props.summaryDialog.quote.order) {
            return this.renderOrderSummary();
        } else {
            return this.renderQuoteSummary();
        }
    }

    getActions() {
        return [
            {
                onClick: () => this.closeDialog(),
                children: 'Close',
            },
        ];
    }

    renderOrderSummary() {
        const bedItems = this.props.summaryDialog.quote.items.filter((item) => item.itemType === 'bed') as QuoteBedItem[];
        const groupedAccessoryItems = groupAccessoryItemsByCategory(this.props.summaryDialog.quote.items);

        return (
            <Dialog
                size="fullscreen"
                className="summary-dialog"
                title="Preview"
                disableContentPadding
                open={this.props.summaryDialog.visible}
                actions={this.getActions()}
                onClose={() => this.closeDialog()}>
                {!!bedItems.length && (
                    <div>
                        <h3>Beds</h3>
                        <OrderBedTable data={bedItems as QuoteBedItem[]} />
                    </div>
                )}
                {Object.keys(groupedAccessoryItems).map((categoryKey) => (
                    <div key={categoryKey}>
                        <h3>{categoryKey}</h3>
                        <OrderAccessoryTable data={groupedAccessoryItems[categoryKey]} />
                    </div>
                ))}
            </Dialog>
        );
    }

    renderQuoteSummary() {
        const bedItems = this.props.summaryDialog.quote.items.filter((item) => item.itemType === 'bed') as OrderFormBedItem[];

        return (
            <Dialog
                size="fullscreen"
                className="summary-dialog"
                title="Preview"
                disableContentPadding
                open={this.props.summaryDialog.visible}
                actions={this.getActions()}
                onClose={() => this.closeDialog()}>
                {!!bedItems.length && (
                    <div>
                        <h3>Beds</h3>
                        <QuoteBedTable
                            data={bedItems}
                            beds={this.props.auth.beds.data}
                            bedProperties={this.props.auth.bedProperties}
                        />
                    </div>
                )}
                {this.props.auth.accessories?.map((category) => {
                    const accessoryItems = this.props.summaryDialog.quote.items.filter((item) => item.itemType === 'accessory' && item.level1 === category.name) as OrderFormAccessoryItem[];
                    if (!accessoryItems.length) {
                        return null;
                    }
                    return (
                        <div key={category.name}>
                            <h3>{category.name}</h3>
                            <QuoteAccessoryTable
                                accessory={category}
                                data={accessoryItems}
                            />
                        </div>
                    );
                })}
            </Dialog>
        );
    }

}

const connector = connect(mapStateToProps);
export default connector(SummaryDialog);

