import React, { ChangeEvent, Component, FocusEvent, KeyboardEvent, ReactElement } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import FormHelperText from '@mui/material/FormHelperText';
import Icon from '@mui/material/Icon';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';

import { Spinner, SpinnerProps } from '../Spinner/Spinner';
import { isIOS } from '../../functions/isIOS';

export interface SelectFieldProps {
    id?: string;
    label?: string;
    fullWidth?: boolean;
    value?: any;
    menuItems?: any[];
    itemLabel?: string;
    itemValue?: string;
    itemAvatar?: string;
    error?: boolean;
    errorText?: string;
    helpText?: string | ReactElement;
    disabled?: boolean;
    onChange?: (value, event?: ChangeEvent) => void;
    onBlur?: (event: FocusEvent) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    defaultToFirst?: boolean;
    spinner?: SpinnerProps;
    autoFocus?: boolean;
    className?: string;
}

interface State {
    open: boolean;
    disabled: boolean;
    autoFocus: boolean;
    selectIsOpen: boolean;
}

interface MenuItem {
    key: number;
    label: string;
    value: string;
    leftAvatar: string;
    className: string;
}

export default class SelectField extends Component<SelectFieldProps, State> {

    state: State = {
        open: false,
        disabled: false,
        autoFocus: false,
        selectIsOpen: false,
    };
    menuItems: MenuItem[] = [];
    prevSelected: MenuItem;

    static defaultProps = {
        itemLabel: 'label',
        itemValue: 'value',
    };

    constructor(props: SelectFieldProps) {
        super(props);
        this.menuItems = this.prepareMenuItems(props.menuItems, props.itemLabel, props.itemValue, props.itemAvatar);
    }

    componentDidMount() {
        if (this.props.value) {
            this.checkSelectedValue();
        } else {
            this.defaultToFirst();
        }
    }

    componentWillUnmount() {
        this.props.onChange('');
    }

    componentDidUpdate(prevProps: SelectFieldProps) {
        this.prevSelected = this.menuItems.find((item) => item.value === prevProps.value);
        this.checkSelectedValue();
        if (!isEqual(prevProps.menuItems, this.props.menuItems)) {
            this.defaultToFirst();
        }
    }

    prepareMenuItems(menuItems: any[], itemLabel: string, itemValue: string, itemAvatar: string): MenuItem[] {
        if (!menuItems) {
            return [];
        }
        const hasAvatars = !!itemAvatar && !!menuItems.some((item) => {
            return item[itemAvatar];
        });
        return menuItems.map((item: any, index: number) => ({
            key: index,
            label: isPlainObject(item) ? item[itemLabel] : item,
            value: isPlainObject(item) ? item[itemValue] : item,
            leftAvatar: hasAvatars ? item[itemAvatar] : null,
            disabled: !!item.disabled,
            className: item.hidden ? 'hidden' : '',
        }));
    }

    defaultToFirst() {
        const shouldDefaultToFirst = this.props.defaultToFirst || this.menuItems.length === 1;
        if (shouldDefaultToFirst && this.menuItems && this.menuItems.length) {
            this.props.onChange(this.menuItems[0].value);
            this.setState({
                disabled: this.menuItems.length === 1,
            });
        } else if (this.state.disabled === true) {
            this.setState({
                disabled: false,
            });
        }
    }

    checkSelectedValue() {
        if (!this.props.value) {
            return;
        }
        if (!this.menuItems.length) {
            return;
        }
        // Value match: Do nothing
        if (!!this.menuItems.find((item) => item.value === this.props.value)) {
            return;
        }
        // Label match: Change value
        if (this.prevSelected && this.prevSelected.value === this.props.value) {
            const prevSelectedInMenuItems = this.menuItems.find((item) => {
                return item.label === this.prevSelected.label;
            });
            if (prevSelectedInMenuItems) {
                this.props.onChange(prevSelectedInMenuItems.value);
                return;
            }
        }
        // No match: Reset value
        this.props.onChange('');
    }

    handleKeyDown(event: KeyboardEvent) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
        // Close dropdown when keyboard shortcuts are used
        if (event.ctrlKey && event.keyCode >= 65 && event.keyCode <= 90) {
            this.setState({ open: false });
        }
    }

    onChange(event: ChangeEvent<any>) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value, event);
        }
    }

    render() {
        this.menuItems = this.prepareMenuItems(this.props.menuItems, this.props.itemLabel, this.props.itemValue, this.props.itemAvatar);

        return (
            <FormControl
                variant="standard"
                error={this.props.error}
                className={this.props.className}
                onKeyDown={this.handleKeyDown.bind(this)}>
                <InputLabel>{this.props.label}</InputLabel>
                <Select
                    id={this.props.id}
                    autoFocus={this.props.autoFocus}
                    value={this.props.value || ''}
                    disabled={this.props.disabled || this.state.disabled}
                    open={this.state.open}
                    onChange={this.onChange.bind(this)}
                    onBlur={this.props.onBlur}
                    onOpen={() => {
                        this.setState({ open: true });
                    }}
                    onClose={() => {
                        this.setState({ open: false });
                    }}
                    MenuProps={{
                        // Bugfix: Disable animations on iOS devices to fix flickering behaviour when opening dropdown
                        transitionDuration: isIOS() ? 0 : undefined,
                    }}
                    IconComponent={(iconProps) => <Spinner {...this.props.spinner} defaultElement={<Icon {...iconProps}>arrow_drop_down</Icon>} />}>
                    {this.menuItems.map((menuItem, index) => (
                        <MenuItem key={index} value={menuItem.value} className={menuItem.className}>
                            {menuItem.leftAvatar && (
                                <ListItemIcon className="list-item-icon" sx={{ minWidth: '56px !important' }}>
                                    <Avatar src={menuItem.leftAvatar} />
                                </ListItemIcon>
                            )}
                            {menuItem.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{this.props.error ? this.props.errorText : this.props.helpText}</FormHelperText>
            </FormControl>
        );
    }

}
