import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as appActions from './actions';
import { MessageDialog } from './components/MessageDialog';
import { ReduxState } from './store';

type ReduxProps = ConnectedProps<typeof connector>;

const mapStateToProps = (store: ReduxState) => ({
    alertDialog: store.app.alertDialog,
});

function AlertDialog(props: ReduxProps) {

    function hideDialog() {
        props.dispatch(appActions.closeAlertDialog());
    }

    return (
        <MessageDialog
            severity="error"
            open={props.alertDialog.visible}
            actions={[{
                onClick: () => hideDialog(),
                children: 'Ok',
            }]}
            onClose={() => hideDialog()}>
            {props.alertDialog.message}
        </MessageDialog>
    );

}

const connector = connect(mapStateToProps);
export default connector(AlertDialog);
