import React from 'react';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import format from 'date-fns/format';

import TextField from '../../components/formFields/TextField';
import { ItemPrice } from '../OrderList/ItemPrice';
import { Quote } from '../../services/backendTypes';
import { TotalQuoteValue } from './TotalQuoteValue';
import { groupAccessoryItemsByCategory } from '../OrderForm/functions';

interface Props {
    data: Quote;
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
}

export class QuoteDetails extends React.Component<Props> {

    render() {
        const bedItems = this.props.data.items.filter((item) => item.itemType === 'bed');
        const groupedAccessoryItems = groupAccessoryItemsByCategory(this.props.data.items);

        return (
            <article className="c--quote-details">
                <section>
                    <h1>Quote</h1>
                    <div className="sub-section">
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Quote number" value={this.props.data.incrementId?.toString() || '---'} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Created by" value={`${this.props.data.createdBy.contact.firstname} ${this.props.data.createdBy.contact.lastname}`} />
                            </Grid>
                            <Hidden mdUp>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Created at" value={format(new Date(this.props.data.createdTime), 'yyyy-MM-dd')} />
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="PO number/name" value={this.props.data.purchaseOrderNumber || '---'} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Reference number" value={this.props.data.referenceNumber || '---'} />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextField readOnly label="Campaign code" value={this.props.data.campaignCode || (this.props.data.idpCode ? 'IDP: ' + this.props.data.idpCode : '---')} />
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section>
                    <h1>Customer</h1>
                    {!!this.props.data.endConsumer && (
                        <div className="sub-section">
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Title" value={this.props.data.endConsumer.title || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="First name" value={this.props.data.endConsumer.firstname || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Last name" value={this.props.data.endConsumer.lastname || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Email" value={this.props.data.endConsumer.email || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Phone number" value={this.props.data.endConsumer.phone || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Street address" value={this.props.data.endConsumer.street || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Apartment number" value={this.props.data.endConsumer.apartmentNumber || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Zip code" value={this.props.data.endConsumer.zipCode || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="City" value={this.props.data.endConsumer.city || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Country" value={this.props.data.endConsumer.country || '---'} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField readOnly label="Region" value={this.props.data.endConsumer.region || '---'} />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    {!this.props.data.endConsumer && (
                        <span className="no-customer">No customer</span>
                    )}
                </section>
                {!!bedItems.length && (
                    <section>
                        <h1>Beds</h1>
                        {bedItems.map((bedItem, bedIndex) => (
                            <div className="sub-section" key={bedIndex}>
                                <Grid container spacing={2}>
                                    {bedItem.name && (
                                        <Grid item xs={12} lg={3}>
                                            <TextField readOnly label="Bed type" value={bedItem.name} />
                                        </Grid>
                                    )}
                                    {bedItem.parametersDetails.map((parameter, parameterIndex) => !parameter.valueLabel ? null : (
                                        <Grid item xs={12} lg={3} key={parameterIndex}>
                                            <TextField readOnly label={parameter.keyLabel} value={parameter.valueLabel} />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} lg={3}>
                                        <TextField readOnly label="Bed quantity" value={bedItem.quantity?.toString()} />
                                    </Grid>
                                </Grid>
                                <ItemPrice
                                    item={bedItem}
                                    hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                                    hideWholesalePrices={this.props.hideWholesalePrices}
                                />
                            </div>
                        ))}
                    </section>
                )}
                {!!Object.keys(groupedAccessoryItems).length && (
                    <section>
                        {Object.keys(groupedAccessoryItems).map((categoryName, categoryIndex) => (
                            <div key={categoryIndex}>
                                <h1>{categoryName}</h1>
                                {groupedAccessoryItems[categoryName].map((accessoryItem, accessoryIndex) => (
                                    <div className="sub-section" key={accessoryIndex}>
                                        <Grid container spacing={2}>
                                            {!!accessoryItem.level2 && (
                                                <Grid item xs={12} lg={3}>
                                                    <TextField readOnly label="Category" value={accessoryItem.level2} />
                                                </Grid>
                                            )}
                                            {!!accessoryItem.name && (
                                                <Grid item xs={12} lg={3}>
                                                    <TextField readOnly label="Name" value={accessoryItem.name} />
                                                </Grid>
                                            )}
                                            {accessoryItem.parametersDetails.map((parameter, parameterIndex) => (
                                                <Grid item xs={12} lg={3} key={parameterIndex}>
                                                    <TextField readOnly label={parameter.keyLabel} value={parameter.valueLabel || '---'} />
                                                </Grid>
                                            ))}
                                            <Grid item xs={12} lg={3}>
                                                <TextField readOnly label="Quantity" value={accessoryItem.quantity?.toString()} />
                                            </Grid>
                                        </Grid>
                                        <ItemPrice
                                            item={accessoryItem}
                                            hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                                            hideWholesalePrices={this.props.hideWholesalePrices}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </section>
                )}
                <TotalQuoteValue
                    data={this.props.data}
                    hideWholesalePrices={this.props.hideWholesalePrices}
                    hideEndConsumerPrices={this.props.hideEndConsumerPrices}
                />
            </article>
        );
    }
}
