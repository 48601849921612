import { createReducer } from '@reduxjs/toolkit';
import { QuoteBuilderResponseItem } from '../../../../services/backendTypes';
import { BasicDebugTableMaterial } from './BasicTable';
import * as debugDialogActions from './actions';

interface DebugDialogReduxState {
    visible: boolean;
    tabs: DebugTabs;
}

export interface DebugTabs {
    basic?: BasicDebugTableMaterial[];
    advanced?: QuoteBuilderResponseItem;
}

const initialState = {
    visible: false,
    tabs: {},
} as DebugDialogReduxState;

export const debugDialog = createReducer(initialState, (builder) => {
    builder.addCase(debugDialogActions.openDebugDialog, (state, action) => {
        state.visible = true;
        state.tabs = action.payload;
    });
    builder.addCase(debugDialogActions.closeDebugDialog, (state) => {
        state.visible = false;
        state.tabs = {};
    });
});
