import { createTheme, ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const darkBlue = {
    50: '#e0e0e6',
    100: '#b3b3c1',
    200: '#808098',
    300: '#4d4d6e',
    400: '#26264f',
    500: '#000030',
    600: '#00002b',
    700: '#000024',
    800: '#00001e',
    900: '#000013',
    A100: '#5454ff',
    A200: '#2121ff',
    A400: '#0000ed',
    A700: '#0000d4',
    contrastDefaultColor: 'light',
};

const red = {
    50: '#ffebea',
    100: '#ffcdcb',
    200: '#ffaba9',
    300: '#ff8986',
    400: '#ff706c',
    500: '#ff5752',
    600: '#ff4f4b',
    700: '#ff4641',
    800: '#ff3c38',
    900: '#ff2c28',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffd6d5',
    A700: '#ffbdbc',
    contrastDefaultColor: 'light',
};

const white = {
    50: '#ffffff',
    100: '#ffffff',
    200: '#ffffff',
    300: '#ffffff',
    400: '#ffffff',
    500: '#ffffff',
    600: '#ffffff',
    700: '#ffffff',
    800: '#ffffff',
    900: '#ffffff',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#ffffff',
    contrastDefaultColor: 'dark',
};

const baseThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"Montserrat", sans-serif',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 300,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                    marginTop: '11px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontWeight: 300,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    fontWeight: 600,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                    '& .list-item-icon': {
                        display: 'none',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 0,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    maxHeight: '425px !important',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 300,
                    fontSize: 14,
                    paddingTop: 8,
                    paddingBottom: 8,
                    height: 24,
                    minHeight: '40px !important',
                    '&.hidden': {
                        display: 'none',
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: 8,
                    marginBottom: 8,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    height: 34,
                    width: 34,
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                },
                fontSizeInherit: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    fontSize: 13,
                },
                text: {
                    padding: '4px 16px',
                },
                textSizeSmall: {
                    fontSize: 10,
                    padding: '3px 16px',
                },
                startIcon: {
                    marginRight: 5,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    padding: 3,
                },
                sizeMedium: {
                    padding: 10,
                },
            },
        },
        MuiTouchRipple: {
            styleOverrides: {
                root: {
                    opacity: 0.5,
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    '&:hover': {
                        backgroundColor: '#e0e0e0',
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                container: {
                    marginTop: '0 !important',
                    marginBottom: '0 !important',
                },
                item: {
                    paddingTop: '0 !important',
                    paddingBottom: '0 !important',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 0,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: 300,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    fontWeight: 300,
                },
                displayedRows: {
                    fontWeight: 300,
                },
            },
        },
    },
};

const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: darkBlue,
        secondary: darkBlue,
        success: darkBlue,
        error: red,
        background: {
            default: '#f7f7f7',
        },
    },
    typography: {
        fontWeightRegular: 300,
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    color: darkBlue[500],
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    },
                },
                button: {
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    },
                },
            },
        },
    },
};

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: white,
        secondary: white,
        error: red,
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#fff',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontWeight: 400,
                    WebkitFontSmoothing: 'antialiased',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    WebkitFontSmoothing: 'antialiased',
                },
                shrink: {
                    fontWeight: 600,
                    WebkitFontSmoothing: 'auto',
                },
            },
        },
    },
};

export const lightTheme = createTheme(deepmerge(baseThemeOptions, lightThemeOptions));
export const darkTheme = createTheme(deepmerge(baseThemeOptions, darkThemeOptions));
