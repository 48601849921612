import React, { Component } from 'react';
import { sprintf } from 'sprintf-js';

import { FormField } from '../../../components/formFields/FormField';
import { formatPrice } from '../../../functions/formatPrice';
import { convertToNumber } from '../../../functions/convertToNumber';
import NumberField, { NumberFieldProps } from '../../../components/formFields/NumberField';

interface Props {
    id: string;
    endConsumerPrice: number;
    endConsumerDiscount: number;
    quotationCurrency: string;
    recommendedPrice: number;
    disabled: boolean;
    onChangeEndConsumerPrice: (value: number) => void;
    onChangeEndConsumerDiscount: (value: number) => void;
}

export class EndConsumerPrice extends Component<Props> {

    render() {
        const endConsumerPrice = convertToNumber(this.props.endConsumerPrice, true);
        const endConsumerDiscount = convertToNumber(this.props.endConsumerDiscount, true);
        const recommendedPrice = convertToNumber(this.props.recommendedPrice, true);

        const discountPrice = endConsumerPrice * ((100 - endConsumerDiscount) / 100);
        const currency = this.props.quotationCurrency;

        return (
            <div className="c--customer-price">
                <div className="row-wrapper">
                    <FormField<NumberFieldProps>
                        component={NumberField}
                        type="text"
                        id={sprintf('customer-price-%s', this.props.id)}
                        value={endConsumerPrice || ''}
                        onChange={this.props.onChangeEndConsumerPrice}
                        disabled={this.props.disabled}
                        lineThrough={!!endConsumerDiscount}
                        textAlign="right"
                        size="small"
                        formatValueOnBlur={(value) => value ? formatPrice(value) : ''}
                    />
                    <span className="right-content">{currency}</span>
                </div>
                {!!recommendedPrice && endConsumerPrice !== recommendedPrice && (
                    <div className="row-wrapper">
                        <div><s className="allow-overflow">{formatPrice(recommendedPrice)}</s></div>
                        <div className="right-content"><span>{currency}</span></div>
                    </div>
                )}
                <div className="row-wrapper">
                    <FormField<NumberFieldProps>
                        component={NumberField}
                        type="text"
                        id={sprintf('customer-discount-%s', this.props.id)}
                        value={endConsumerDiscount || ''}
                        onChange={this.props.onChangeEndConsumerDiscount}
                        disabled={this.props.disabled}
                        textAlign="right"
                        size="small"
                        max={100}
                    />
                    <span className="right-content">%</span>
                </div>
                {!!endConsumerDiscount && (
                    <div className="row-wrapper">
                        <div><span className="allow-overflow">{formatPrice(discountPrice)}</span></div>
                        <div className="right-content"><span>{currency}</span></div>
                    </div>
                )}
            </div>
        );
    }
}
