import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Button from './components/formFields/Button';
import { ReduxState } from './store';
import { AppToast, AppToastConfig } from './reducer';
import * as appActions from './actions';

type ReduxProps = ConnectedProps<typeof connector>;

export const ERROR_TOAST_CONFIG: AppToastConfig = {
    severity: 'error',
    falseAction: 'Close',
    autohide: false,
};

export const SUCCESS_TOAST_CONFIG: AppToastConfig = {
    severity: 'success',
    autohide: true,
};

const mapStateToProps = (store: ReduxState) => ({
    toasts: store.app.toasts,
});

function Snackbar(props: ReduxProps): ReactElement {
    const [open, setOpen] = useState(false);
    const [activeToast, setActiveToast] = useState<AppToast>(null);

    useEffect(() => {
        if (props.toasts.length) {
            setActiveToast(props.toasts[0]);
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [props.toasts]);

    return (
        <MuiSnackbar
            key={activeToast?.id}
            open={open}
            autoHideDuration={activeToast?.config.autohide ? 4000 : null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => {
                props.dispatch(appActions.removeSnackbarItem(activeToast?.id, false));
            }}
            TransitionProps={{
                onExited: () => {
                    setActiveToast(null);
                },
            }}>
            <Alert
                variant="filled"
                severity={activeToast?.config.severity}
                elevation={6}
                action={
                    <Fragment>
                        {!!activeToast?.config.trueAction && (
                            <Button
                                color="light"
                                onClick={() => {
                                    props.dispatch(appActions.removeSnackbarItem(activeToast?.id, true));
                                }}>
                                {activeToast?.config.trueAction}
                            </Button>
                        )}
                        {!!activeToast?.config.falseAction && (
                            <Button
                                color="light"
                                onClick={() => {
                                    props.dispatch(appActions.removeSnackbarItem(activeToast?.id, false));
                                }}>
                                {activeToast?.config.falseAction}
                            </Button>
                        )}
                    </Fragment>
                }>
                {activeToast?.message}
            </Alert>
        </MuiSnackbar>
    );

}

const connector = connect(mapStateToProps);
export default connector(Snackbar);
