import React, { Fragment, MouseEvent, ReactElement, useRef, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Fade from '@mui/material/Fade';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';

import Button from '../../components/formFields/Button';
import { SpinnerProps } from '../../components/Spinner/Spinner';
import Tooltip, { TooltipProps } from './Tooltip';
import { NotAvailableIcon } from '../../components/NotAvailableIcon';

export interface SecondLevelProps {
    primaryText: string;
    secondaryText?: string[];
    to?: string;
    onClick?: () => void;
    selected?: boolean;
    key: string | number;
    tooltip?: TooltipProps;
    disabled?: boolean;
    actions?: {
        icon: string;
        label: string;
        spinner?: SpinnerProps;
        onClick: () => void;
        needsConfirm?: boolean;
    }[];
}

export default function SecondLevelListItem(props: SecondLevelProps): ReactElement {
    const match = useRouteMatch(props.to);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const confirmAction = useRef<() => void>();

    function cancelDelete(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        setShowConfirm(false);
    }

    function confirmDelete(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        setShowConfirm(false);
        if (confirmAction.current) {
            setPendingDelete(true);
            confirmAction.current();
        }
    }

    return (
        <Fragment>
            <Tooltip active={!!props.tooltip && !props.disabled} {...props.tooltip}>
                <div>
                    <ListItem
                        sx={{
                            paddingTop: '2px',
                            paddingBottom: '2px',
                            paddingLeft: '45px',
                        }}
                        button
                        to={props.to}
                        onClick={props.onClick}
                        selected={(!!props.to && !!match) || props.selected}
                        disabled={props.disabled}
                        component={props.to ? Link : 'div'}
                        ContainerComponent="div">
                        <Fade in={showConfirm} unmountOnExit>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    backgroundColor: '#fff',
                                    zIndex: 1,
                                }}>
                                <ButtonBase
                                    onClick={cancelDelete}
                                    sx={{
                                        fontFamily: (theme) => theme.typography.fontFamily,
                                        flex: 1,
                                        backgroundColor: 'rgba(0, 0, 48, 0.7)',
                                        borderRadius: 0,
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 48, 0.7)',
                                        },
                                    }}>
                                    Cancel
                                </ButtonBase>
                                <ButtonBase
                                    onClick={confirmDelete}
                                    sx={{
                                        fontFamily: (theme) => theme.typography.fontFamily,
                                        flex: 1,
                                        backgroundColor: '#000030',
                                        borderRadius: 0,
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#000030',
                                        },
                                    }}>
                                    Delete
                                </ButtonBase>
                            </Box>
                        </Fade>
                        <ListItemText
                            primary={props.primaryText}
                            secondary={props.secondaryText?.map((text, index) => <span key={index}>{text}</span>)}
                            primaryTypographyProps={{
                                sx: {
                                    fontWeight: 300,
                                    fontSize: '14px',
                                    color: '#000',
                                },
                            }}
                            secondaryTypographyProps={{
                                sx: {
                                    fontWeight: 300,
                                    fontSize: '12px',
                                    color: '#8b8b8b',
                                    textTransform: 'uppercase',
                                    '& span': {
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    },
                                },
                            }}
                        />
                        {props.actions && (
                            <ListItemSecondaryAction>
                                {props.disabled ? (
                                    <NotAvailableIcon />
                                ) : (
                                    <Button
                                        icon="more_vert"
                                        spinner={{ spinning: pendingDelete }}
                                        disabled={props.disabled}
                                        onClick={() => {
                                            setShowActions((prevShowActions) => !prevShowActions);
                                        }}
                                    />
                                )}
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                </div>
            </Tooltip>
            <Collapse
                in={showActions}
                timeout="auto"
                unmountOnExit
                sx={{
                    backgroundColor: 'rgba(228, 228, 228, 0.5)',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                }}>
                <List component="div" disablePadding>
                    {props.actions?.map((action, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                paddingLeft: '30px',
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}>
                            <Box
                                component={Button}
                                size="small"
                                icon={action.icon}
                                spinner={action.spinner}
                                onClick={() => {
                                    if (action.needsConfirm) {
                                        confirmAction.current = action.onClick;
                                        setShowConfirm(true);
                                    } else {
                                        action.onClick();
                                    }
                                }}>
                                {action.label}
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </Fragment>
    );

}
