import React, { MouseEvent, ReactElement } from 'react';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import MuiButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import isString from 'lodash/isString';
import clsx from 'clsx';

import { SpinnerProps } from '../Spinner/Spinner';

export interface ButtonProps {
    id?: string;
    variant?: 'text' | 'contained';
    size?: 'small' | 'medium' | 'large';
    type?: 'button' | 'submit' | 'reset';
    color?: 'primary' | 'light' | 'dark';
    icon?: string | ReactElement;
    className?: string;
    disabled?: boolean;
    spinner?: SpinnerProps;
    children?: string;
    onClick?: (event: MouseEvent) => void;
    tooltipLabel?: string;
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start';
    uppercase?: boolean;
}

interface ButtonIconProps {
    spinner: SpinnerProps;
    children: string | ReactElement;
    className?: string;
}

Button.defaultProps = {
    variant: 'text',
    size: 'medium',
    spinner: {},
    color: 'primary',
    type: 'button',
    disabled: false,
    tooltipLabel: '',
    uppercase: true,
} as ButtonProps;

export default function Button(props: ButtonProps) {

    const {
        children,
        icon,
        color,
        size,
        variant,
        type,
        disabled,
        spinner,
        className,
        onClick,
        id,
        tooltipLabel,
        tooltipPosition,
        uppercase,
    } = props;

    const textStyles = {
        light: { color: '#fff' },
        dark: { color: '#000' },
    };

    const containedStyles = {
        light: {
            backgroundColor: '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#f5f5f7',
                color: '#000',
            },
        },
    };

    if (!!icon && !children) {
        return (
            <Tooltip
                title={tooltipLabel}
                placement={tooltipPosition}
                open={tooltipLabel ? undefined : false}>
                <IconButton
                    id={id}
                    sx={{
                        ...textStyles[color],
                        textTransform: uppercase ? 'uppercase' : 'none',
                    }}
                    className={className}
                    color="primary"
                    type={type}
                    disabled={spinner.spinning || disabled}
                    onClick={onClick}>
                    <ButtonIcon spinner={spinner}>{icon}</ButtonIcon>
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <MuiButton
            id={id}
            sx={{
                ...textStyles[color],
                textTransform: uppercase ? 'uppercase' : 'none',
                '&.MuiButton-contained': containedStyles[color],
            }}
            className={className}
            variant={variant}
            disableElevation
            color="primary"
            size={size}
            startIcon={icon && (
                <Box
                    sx={{
                        height: size === 'small' ? '18px' : '20px',
                        width: size === 'small' ? '18px' : '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    component={ButtonIcon}
                    spinner={spinner}>
                    {icon}
                </Box>
            )}
            type={type}
            disabled={spinner.spinning || disabled}
            onClick={onClick}>
            {children}
        </MuiButton>
    );

}

export function ButtonIcon(props: ButtonIconProps) {
    if (props.spinner.error) {
        return (
            <Tooltip title={props.spinner.errorMessage || ''} placement="top">
                <Icon className={clsx('error', props.className)}>error</Icon>
            </Tooltip>
        );
    }
    if (props.spinner.spinning) {
        return <CircularProgress size={14} thickness={5} className={props.className} />;
    }
    if (isString(props.children)) {
        return <Icon className={props.className}>{props.children}</Icon>;
    }
    return props.children || null;
}
