import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { backendService, ErrorData } from '../../../../services/backendService';
import { Country } from '../../../../services/backendTypes';
import { AppThunkAction } from '../../../../store';

export const openCustomerDialog = createAction('OPEN_CUSTOMER_DIALOG');

export const closeCustomerDialog = createAction('CLOSE_CUSTOMER_DIALOG');

export function getCountries(): AppThunkAction {
    return (dispatch) => {
        dispatch(setFetchCountriesPending());

        return backendService.getCountries().then(
            (response) => {
                dispatch(setFetchCountriesComplete(response));
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setFetchCountriesRejected(error?.response?.data?.message));
            },
        );
    };
}

export const setFetchCountriesPending = createAction('FETCH_COUNTRIES_PENDING');

export const setFetchCountriesComplete = createAction<Country[]>('FETCH_COUNTRIES_COMPLETE');

export const setFetchCountriesRejected = createAction<string>('FETCH_COUNTRIES_REJECTED');
