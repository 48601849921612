import { errorMessages } from '../constants/errorMessages';
import { SizeFieldValue } from '../components/formFields/SizeField';
import { Validator } from '../services/formService';
import { safeDivide } from '../functions/math';

export const splitBaseSizeValidator = (isSplitBase = false): Validator => ({
    validate: (value: SizeFieldValue) => {
        if (isSplitBase === false) {
            return null;
        }

        if (Number.isInteger(safeDivide(value?.width, 10)) === true && Number.isInteger(safeDivide(value?.length, 10)) === true) {
            return null;
        }

        return { errorMessage: errorMessages.halfCentimeterNotAllowedWithSplitBase };
    },
});
