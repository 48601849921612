import { createReducer } from '@reduxjs/toolkit';
import { SpinnerProps } from '../../../../components/Spinner/Spinner';
import { Country } from '../../../../services/backendTypes';
import * as customerDialogActions from './actions';

interface CustomerDialogReduxState {
    visible: boolean;
    countries: Country[];
    countriesSpinner: SpinnerProps;
}

const initialState = {
    visible: false,
    countries: [],
    countriesSpinner: {},
} as CustomerDialogReduxState;

export const customerDialog = createReducer(initialState, (builder) => {
    builder.addCase(customerDialogActions.openCustomerDialog, (state) => {
        state.visible = true;
    });
    builder.addCase(customerDialogActions.closeCustomerDialog, (state) => {
        state.visible = false;
    });
    builder.addCase(customerDialogActions.setFetchCountriesPending, (state) => {
        state.countriesSpinner = { spinning: true };
    });
    builder.addCase(customerDialogActions.setFetchCountriesComplete, (state, action) => {
        state.countriesSpinner = {};
        state.countries = action.payload;
    });
    builder.addCase(customerDialogActions.setFetchCountriesRejected, (state, action) => {
        state.countriesSpinner = { error: true, errorMessage: action.payload };
    });
});
