import React, { KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import { Spinner, SpinnerProps } from '../Spinner/Spinner';

export interface TextFieldProps {
    id?: string;
    className?: string;
    size?: 'small' | 'medium';
    label?: string;
    placeholder?: string;
    type?: 'text' | 'email' | 'password';
    disabled?: boolean;
    readOnly?: boolean;
    error?: boolean;
    errorText?: string;
    helpText?: string;
    spinner?: SpinnerProps;
    resetOnUnmount?: boolean;
    lineThrough?: boolean;
    value?: string;
    disableUnderline?: boolean;
    autoComplete?: string;
    onChange?: (value: string, event?) => void;
    onKeyPress?: (event: KeyboardEvent<HTMLDivElement>) => void;
}

TextField.defaultProps = {
    value: '',
    resetOnUnmount: true,
    disableUnderline: false,
    lineThrough: false,
    disabled: false,
    readOnly: false,
    error: false,
    type: 'text',
    size: 'medium',
} as TextFieldProps;

export default function TextField(props: TextFieldProps): ReactElement {
    const [focused, setFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        return () => {
            if (props.onChange && props.resetOnUnmount) {
                props.onChange('');
            }
        };
    }, []);

    function handleClickShowPassword() {
        setShowPassword((prevValue) => !prevValue);
    }

    function handleMouseDownPassword(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
    }

    return (
        <MuiTextField
            variant="standard"
            fullWidth
            size={props.size}
            id={props.id}
            className={props.className}
            type={showPassword ? 'text' : props.type}
            disabled={props.disabled}
            label={props.label}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(event) => {
                props.onChange(event.target.value, event);
            }}
            onFocus={() => {
                setFocused(true);
            }}
            onBlur={() => {
                setFocused(false);
            }}
            onKeyPress={props.onKeyPress}
            error={props.error}
            helperText={props.error ? props.errorText : props.helpText}
            inputProps={{
                sx: {
                    textDecoration: props.lineThrough && !focused ? 'line-through' : null,
                    fontSize: props.size === 'small' ? '14px' : null,
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                },
            }}
            InputProps={{
                autoComplete: props.autoComplete,
                readOnly: props.readOnly,
                disableUnderline: props.disableUnderline,
                sx: {
                    '&:hover:before': {
                        borderBottom: props.readOnly ? '1px solid rgba(0, 0, 0, 0.12) !important' : null,
                    },
                    '&:after': {
                        content: props.readOnly ? 'none' : null,
                    },
                },
                endAdornment: (() => {
                    if (props.spinner) {
                        return <InputAdornment position="end"><Spinner {...props.spinner} /></InputAdornment>;
                    } else if (props.type === 'password') {
                        return (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                                </IconButton>
                            </InputAdornment>
                        );
                    }
                    return null;
                })(),
            }}
        />
    );

}
