import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import { Notification } from './backendTypes';

interface UserStorage {
    hideEndConsumerPrices: boolean;
    hideWholesalePrices: boolean;
    notifications: number[];
    selectedCustomer: string;
}

class LocalStorageService {

    username: string = null;

    public setItem(key: keyof UserStorage, value) {
        if (key && this.username) {
            const userData = this.getUserData();
            const dataToSave = { ...userData, [key]: value };
            window.localStorage.setItem(this.username, JSON.stringify(dataToSave));
        }
    }

    private getUserData(): UserStorage {
        const item = window.localStorage.getItem(this.username);
        if (item) {
            return JSON.parse(item);
        }
    }

    private removeItem(key: keyof UserStorage) {
        if (key) {
            window.localStorage.removeItem(key);
        }
    }

    public getItem<T extends keyof UserStorage>(key: T): UserStorage[T] {
        const userData = this.getUserData();
        if (isObject(userData)) {
            return userData[key];
        }
    }

    public setUsername(username: string) {
        if (username) {
            this.username = username;
        }
    }

    public hideEndConsumerPrices(value: boolean) {
        if (isBoolean(value)) {
            this.setItem('hideEndConsumerPrices', value);
        }
    }

    public hideWholesalePrices(value: boolean) {
        if (isBoolean(value)) {
            this.setItem('hideWholesalePrices', value);
        }
    }

    public notificationsRead(notifications: Notification[]) {
        if (notifications && Array.isArray(notifications)) {
            this.setItem('notifications', notifications.map((item) => item.id));
        }
    }

    public emptyNotifications() {
        this.setItem('notifications', []);
    }
}

export const localStorageService = new LocalStorageService();
