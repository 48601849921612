import React, { useState } from 'react';
import concat from 'lodash/concat';

import { AccessoryCategory, AccessoryCategorySetting, BedOption, BedPropertyChoiceData, BedPropertyConfig, Quote, QuoteAccessoryItem, QuoteBedItem, QuoteItemParameterDetails, SizeOptions } from '../../../services/backendTypes';
import Button from '../../../components/formFields/Button';
import SlideAndFade from '../../../components/animations/SlideAndFade';
import { AuthBedProperties } from '../../../layouts/reducers';

interface Props {
    quote: Quote;
    bedTypeOptions: BedOption[];
    bedPropertyConfigs: AuthBedProperties;
    accessoryPropertyConfigs: AccessoryCategory[];
}

/**
 * Warn user about selected options that have been removed from the database.
 *
 * Known problems:
 * - If a size field has custom size turned on, we don't currently have any way of determining if the user selected a removed size option or if the user filled in a custom size.
 * - Products of type material by key always display error, due to not being a configuration available in Coper.
 */
export default function MissingOptionsAlert(props: Props) {
    const [closed, setClosed] = useState(false);

    const missingOptions = [];
    const bedItems = props.quote.items.filter((item) => item.itemType === 'bed') as QuoteBedItem[];
    const accessoryItems = props.quote.items.filter((item) => item.itemType === 'accessory') as QuoteAccessoryItem[];

    /**
     * Check if selected bed options are still available.
     */
    for (const bedItem of bedItems) {
        const selectedTypeOption = props.bedTypeOptions.find((option) => option.code === bedItem.type);

        if (!selectedTypeOption || !props.bedPropertyConfigs[bedItem.type]?.data) {
            missingOptions.push(bedItem.name);
            continue;
        }

        const missingBedOptions = evaluateBedItem(
            bedItem,
            props.bedPropertyConfigs[bedItem.type].data,
            props.bedPropertyConfigs[bedItem.type].sizeOptions,
            props.bedPropertyConfigs[bedItem.type].allowCustomSize,
        );

        if (missingBedOptions.length) {
            missingOptions.push(`${bedItem.name}: ${missingBedOptions.join(', ')}`);
        }
    }

    /**
     * Check if selected accessory options are still available.
     */
    for (const accessoryItem of accessoryItems) {
        const selectedLevel1Category = props.accessoryPropertyConfigs.find((config) => config.name === accessoryItem.level1);

        if (!selectedLevel1Category && missingOptions.indexOf(accessoryItem.level1) === -1) {
            missingOptions.push(accessoryItem.level1);
            continue;
        }

        const missingAccessoryOptions = evaluateAccessoryItem(
            accessoryItem,
            selectedLevel1Category,
        );

        if (missingAccessoryOptions.length) {
            missingOptions.push(`${selectedLevel1Category.name}: ${missingAccessoryOptions.join(', ')}`);
        }
    }

    return (
        <SlideAndFade in={!!props.quote.id && !!missingOptions.length && !closed}>
            <div className="c--missing-values-alert">
                <div className="alert-box">
                    <Button
                        icon="close"
                        color="light"
                        className="close-action"
                        onClick={() => {
                            setClosed(true);
                        }}
                    />
                    <p>The following has been removed from the order form and will not be available to purchase anymore.</p>
                    <ul>
                        {missingOptions.map((value, index) => (
                            <li key={index}>{value}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </SlideAndFade>
    );
}

function evaluateBedItem(bedItem: QuoteBedItem, bedPropertyConfig: BedPropertyConfig[], sizeOptions: SizeOptions, allowCustomSize: boolean): string[] {
    const missingBedOptions: string[] = [];

    for (const parametersDetailsItem of bedItem.parametersDetails) {
        const config = bedPropertyConfig.find((item) => item.code === parametersDetailsItem.key);

        const isMissing = evaluateBedItemProperty(
            config,
            parametersDetailsItem,
            sizeOptions,
            allowCustomSize,
        );

        if (isMissing) {
            missingBedOptions.push(parametersDetailsItem.valueLabel);
        }
    }
    return missingBedOptions;
}

function evaluateAccessoryItem(accessoryItem: QuoteAccessoryItem, selectedLevel1Category: AccessoryCategory): string[] {
    const missingAccessoryOptions: string[] = [];

    (function recurseCategoryTree(accessoryCategory: AccessoryCategory) {
        if (accessoryCategory.children) {
            const selectedChild = accessoryCategory.children.find((child) => child.name === accessoryItem[accessoryCategory.key]);

            if (selectedChild) {
                recurseCategoryTree(selectedChild);
            } else {
                missingAccessoryOptions.push(accessoryItem[accessoryCategory.key]);
            }
        } else if (accessoryCategory.settings) {
            for (const parametersDetailsItem of accessoryItem.parametersDetails) {
                const setting = accessoryCategory.settings.find((item) => item.code === parametersDetailsItem.key);

                const isMissing = evaluateAccessoryItemProperty(
                    setting,
                    parametersDetailsItem,
                );

                if (isMissing) {
                    missingAccessoryOptions.push(parametersDetailsItem.valueLabel);
                }
            }
        }
    })(selectedLevel1Category);

    return missingAccessoryOptions;
}

function evaluateBedItemProperty(config: BedPropertyConfig, parametersDetailsItem: QuoteItemParameterDetails, sizeOptions: SizeOptions, allowCustomSize: boolean): boolean {
    switch (config?.dataType) {
        case 'choice': {
            const choiceData = config.data as BedPropertyChoiceData;
            const selectedOption = choiceData.options.find((option) => option.code === parametersDetailsItem.value);

            return !selectedOption;
        }
        case 'measurement': {
            if (allowCustomSize) {
                return false;
            }
            const allSizeOptions = concat([], ...Object.values(sizeOptions));
            const selectedSize = allSizeOptions.find((option) => option.displayName === parametersDetailsItem.valueLabel);

            return !selectedSize;
        }
        default: {
            return false;
        }
    }
}

function evaluateAccessoryItemProperty(setting: AccessoryCategorySetting, parametersDetailsItem: QuoteItemParameterDetails): boolean {
    switch (setting?.type) {
        case 'dropdown': {
            const options = setting.data.options;
            const selectedOption = options?.find((option) => option.code === parametersDetailsItem.value);

            return !selectedOption;
        }
        case 'size': {
            if (setting.allowCustomSize) {
                return false;
            }
            const allStandardSizes = concat([], ...Object.values(setting.standardSizes));
            const selectedSize = allStandardSizes.find((size) => size.displayName === parametersDetailsItem.valueLabel);

            return !selectedSize;
        }
        case 'string_size': {
            if (setting.allowCustomSize) {
                return false;
            }
            const options = setting.data.options;
            const selectedOption = options?.find((option) => option.code === parametersDetailsItem.value);

            return !selectedOption;
        }
        default: {
            return false;
        }
    }
}
