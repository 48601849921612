import React, { FocusEvent, ReactElement, useState } from 'react';
import MuiTextField from '@mui/material/TextField';

export interface AutosizeTextFieldProps {
    id?: string;
    label?: string;
    value: string;
    type?: string;
    onChange: (value: string) => void;
    onBlur?: (event: FocusEvent) => void;
    onFocus?: (event: FocusEvent) => void;
    error?: boolean;
    errorText?: string;
    helpText?: string;
    placeholder?: string;
    centered?: boolean;
    color?: string;
    disabled?: boolean;
    backgroundStriped?: boolean;
    maxRows?: number;
}

AutosizeTextField.defaultProps = {
    value: '',
    backgroundStriped: false,
};

export default function AutosizeTextField(props: AutosizeTextFieldProps): ReactElement {
    const [focused, setFocused] = useState(false);
    const helpText = focused ? props.helpText : '';
    const isStriped = props.backgroundStriped && (focused || props.value.length);

    return (
        <MuiTextField
            variant="standard"
            fullWidth
            multiline
            minRows={focused ? 2 : 1}
            maxRows={props.maxRows}
            id={props.id}
            disabled={props.disabled}
            type={props.type}
            label={props.label}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(event) => {
                props.onChange(event.target.value);
            }}
            onFocus={(event) => {
                setFocused(true);
                if (props.onFocus) {
                    props.onFocus(event);
                }
            }}
            onBlur={(event) => {
                setFocused(false);
                if (props.onBlur) {
                    props.onBlur(event);
                }
            }}
            error={props.error}
            helperText={props.error ? props.errorText : helpText}
            inputProps={{
                sx: {
                    textAlign: props.centered ? 'center' : null,
                    background: isStriped ? `linear-gradient(-45deg, #f7f7f7 25%, transparent 25%, transparent 50%, #f7f7f7 50%, #f7f7f7 75%, transparent 75%, transparent)` : null,
                    backgroundSize: isStriped ? '6px 6px' : null,
                },
            }}
        />
    );

}
