import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Button from './formFields/Button';

interface Props {
    open: boolean;
    onClose: () => void;
    children: string;
    severity?: 'info' | 'error';
    actions: {
        onClick: () => void;
        children: string;
    }[];
}

MessageDialog.defaultProps = {
    open: false,
    severity: 'info',
    actions: [],
} as Props;

export function MessageDialog(props: Props) {
    return (
        <Dialog
            PaperProps={{
                sx: {
                    backgroundColor: () => {
                        switch (props.severity) {
                            case 'error': {
                                return '#ff5752';
                            }
                            default: {
                                return '#000030';
                            }
                        }
                    },
                    width: '500px',
                },
            }}
            open={props.open}
            onClose={props.onClose}>
            <DialogContent
                sx={{
                    color: '#fff',
                    fontWeight: 500,
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: '24px !important',
                    WebkitFontSmoothing: 'antialiased',
                }}>
                {props.children}
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    padding: '14px 24px 24px',
                }}>
                {props.actions.map((action, index) => (
                    <Button
                        variant="contained"
                        color="light"
                        key={index}
                        onClick={action.onClick}>
                        {action.children}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );

}
