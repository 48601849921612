import { createReducer } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import isUndefined from 'lodash/isUndefined';

import { can } from '../../components/Can/Can';
import { localStorageService } from '../../services/localStorageService';
import { SpinnerProps } from '../../components/Spinner/Spinner';
import { AccessoryCategory, AuthCustomer, AuthPermission, BedOption, BedPropertyConfig, DeliveryAddress, ListQuote, Quote, SizeOptions } from '../../services/backendTypes';
import * as authActions from './actions';

export interface AuthReduxState {
    sidebarVisible: boolean;
    beds: {
        data: BedOption[];
        spinner: SpinnerProps;
    };
    accessories: AccessoryCategory[];
    bedProperties: AuthBedProperties;
    quotes: {
        data: ListQuote[];
        spinner: SpinnerProps;
    };
    onlineQuotes: {
        data: ListQuote[];
        spinner: SpinnerProps;
    };
    orders: {
        data: ListQuote[];
        spinner: SpinnerProps;
    };
    lazyQuotes: {
        [quoteId: string]: {
            data: Quote;
            spinner: SpinnerProps;
        }
    };
    lazyOrders: {
        [quoteId: string]: {
            data: Quote;
            spinner: SpinnerProps;
        }
    };
    deliveryAddresses: DeliveryAddress[];
    loginDialog: {
        visible: boolean;
    },
    spinners: {
        customerQuotation: SpinnerProps;
        deliveryConfirmation: SpinnerProps;
        accessories: SpinnerProps;
    },
    currentCustomer: AuthCustomer;
    hideEndConsumerPrices: boolean;
    hideWholesalePrices: boolean;
}

export interface AuthBedProperties {
    [bedCode: string]: AuthBedProperty;
}

export interface AuthBedProperty {
    spinner: SpinnerProps;
    data: BedPropertyConfig[];
    sizeOptions: SizeOptions;
    allowCustomSize: boolean;
}

const initialState = {
    sidebarVisible: false,
    beds: {
        data: [],
        spinner: {},
    },
    accessories: [],
    bedProperties: {},
    quotes: {},
    onlineQuotes: {},
    orders: {},
    lazyQuotes: {},
    lazyOrders: {},
    loginDialog: {
        visible: false,
    },
    spinners: {
        customerQuotation: {},
        deliveryConfirmation: {},
        accessories: {},
    },
    currentCustomer: {},
    hideEndConsumerPrices: true,
    hideWholesalePrices: false,
} as AuthReduxState;


export const auth = createReducer(initialState, (builder) => {
    builder.addCase(authActions.openSidebar, (state) => {
        state.sidebarVisible = true;
    });
    builder.addCase(authActions.closeSidebar, (state) => {
        state.sidebarVisible = false;
    });
    builder.addCase(authActions.openLoginDialog, (state) => {
        state.loginDialog.visible = true;
    });
    builder.addCase(authActions.closeLoginDialog, (state) => {
        state.loginDialog.visible = false;
    });
    builder.addCase(authActions.setCurrentCustomer, (state, action) => {
        const hideEndConsumerPrices = localStorageService.getItem('hideEndConsumerPrices');
        const hideWholesalePrices = localStorageService.getItem('hideWholesalePrices');

        if (!can(action.payload, AuthPermission.CAN_SEE_WHOLESALE_PRICE)) {
            state.hideEndConsumerPrices = false;
            state.hideWholesalePrices = true;
        } else if (!isUndefined(hideEndConsumerPrices) && !isUndefined(hideWholesalePrices)) {
            state.hideEndConsumerPrices = !!hideEndConsumerPrices;
            state.hideWholesalePrices = !!hideWholesalePrices;
        } else {
            state.hideEndConsumerPrices = initialState.hideEndConsumerPrices;
            state.hideWholesalePrices = initialState.hideWholesalePrices;
            localStorageService.hideEndConsumerPrices(state.hideEndConsumerPrices);
            localStorageService.hideWholesalePrices(state.hideWholesalePrices);
        }
        state.currentCustomer = action.payload;
        localStorageService.setItem('selectedCustomer', action.payload.key);
    });
    builder.addCase(authActions.setLoadSidebarQuotesPending, (state) => {
        state.quotes.spinner = { spinning: true };
        state.onlineQuotes.spinner = { spinning: true };
    });
    builder.addCase(authActions.setLoadSidebarQuotesComplete, (state, action) => {
        state.quotes = {
            spinner: null,
            data: action.payload.quoteData,
        };
        state.onlineQuotes = {
            spinner: null,
            data: action.payload.onlineQuoteData,
        };
        state.lazyQuotes = {};
    });
    builder.addCase(authActions.setLoadSidebarQuotesRejected, (state, action) => {
        state.quotes.spinner = { error: true, errorMessage: action.payload };
        state.onlineQuotes.spinner = { error: true, errorMessage: action.payload };
    });
    builder.addCase(authActions.setLazyloadSidebarQuotePending, (state, action) => {
        state.lazyQuotes[action.payload.quoteId] = {
            spinner: { spinning: true },
            data: null,
        };
    });
    builder.addCase(authActions.setLazyloadSidebarQuoteComplete, (state, action) => {
        state.lazyQuotes[action.payload.quoteId] = {
            spinner: null,
            data: action.payload.data,
        };
    });
    builder.addCase(authActions.setLazyloadSidebarQuoteRejected, (state, action) => {
        state.lazyQuotes[action.payload.quoteId] = {
            spinner: { error: true, errorMessage: action.payload.errorMessage },
            data: null,
        };
    });
    builder.addCase(authActions.setLoadSidebarOrdersPending, (state) => {
        state.orders.spinner = { spinning: true };
    });
    builder.addCase(authActions.setLoadSidebarOrdersComplete, (state, action) => {
        state.orders = {
            spinner: null,
            data: action.payload,
        };
        state.lazyOrders = {};
    });
    builder.addCase(authActions.setLoadSidebarOrdersRejected, (state, action) => {
        state.orders.spinner = { error: true, errorMessage: action.payload };
    });
    builder.addCase(authActions.setLazyloadSidebarOrderPending, (state, action) => {
        state.lazyOrders[action.payload.quoteId] = {
            spinner: { spinning: true },
            data: null,
        };
    });
    builder.addCase(authActions.setLazyloadSidebarOrderComplete, (state, action) => {
        state.lazyOrders[action.payload.quoteId] = {
            spinner: null,
            data: action.payload.data,
        };
    });
    builder.addCase(authActions.setLazyloadSidebarOrderRejected, (state, action) => {
        state.lazyOrders[action.payload.quoteId] = {
            spinner: { error: true, errorMessage: action.payload.errorMessage },
            data: null,
        };
    });
    builder.addCase(authActions.setDeleteQuoteComplete, (state, action) => {
        let index = state.quotes.data.findIndex((quote) => quote.id === action.payload);

        if (index !== -1) {
            state.quotes.data.splice(index, 1);
        } else {
            index = state.onlineQuotes.data.findIndex((quote) => quote.id === action.payload);

            if (index !== -1) {
                state.onlineQuotes.data.splice(index, 1);
            }
        }
    });
    builder.addCase(authActions.setLoadBedsPending, (state) => {
        state.beds = {
            data: [],
            spinner: { spinning: true },
        };
    });
    builder.addCase(authActions.setLoadBedsComplete, (state, action) => {
        state.beds = {
            data: orderBy(action.payload, [(item) => item.displayName.toLowerCase()]),
            spinner: {},
        };
    });
    builder.addCase(authActions.setLoadBedsRejected, (state, action) => {
        state.beds = {
            data: [],
            spinner: { error: true, errorMessage: action.payload },
        };
    });
    builder.addCase(authActions.setLoadAccessoriesPending, (state) => {
        state.spinners.accessories = { spinning: true };
        state.accessories = [];
    });
    builder.addCase(authActions.setLoadAccessoriesComplete, (state, action) => {
        state.spinners.accessories = {};
        state.accessories = action.payload;
    });
    builder.addCase(authActions.setLoadAccessoriesRejected, (state) => {
        state.spinners.accessories = { error: true };
        state.accessories = [];
    });
    builder.addCase(authActions.setLoadBedPropertiesPending, (state, action) => {
        state.bedProperties[action.payload.bedCode] = {
            spinner: { spinning: true },
            data: null,
            sizeOptions: null,
            allowCustomSize: null,
        };
    });
    builder.addCase(authActions.setLoadBedPropertiesComplete, (state, action) => {
        state.bedProperties[action.payload.bedCode] = {
            spinner: {},
            data: action.payload.data.properties,
            sizeOptions: action.payload.data.sizeOptions,
            allowCustomSize: action.payload.data.allowCustomSize,
        };
    });
    builder.addCase(authActions.setLoadBedPropertiesRejected, (state, action) => {
        state.bedProperties[action.payload.bedCode] = {
            spinner: { error: true, errorMessage: action.payload.errorMessage },
            data: null,
            sizeOptions: null,
            allowCustomSize: null,
        };
    });
    builder.addCase(authActions.setLoadCustomerDeliveryAddressesComplete, (state, action) => {
        state.deliveryAddresses = action.payload;
    });
    builder.addCase(authActions.hideEndConsumerPrices, (state, action) => {
        localStorageService.hideEndConsumerPrices(action.payload);
        state.hideEndConsumerPrices = action.payload;
    });
    builder.addCase(authActions.hideWholesalePrices, (state, action) => {
        localStorageService.hideWholesalePrices(action.payload);
        state.hideWholesalePrices = action.payload;
    });
    builder.addCase(authActions.setLogoutComplete, (state) => {
        state.currentCustomer = {} as AuthCustomer;
    });
});
