export const defaultErrorMessage = 'An error occurred';

export const errorMessages = {
    required: 'This field is required',
    size: 'Width %s-%s, length %s-%s',
    halfCentimeterNotAllowedWithSplitBase: 'Split base is not allowed on 0,5cm beds',
    sizeSingle: 'Min %s, max %s',
    minNumber: 'Must be greater than %s',
    price: 'Price can\'t be found, please contact customer service',
    maxLines: '%s lines maximum',
    maxCharacters: 'Exceeding maximum characters',
    maxCharactersPerLine: 'Exceeding maximum characters per line',
    email: 'Invalid email',
    disabled: 'Option is no longer available',
    sizeExists: 'Size is not available',
    invalidCharacters: 'Invalid characters',
    exactNumber: 'Must be %s numbers',
    notAllowed: 'This item is unavailable due to legal reasons', // blockStatus 96
    discontinued: 'This item is discontinued and no longer a part of our assortment', // blockStatus 97
};
