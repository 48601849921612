import React, { PropsWithChildren } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

interface Props {
    clickHandler: () => void;
}

export function CustomMenuItem(props: PropsWithChildren<Props>) {
    return (
        <MenuItem
            sx={{
                padding: 0,
                display: 'block',
                height: 'auto',
            }}>
            <Box
                component="span"
                sx={{
                    padding: '8px 16px',
                    height: '24px',
                    boxSizing: 'content-box',
                    display: 'flex',
                    alignItems: 'center',
                }}
                onClick={props.clickHandler}>
                {props.children}
            </Box>
        </MenuItem>
    );
}
