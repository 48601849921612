import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import {
    AccessoryCategory,
    QuoteItem,
    QuoteBuilderResponseItem,
    QuoteRequestData,
    RequestDataQuoteItem,
    RequestDataAccessoryConfiguration,
    RequestDataBedConfiguration,
    TemplateRequestData,
    TemplateRequestDataItem,
} from '../../services/backendTypes';
import { OrderFormAccessoryItem, OrderFormBedItem, OrderFormData, OrderFormItem, SimplifiedQuoteBuilderResponseItem } from './reducer';
import { mergeCurrencies } from '../../functions/mergeCurrencies';
import { safeMultiply } from '../../functions/math';

export function removeInvalidData(data: OrderFormData, accessories: AccessoryCategory[]): OrderFormData {
    const copy = cloneDeep(data);
    const validItems = [];

    for (const item of copy.items) {
        if (item.itemType === 'bed') {
            validItems.push(item);
        } else if (item.itemType === 'accessory') {
            const categoryFound = !!accessories.find((accessoryCategory) => accessoryCategory.name === item.level1);
            if (categoryFound) {
                validItems.push(item);
            }
        }
    }
    copy.items = validItems;
    return copy;
}

export function groupAccessoryItemsByCategory(items: OrderFormItem[] | QuoteItem[]): { [categoryName: string]: OrderFormAccessoryItem[] } {
    const accessories = {};
    for (const item of items) {
        if (item.itemType !== 'accessory') {
            continue;
        }
        if (!accessories[item.level1]) {
            accessories[item.level1] = [];
        }
        accessories[item.level1].push(item);
    }
    return accessories;
}

export function simplifyQuoteBuilderResponseItem(quoteBuilderResponseItem: QuoteBuilderResponseItem): SimplifiedQuoteBuilderResponseItem {
    return {
        recommendedPrice: sumBy(quoteBuilderResponseItem.materials, (material) => {
            const materialTotal = safeMultiply(material.price.recommended, material.quantity);
            const itemTotal = safeMultiply(materialTotal, quoteBuilderResponseItem.quantity);
            return itemTotal;
        }),
        /**
         * Price after campaign excluding includedFree products
         */
        discountedPrice: sumBy(quoteBuilderResponseItem.materials, (material) => {
            if (material.valid === false) {
                return 0;
            }
            const materialTotal = safeMultiply(material.price.price, material.quantity);
            const itemTotal = safeMultiply(materialTotal, quoteBuilderResponseItem.quantity);
            return itemTotal;
        }),
        /**
         * Price before campaign excluding includedFree products
         */
        price: sumBy(quoteBuilderResponseItem.materials, (material) => {
            if (material.metaData.includedFree === true) {
                return 0;
            }
            const materialTotal = safeMultiply(material.price.full, material.quantity);
            const itemTotal = safeMultiply(materialTotal, quoteBuilderResponseItem.quantity);
            return itemTotal;
        }),
        colorSurchargeListPrice: sumBy(quoteBuilderResponseItem.materials, (material) => {
            const materialTotal = safeMultiply(material.surcharge.colorListPrice, material.quantity);
            const itemTotal = safeMultiply(materialTotal, quoteBuilderResponseItem.quantity);
            return itemTotal;
        }),
        legsIncludedWorth: sumBy(quoteBuilderResponseItem.materials, (material) => {
            if (material.metaData.materialName === 'Legs' && material.metaData.includedFree === true) {
                const materialTotal = safeMultiply(material.price.full, material.quantity);
                const itemTotal = safeMultiply(materialTotal, quoteBuilderResponseItem.quantity);
                return itemTotal;
            }
            return 0;
        }),
        currency: mergeCurrencies(quoteBuilderResponseItem.materials.map((material) => {
            return material.price.currency;
        })),
        quotationCurrency: mergeCurrencies(quoteBuilderResponseItem.materials.map((material) => {
            return material.price.quotationCurrency;
        })),
        valid: quoteBuilderResponseItem.materials.length ? quoteBuilderResponseItem.materials.every((material) => material.valid !== false) : false,
        appliedCampaigns: uniq(concat([], ...quoteBuilderResponseItem.materials.map((material) => material.appliedCampaigns || []))),
    };
}

export function prepareQuoteRequestData(orderFormData: OrderFormData): QuoteRequestData {
    const { campaignCode, customerNo, endConsumer, id, incrementId, idpCode, lastDayOfApproval, purchaseOrderNumber, referenceNumber, items, orderType } = orderFormData;

    return {
        campaignCode,
        customerNo,
        endConsumer,
        id,
        incrementId,
        idpCode,
        lastDayOfApproval,
        purchaseOrderNumber,
        referenceNumber,
        items: items.map((item) => {
            if (item.itemType === 'accessory') {
                return prepareRequestDataAccessoryItem(item);
            } else if (item.itemType === 'bed') {
                return prepareRequestDataBedItem(item);
            }
        }),
        orderType,
    };
}

export function prepareRequestDataAccessoryItem(accessory: OrderFormAccessoryItem): RequestDataQuoteItem<RequestDataAccessoryConfiguration> {
    const { _key, level1, level2, name, quantity, parameters, endConsumerPrice, endConsumerDiscount, quotationCurrency } = accessory;
    const { sizeNationKey, ...otherParameters } = parameters;

    return {
        id: _key,
        sizeNationKey,
        endConsumerPrice,
        endConsumerDiscount,
        quotationCurrency,
        configuration: {
            level1,
            level2,
            name,
            quantity,
            ...otherParameters,
        },
    };
}

export function prepareRequestDataBedItem(bed: OrderFormBedItem): RequestDataQuoteItem<RequestDataBedConfiguration> {
    const { _key, sizeNationKey, endConsumerPrice, endConsumerDiscount, quotationCurrency, bottom_list, color, firmness, left_nameplate, legs, length, middle_leg_link, nameplate, nameplate_type, right_nameplate, split_base, split_mattress, split_top_mattress, top_mattress_type, trim, type, vividus_legs, width, quantity } = bed; // eslint-disable-line camelcase

    return {
        id: _key,
        sizeNationKey,
        endConsumerPrice,
        endConsumerDiscount,
        quotationCurrency,
        configuration: {
            bottom_list, // eslint-disable-line camelcase
            color,
            firmness,
            left_nameplate, // eslint-disable-line camelcase
            legs,
            length,
            middle_leg_link, // eslint-disable-line camelcase
            nameplate,
            nameplate_type, // eslint-disable-line camelcase
            right_nameplate, // eslint-disable-line camelcase
            split_base, // eslint-disable-line camelcase
            split_mattress, // eslint-disable-line camelcase
            split_top_mattress, // eslint-disable-line camelcase
            top_mattress_type, // eslint-disable-line camelcase
            trim,
            type,
            vividus_legs, // eslint-disable-line camelcase
            width,
            quantity,
        },
    };
}

export function prepareTemplateRequestData(templateName: string, orderFormData: OrderFormData): TemplateRequestData {
    const { items } = orderFormData;

    return {
        templateName,
        items: items.map((item) => {
            if (item.itemType === 'accessory') {
                return prepareTemplateRequestDataAccessoryItem(item);
            } else if (item.itemType === 'bed') {
                return prepareTemplateRequestDataBedItem(item);
            }
        }),
    };
}

function prepareTemplateRequestDataAccessoryItem(accessory: OrderFormAccessoryItem): TemplateRequestDataItem<RequestDataAccessoryConfiguration> {
    const { level1, level2, name, quantity, parameters } = accessory;
    const otherParameters = omit(parameters, 'sizeNationKey');

    return {
        configuration: {
            level1,
            level2,
            name,
            quantity,
            ...otherParameters,
        },
    };
}

function prepareTemplateRequestDataBedItem(bed: OrderFormBedItem): TemplateRequestDataItem<RequestDataBedConfiguration> {
    const { bottom_list, color, firmness, left_nameplate, legs, length, middle_leg_link, nameplate, nameplate_type, right_nameplate, split_base, split_mattress, split_top_mattress, top_mattress_type, trim, type, vividus_legs, width, quantity } = bed; // eslint-disable-line camelcase

    return {
        configuration: {
            bottom_list, // eslint-disable-line camelcase
            color,
            firmness,
            left_nameplate, // eslint-disable-line camelcase
            legs,
            length,
            middle_leg_link, // eslint-disable-line camelcase
            nameplate,
            nameplate_type, // eslint-disable-line camelcase
            right_nameplate, // eslint-disable-line camelcase
            split_base, // eslint-disable-line camelcase
            split_mattress, // eslint-disable-line camelcase
            split_top_mattress, // eslint-disable-line camelcase
            top_mattress_type, // eslint-disable-line camelcase
            trim,
            type,
            vividus_legs, // eslint-disable-line camelcase
            width,
            quantity,
        },
    };
}
