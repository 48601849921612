import React, { Component, Fragment } from 'react';
import Grid from '@mui/material/Grid';

import { Spinner, SpinnerProps } from '../../../../components/Spinner/Spinner';
import { errorMessages } from '../../../../constants/errorMessages';
import { EndConsumerPrice } from '../EndConsumerPrice';
import { OrderFormBedItem, SimplifiedQuoteBuilderResponseItem } from '../../reducer';
import { formatPrice } from '../../../../functions/formatPrice';

export interface BedFooterProps {
    data: OrderFormBedItem;
    simplifiedQuoteBuilderResponseItem: SimplifiedQuoteBuilderResponseItem;
    quoteBuilderResponseSpinner: SpinnerProps;
    disabled: boolean;
    openingOrder: boolean;
    onChangeEndConsumerPrice: (value: number) => void;
    onChangeEndConsumerDiscount: (value: number) => void;
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
    onDebugClick: () => void;
}

export class BedFooter extends Component<BedFooterProps> {

    static defaultProps = {
        simplifiedQuoteBuilderResponseItem: {},
    };

    render() {
        const { price, discountedPrice, recommendedPrice, colorSurchargeListPrice, legsIncludedWorth, currency, appliedCampaigns, valid } = this.props.simplifiedQuoteBuilderResponseItem;
        const shouldDisplayColorSurcharge = colorSurchargeListPrice && valid && !this.props.quoteBuilderResponseSpinner?.spinning;
        const shouldDisplayLegsIncludedWorth = legsIncludedWorth && valid && !this.props.quoteBuilderResponseSpinner?.spinning;

        return (
            <div className="c--bed-footer">
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item xs={12} md={4} style={{ alignSelf: 'flex-end' }}>
                        {(this.props.hideWholesalePrices === false) &&
                            (this.props.openingOrder === false) && (
                            <div className="campaign-matched">
                                <span>Campaign matched:</span>
                                <span>{appliedCampaigns?.length ? appliedCampaigns.join(', ') : '---'}</span>
                            </div>
                        )}
                        {(this.props.hideWholesalePrices === false) &&
                            (this.props.openingOrder === true) && (
                            <div className="campaign-matched">
                                <span>Opening order matched:</span>
                                <span>Discount 30%</span>
                            </div>
                        )}
                        {(this.props.hideWholesalePrices === false) && (
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Color surcharge (list price):</td>
                                        <td><span>{shouldDisplayColorSurcharge ? formatPrice(colorSurchargeListPrice, currency) : '---'}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Legs included worth:</td>
                                        <td><span>{shouldDisplayLegsIncludedWorth ? formatPrice(legsIncludedWorth, currency) : '---'}</span></td>
                                    </tr>
                                    <tr className="bottom-row">
                                        <td>Wholesale price:</td>
                                        <td>
                                            <Spinner
                                                {...this.props.quoteBuilderResponseSpinner}
                                                defaultElement={
                                                    <Fragment>
                                                        <span
                                                            className="new-price"
                                                            onClick={(event) => {
                                                                if (event.altKey) {
                                                                    this.props.onDebugClick();
                                                                }
                                                            }}>
                                                            {discountedPrice && valid ? formatPrice(discountedPrice, currency) : '---'}
                                                        </span>
                                                        {discountedPrice !== price && valid && (
                                                            <span className="old-price">
                                                                <s>{formatPrice(price, currency)}</s>
                                                            </span>
                                                        )}
                                                    </Fragment>
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        {(this.props.hideEndConsumerPrices === false) && (
                            <table>
                                <tbody>
                                    <tr className="bottom-row">
                                        <td>Price:</td>
                                        <td>
                                            <Spinner
                                                {...this.props.quoteBuilderResponseSpinner}
                                                defaultElement={
                                                    <EndConsumerPrice
                                                        id={this.props.data._key}
                                                        disabled={this.props.disabled || valid === false}
                                                        recommendedPrice={valid ? recommendedPrice: 0}
                                                        endConsumerPrice={valid ? this.props.data.endConsumerPrice : 0}
                                                        endConsumerDiscount={this.props.data.endConsumerDiscount}
                                                        quotationCurrency={valid ? this.props.data.quotationCurrency : undefined}
                                                        onChangeEndConsumerPrice={this.props.onChangeEndConsumerPrice}
                                                        onChangeEndConsumerDiscount={this.props.onChangeEndConsumerDiscount}
                                                    />
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </Grid>
                </Grid>
                {valid === false && (
                    <span className="error-text">{errorMessages.price}</span>
                )}
            </div>
        );
    }

}
