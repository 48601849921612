import React from 'react';
import uniqBy from 'lodash/uniqBy';

import { SummaryTable, SummaryTableDataItem } from './SummaryTable';
import { QuoteAccessoryItem } from '../../../../services/backendTypes';

interface Props {
    data: QuoteAccessoryItem[];
}

interface TableKey {
    title: string;
    key: string;
}

export class OrderAccessoryTable extends React.Component<Props> {

    extractFields(items: QuoteAccessoryItem[]) {
        const extractedFields: TableKey[] = [];
        for (const item of items) {
            if (item.level2) {
                extractedFields.push({
                    title: 'Category',
                    key: 'level2',
                });
            }
            if (item.name) {
                extractedFields.push({
                    title: 'Name',
                    key: 'name',
                });
            }
            for (const parameterDetails of item.parametersDetails) {
                extractedFields.push({
                    title: parameterDetails.keyLabel,
                    key: parameterDetails.key,
                });
            }
            extractedFields.push({
                title: 'Quantity',
                key: 'quantity',
            });
        }
        return uniqBy(extractedFields, 'key');
    }

    prepareItem(item: QuoteAccessoryItem, fields: TableKey[]) {
        const preparedItem: SummaryTableDataItem = {};
        for (const field of fields) {
            switch (field.key) {
                case 'level2': {
                    preparedItem[field.key] = item.level2;
                    break;
                }
                case 'name': {
                    preparedItem[field.key] = item.name;
                    break;
                }
                case 'quantity': {
                    preparedItem[field.key] = item.quantity?.toString();
                    break;
                }
                default: {
                    const details = item.parametersDetails.find((parameterDetails) => parameterDetails.key === field.key);
                    if (details) {
                        preparedItem[field.key] = details.valueLabel;
                    } else {
                        preparedItem[field.key] = item[field.key];
                    }
                    break;
                }
            }
        }
        return preparedItem;
    }

    render() {
        const fields = this.extractFields(this.props.data);

        return (
            <SummaryTable
                fields={fields}
                data={this.props.data.map((item) => this.prepareItem(item, fields))}
            />
        );
    }

}
