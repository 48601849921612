import { sprintf } from 'sprintf-js';

import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const minNumberValidator = (minNumber: number): Validator => ({
    validate: (value: number) => {
        const error = {
            errorMessage: sprintf(errorMessages.minNumber, minNumber - 1),
        };
        return value >= minNumber ? null : error;
    },
});
