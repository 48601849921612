import { createReducer } from '@reduxjs/toolkit';
import { AuthConfig } from './services/backendTypes';
import * as appActions from './actions';
import * as authActions from './layouts/Auth/actions';
import * as loginActions from './containers/Login/actions';

export interface AppReduxState {
    config: AuthConfig;
    version: number;
    versionBannerVisible: boolean;
    versionDialogIsVisible: boolean;
    toasts: AppToast[];
    alertDialog: AppAlertDialog;
    confirmDialog: AppConfirmDialog;
}

export interface AppToast {
    id: string;
    message: string;
    config: AppToastConfig;
}

export interface AppToastConfig {
    severity: 'success' | 'error';
    trueAction?: string;
    falseAction?: string;
    autohide?: boolean;
}

export interface AppAlertDialog {
    visible: boolean;
    message: string;
}

export interface AppConfirmDialog {
    id: string;
    visible: boolean;
    message: string;
    config: AppConfirmDialogConfig;
}

export interface AppConfirmDialogConfig {
    trueAction?: string;
    falseAction?: string;
}

const initialState = {
    config: null,
    versionBannerVisible: false,
    versionDialogIsVisible: false,
    toasts: [],
    alertDialog: {},
    confirmDialog: {},
} as AppReduxState;

export const app = createReducer(initialState, (builder) => {
    builder.addCase(loginActions.setLoginComplete, (state, action) => {
        state.config = action.payload;
    });
    builder.addCase(authActions.setLogoutComplete, (state) => {
        if (state.config) {
            state.config.isLoggedIn = false;
        }
    });
    builder.addCase(appActions.setLoadConfigComplete, (state, action) => {
        state.config = action.payload;
    });
    builder.addCase(appActions.updateVersion, (state, action) => {
        state.version = action.payload.version;
        state.versionBannerVisible = action.payload.newVersionAvailable;
        state.versionDialogIsVisible = action.payload.newVersionAvailable;
    });
    builder.addCase(appActions.closeVersionDialog, (state) => {
        state.versionDialogIsVisible = false;
    });
    builder.addCase(appActions.addSnackbarItem, (state, action) => {
        state.toasts.unshift(action.payload);
    });
    builder.addCase(appActions.removeSnackbarItem, (state, action) => {
        const index = state.toasts.findIndex((toast) => toast.id === action.payload);
        if (index !== -1) {
            state.toasts.splice(index, 1);
        }
    });
    builder.addCase(appActions.openAlertDialog, (state, action) => {
        state.alertDialog = { message: action.payload, visible: true };
    });
    builder.addCase(appActions.closeAlertDialog, (state) => {
        state.alertDialog.visible = false;
    });
    builder.addCase(appActions.openConfirmDialog, (state, action) => {
        state.confirmDialog = action.payload;
    });
    builder.addCase(appActions.closeConfirmDialog, (state) => {
        state.confirmDialog.visible = false;
    });
});
