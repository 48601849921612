export interface AuthConfig {
    account: AuthAccount;
    customers: AuthCustomer[];
    isLoggedIn: boolean;
}

interface AuthAccount {
    username: string;
    contact: {
        firstname: string;
        lastname: string;
    };
}

export interface AuthCustomer {
    key: string;
    name1: string;
    name2: string;
    nation: SizeNation;
    role: { [key in AuthPermission]?: boolean };
}

export enum AuthPermission {
    CAN_CREATE_ORDER = 'canCreateOrder',
    CAN_SEE_WHOLESALE_PRICE = 'canSeeWholesalePrice',
    CAN_EDIT_MY_HASTENS = 'canEditMyHastens',
}

export interface LoginRequestData {
    username: string;
    password: string;
}

export interface Quote {
    autogenerated: boolean;
    campaignCode: string;
    createdBy: {
        contact: QuoteContact;
    };
    createdTime: string;
    customerNo: string;
    endConsumer: QuoteEndConsumer;
    id: number;
    idpCode: string;
    incrementId: number;
    isHastensQuote: boolean;
    items: QuoteItem[];
    lastDayOfApproval: string;
    name: string;
    order: OrderDetails;
    purchaseOrderNumber: string;
    readOnly: boolean;
    referenceNumber: string;
    splitDelivery: boolean;
    orderType: OrderType;
}

export interface OrderType {
    serviceType: ServiceType;
    reason: OrderReason;
}

export type ServiceType = keyof typeof serviceTypes;
export type OrderReason = keyof typeof orderReasons;

export interface OrderDetails {
    createdBy: {
        contact: QuoteContact;
    }
    createdTime: string;
    deliveryTerms: string;
    deliveryLocation: string;
    deliveryAddress: string;
    deliveryTo: {
        name: string;
        street: string;
        city: string;
        zipcode: string;
        country: string;
    };
    doNotPurchase: DoNotPurchaseItem[];
}

export interface DoNotPurchaseItem {
    materialNo: string;
    qty: number;
}

export interface QuoteEndConsumer {
    apartmentNumber: string;
    city: string;
    country: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    region: string;
    street: string;
    title: string;
    zipCode: string;
}

export type QuoteItem = QuoteAccessoryItem | QuoteBedItem;

export interface QuoteBedItem extends QuoteItemBase {
    itemType: 'bed';
    bottom_list: string; // eslint-disable-line camelcase
    color: string;
    firmness: string;
    left_nameplate: string; // eslint-disable-line camelcase
    legs: string;
    length: number;
    middle_leg_link: YesOrNo; // eslint-disable-line camelcase
    name: string;
    nameplate: string;
    nameplate_type: string; // eslint-disable-line camelcase
    right_nameplate: string; // eslint-disable-line camelcase
    sizeNationKey: SizeNation;
    split_base: YesOrNo; // eslint-disable-line camelcase
    split_mattress: YesOrNo; // eslint-disable-line camelcase
    split_top_mattress: YesOrNo; // eslint-disable-line camelcase
    top_mattress_type: string; // eslint-disable-line camelcase
    trim: string;
    type: string;
    vividus_legs: string; // eslint-disable-line camelcase
    width: number;
}

export interface QuoteAccessoryItem extends QuoteItemBase {
    itemType: 'accessory';
    level1: string;
    level2: string;
    name: string;
    parameters: QuoteAccessoryParameters;
}

export interface QuoteAccessoryParameters {
    sizeNationKey?: SizeNation;
    [key: string]: string;
}

interface QuoteItemBase {
    campaigns: string[];
    currency: string;
    endConsumerDiscount: number;
    endConsumerPrice: number;
    fullPrice: number;
    parametersDetails: QuoteItemParameterDetails[];
    price: number;
    quantity: number;
    quotationCurrency: string;
    recFullPrice: number;
    valid: boolean;
}

export interface QuoteItemParameterDetails {
    key: string;
    keyLabel: string;
    value: string;
    valueLabel: string;
}

export interface QuoteContact {
    primaryEmail: {
        value: string;
    },
    firstname: string;
    lastname: string;
}

export interface ListQuote {
    autogenerated: boolean;
    createdBy: {
        contact: QuoteContact;
    };
    createdTime: string;
    endConsumer: QuoteEndConsumer;
    id: number;
    incrementId: number;
    isHastensQuote: boolean;
    name: string;
    order: OrderDetails;
    purchaseOrderNumber: string;
    readOnly: boolean;
    referenceNumber: string;
    version: DataVersion;
}

export interface DeleteQuoteResponse {
    deleted: boolean;
    quoteId: number;
}

export interface QuoteRequestData {
    campaignCode: string;
    customerNo: string;
    endConsumer: QuoteEndConsumer;
    id: number;
    incrementId: number;
    idpCode: string;
    items: RequestDataQuoteItem<RequestDataBedConfiguration | RequestDataAccessoryConfiguration>[];
    lastDayOfApproval: string;
    orderType: OrderType;
    purchaseOrderNumber: string;
    referenceNumber: string;
}

export type OrderRequestData = QuoteRequestData & DeliveryData;

export interface DeliveryData {
    deliveryTerms: DeliveryTerms;
    deliveryLocation: DeliveryLocation;
    deliveryTo: string;
    doNotPurchase: DoNotPurchaseItem[];
}

export type DeliveryTerms = 'default' | 'express';

export type DeliveryLocation = 'warehouse' | 'store';

export interface QuoteBuilderRequestData {
    id?: number;
    customerNumber: string;
    campaignCode: string;
    idpCode: string;
    orderType: OrderType;
    currency?: string;
    items: RequestDataQuoteItem<RequestDataBedConfiguration | RequestDataAccessoryConfiguration>[];
}

export interface QuoteBuilderResponse {
    items: QuoteBuilderResponseItem[];
}

export interface QuoteBuilderResponseItem {
    id: string;
    quantity: number;
    materials: QuoteBuilderMaterial[];
}

export interface QuoteBuilderMaterial {
    id: string;
    appliedCampaigns: string[];
    blockStatus: number; // TODO
    quantity: number;
    sku: string;
    surcharge: {
        colorPrice: number;
        colorListPrice: number;
    };
    price: {
        price: number;
        full: number;
        recommended: number;
        currency: string;
        quotationCurrency: string;
    };
    product: {
        name: string;
    };
    metaData: {
        includedFree: boolean;
        materialName: string;
        blockName: string;
    };
    valid: boolean;
}

export interface RequestDataQuoteItem<T> {
    id: string;
    sizeNationKey?: SizeNation;
    endConsumerPrice: number;
    endConsumerDiscount: number;
    quotationCurrency: string;
    configuration: T;
}

export type RequestDataAccessoryConfiguration = Pick<QuoteAccessoryItem, 'level1' | 'level2' | 'name' | 'quantity'> & {
    [key: string]: any;
};

export type RequestDataBedConfiguration = Pick<QuoteBedItem, 'bottom_list' | 'color' | 'firmness' | 'left_nameplate' | 'legs' | 'length' | 'middle_leg_link' | 'nameplate' | 'nameplate_type' | 'right_nameplate' | 'split_base' | 'split_mattress' | 'split_top_mattress' | 'top_mattress_type' | 'trim' | 'type' | 'vividus_legs' | 'width' | 'quantity'>;

export interface VerifyCampaignCodeRequestData {
    campaignCode: string;
    customerNumber: string;
}

export interface VerifyCampaignCodeResponse {
    isValid: YesOrNo;
}

export interface BedOption {
    code: string;
    displayName: string;
    campaignCodes: string[];
}

export interface BedPropertiesResponse {
    allowCustomSize: boolean;
    properties: BedPropertyConfig[];
    sizeOptions: SizeOptions;
}

export interface BedPropertyConfig {
    code: string;
    data: BedPropertyChoiceData | BedPropertyMeasurementData;
    dataType: BedPropertyConfigDataType;
    displayName: string;
    image: string;
    isDefault: boolean;
    rules: Rule[];
}

/**
 * When adding a new config data type remember to also add it to MissingOptionsAlert if necessary
 */
type BedPropertyConfigDataType = 'choice' | 'text' | 'measurement';

export interface BedPropertyChoiceData {
    options: BedPropertyChoiceDataOption[];
}

export interface BedPropertyChoiceDataOption {
    code: string;
    displayName: string;
    image: string;
    rules: Rule[];
}

export interface BedPropertyMeasurementData {
    intervals: {
        from: number;
        to: number;
        rules: Rule[];
    }[]
}

export type SizeOptions = { [key in SizeNation]: SizeOption[]; }

interface SizeOption {
    displayName: string;
    length: number;
    width: number;
}

export type SizeNation = 'ROW' | 'UK' | 'US' | 'AUS';

export interface AccessoryCategory {
    children: AccessoryCategory[];
    code: string;
    key: string;
    label: string;
    name: string;
    settings: AccessoryCategorySetting[];
}

export type AccessoryCategorySetting = AccessoryCategoryDropdownSetting | AccessoryCategorySizeSetting | AccessoryCategoryStringSizeSetting;

export interface AccessoryCategoryDropdownSetting extends AccessoryCategorySettingBase {
    data: {
        options: {
            code: string;
            disabled: boolean;
            displayName: string;
            image: string;
            rules: Rule[];
        }[];
    };
    type: 'dropdown';
}

export interface AccessoryCategorySizeSetting extends AccessoryCategorySettingBase {
    data: {
        widthInterval: Intervals;
        lengthInterval: Intervals;
    };
    type: 'size';
    allowCustomSize: boolean;
    standardSizes: SizeOptions;
}

export interface AccessoryCategoryStringSizeSetting extends AccessoryCategorySettingBase {
    data: {
        dimensions: 'height' | 'width' | 'width_length';
        heightInterval?: Intervals;
        widthInterval?: Intervals;
        lengthInterval?: Intervals;
        options: {
            code: string;
            disabled: boolean;
            displayName: string;
            rules: Rule[];
        }[];
    };
    type: 'string_size';
    allowCustomSize: boolean;
}

export interface Intervals {
    intervals: Interval[];
}

interface AccessoryCategorySettingBase {
    code: string;
    name: string;
    type: AccessorySettingType;
}

/**
 * When adding a new setting type remember to also add it to MissingOptionsAlert if necessary
 */
type AccessorySettingType = 'dropdown' | 'size' | 'string_size';

export interface Interval {
    from: number;
    to: number;
}

export interface Rule {
    type: string;
    property?: string;
    operator: string;
    value?: string;
    rules: Rule[];
}

export interface Country {
    code: string;
    name: string;
}

export interface Contact {
    address: {
        city: string;
        country: {
            name: string;
        };
        street: string;
        zipCode: string;
    };
    firstname: string;
    id: number;
    lastname: string;
    mobilePhone: string;
    telephone: string;
}

export interface Notification {
    active: boolean;
    createdAt: string;
    header: string;
    id: number;
    text: string;
}

export interface VerifyIdpCodeRequestData {
    customerNumber: string;
    idpCode: string;
}

export interface VerifyIdpCodeResponse {
    isValid: boolean;
}

export interface DeliveryAddress {
    city: string;
    country: string;
    id: number
    key: string;
    name: string;
    street: string;
    zipcode: string;
}

export interface PagedResponse<T> {
    list: T[];
    total: number;
}

export interface PagedQuote {
    createdBy: string;
    createdTime: string;
    id: number;
    orderNumber: number;
    purchaseOrderNumber: string;
    referenceNumber: string;
    version: DataVersion;
}

export interface FilterParameters {
    search: string;
    sort: string;
    direction: SortDirection;
    page: number;
    count: number;
}

export type SortDirection = 'desc' | 'asc';

type YesOrNo = 'yes' | 'no';

type DataVersion = 'V1' | 'V2';

export interface PagedTemplate {
    id: number;
    templateName: string;
    createdAt: string;
}

export interface TemplateRequestData {
    templateName: string,
    items: TemplateRequestDataItem<RequestDataBedConfiguration | RequestDataAccessoryConfiguration>[];
}

export interface TemplateRequestDataItem<T> {
    configuration: T;
}

export interface Template {
    id?: number;
    templateName: string;
    items: (Partial<QuoteBedItem> | Partial<QuoteAccessoryItem>)[];
}

export const serviceTypes = {
    standardOrder: 'Standard order',
    openingOrder: 'Opening order',
    serviceOrder: 'Service order',
    freeOfChargeOrder: 'Free of charge order',
    testOrderBed: 'Test order bed',
};

export const orderReasons = {
    serviceOrderUpgrade: 'Service order upgrade',
    compensation: 'Compensation',
    goodwill: 'Goodwill',
    orderEntryError: 'Order entry error',
    transportDamage: 'Transport damage',
    wrongDelivery: 'Wrong delivery',
    showroomAndSpons: 'Showroom & spons',
    testProducts: 'Test products',
    prAmbassadorOrders: 'PR/Ambassador order',
    warranty: 'Warranty',
};
