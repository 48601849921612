import React from 'react';

import { SummaryTable, SummaryTableDataItem } from './SummaryTable';
import { BED_PROPERTIES } from './bedProperties';
import { QuoteBedItem } from '../../../../services/backendTypes';

interface Props {
    data: QuoteBedItem[];
}

export class OrderBedTable extends React.Component<Props> {

    prepareItem(item: QuoteBedItem) {
        const preparedItem: SummaryTableDataItem = {};
        for (const bedProperty of BED_PROPERTIES) {
            switch (bedProperty.key) {
                case 'type': {
                    preparedItem[bedProperty.key] = item.name;
                    break;
                }
                case 'quantity': {
                    preparedItem[bedProperty.key] = item.quantity?.toString();
                    break;
                }
                default: {
                    const details = item.parametersDetails.find((parameterDetails) => parameterDetails.key === bedProperty.key);
                    if (details) {
                        preparedItem[bedProperty.key] = details.valueLabel;
                    } else {
                        preparedItem[bedProperty.key] = item[bedProperty.key];
                    }
                    break;
                }
            }
        }
        return preparedItem;
    }

    render() {
        return (
            <SummaryTable
                fields={BED_PROPERTIES}
                data={this.props.data.map((item) => this.prepareItem(item))}
            />
        );
    }

}
