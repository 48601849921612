import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import cloneDeep from 'lodash/cloneDeep';
import sum from 'lodash/sum';

import SelectField from '../../../../components/formFields/SelectField';
import Button from '../../../../components/formFields/Button';
import * as createOrderDialogActions from './actions';
import * as summaryDialogActions from '../../../../layouts/Auth/dialogs/SummaryDialog/actions';
import { DELIVERY_TERMS } from '../../../../constants/deliveryInfo';
import { Dialog } from '../../../../components/Dialog';
import RadioGroup from '../../../../components/formFields/RadioGroup';
import Checkbox from '../../../../components/formFields/Checkbox';
import Radio from '../../../../components/formFields/Radio';
import { BedOption, BedPropertyChoiceData, DeliveryData, DeliveryLocation, DoNotPurchaseItem, QuoteBuilderResponseItem } from '../../../../services/backendTypes';
import { ReduxState } from '../../../../store';
import { CUSTOMER_SERVICE_USER } from '../../../../appConfig';
import { OrderFormAccessoryItem, OrderFormBedItem } from '../../reducer';
import { AuthBedProperties } from '../../../../layouts/reducers';
import { safeDivide } from '../../../../functions/math';
import EditBedDialog from './EditBedDialog';

interface Props {
    onPlaceOrderClick: (deliveryData: DeliveryData, trackingData) => void;
}

interface AccessorySummaryItem {
    categoryName: string;
    quantity: number;
}

export interface PurchaseList {
    [key: string]: PurchaseListItem[];
}

export interface PurchaseListItem {
    id: string;
    sku: string;
    quantity: number;
}

const defaultFormData: DeliveryData = {
    deliveryTerms: 'default',
    deliveryLocation: undefined,
    deliveryTo: undefined,
    doNotPurchase: [],
};

export default function CreateOrderDialog(props: Props) {
    const dispatch = useDispatch();
    const dialogIsVisible = useSelector((state: ReduxState) => state.createOrderDialog.visible);
    const orderFormData = useSelector((state: ReduxState) => state.orderForm.data);
    const quoteBuilderResponse = useSelector((state: ReduxState) => state.orderForm.quoteBuilderResponse);
    const bedProperties = useSelector((state: ReduxState) => state.auth.bedProperties);
    const bedOptions = useSelector((state: ReduxState) => state.auth.beds.data);
    const deliveryAddresses = useSelector((state: ReduxState) => state.auth.deliveryAddresses);
    const isCustomerServiceUser = useSelector((state: ReduxState) => state.app.config.account.username === CUSTOMER_SERVICE_USER);

    const [formData, setFormData] = useState(defaultFormData);
    const [addressSelectIsVisible, setAddressSelectIsVisible] = useState(false);
    const [purchaseList, setPurchaseList] = useState<PurchaseList>({});
    const [purchaseListDialogIsVisible, setPurchaseListDialogIsVisible] = useState<string>(null);

    const selectedDeliveryAddress = deliveryAddresses?.find((deliveryAddress) => deliveryAddress.key === formData.deliveryTo) || null;
    const bedItems = orderFormData.items.filter((item) => item.itemType === 'bed') as OrderFormBedItem[];
    const accessoryItems = orderFormData.items.filter((item) => item.itemType === 'accessory') as OrderFormAccessoryItem[];
    const accessorySummaryItems = summarizeAccessoryItems(accessoryItems);
    const colorImages = extractColorImagesFromBedProperties(bedProperties);
    const bedNames = extractBedNamesFromBedOptions(bedOptions);

    function closeDialog() {
        setFormData(defaultFormData);
        setAddressSelectIsVisible(false);
        setPurchaseList({});
        dispatch(createOrderDialogActions.closeCreateOrderDialog());
    }

    return (
        <Dialog
            title="Create order"
            className="c--create-order-dialog"
            actionsClassName="dialog-actions"
            disableContentPadding
            open={dialogIsVisible}
            onOpen={() => {
                setPurchaseList(Object.keys(quoteBuilderResponse).reduce<PurchaseList>((result, key) => {
                    const { materials, quantity } = quoteBuilderResponse[key];
                    result[key] = materials.map((material) => ({
                        id: material.id,
                        sku: material.sku,
                        quantity: material.quantity * quantity,
                    }));

                    return result;
                }, {}));
            }}
            actions={[
                {
                    onClick: () => {
                        closeDialog();
                    },
                    children: 'Cancel',
                },
                {
                    onClick: () => {
                        const doNotPurchase = getDoNotPurchaseItems(purchaseList, quoteBuilderResponse);
                        const trackingData = getDoNotPurchaseItemsForGTM(purchaseList, quoteBuilderResponse);
                        props.onPlaceOrderClick({ ...formData, doNotPurchase }, trackingData );
                        closeDialog();
                    },
                    children: 'Place order',
                },
            ]}>
            <div className="dialog-content-main">
                <h3>My order</h3>
                <div className="order-summary">
                    <div className="summary-columns">
                        {bedItems.length > 0 && (
                            <div className="bed-summary">
                                <h4>Beds</h4>
                                <ul>
                                    {bedItems.map((bedItem, index) => (
                                        <li key={index}>
                                            <div className="image" style={{ backgroundImage: `url(${colorImages[bedItem.type]?.[bedItem.color]})` }} />
                                            <div>
                                                <p>
                                                    {bedNames[bedItem.type]} {`${safeDivide(bedItem.width, 10)}x${safeDivide(bedItem.length, 10)} cm`}
                                                    {isCustomerServiceUser && (
                                                        <Fragment>
                                                            <button
                                                                className="edit-button"
                                                                onClick={() => {
                                                                    setPurchaseListDialogIsVisible(bedItem._key);
                                                                }}>
                                                                <Icon fontSize="inherit">settings</Icon>
                                                            </button>
                                                            <EditBedDialog
                                                                open={purchaseListDialogIsVisible === bedItem._key}
                                                                purchaseList={purchaseList[bedItem._key]}
                                                                quoteBuilderResponseItem={quoteBuilderResponse[bedItem._key]}
                                                                onClose={() => {
                                                                    setPurchaseListDialogIsVisible(null);
                                                                }}
                                                                onChangePurchaseList={(materialId, quantity) => {
                                                                    setPurchaseList((prevState) => {
                                                                        const purchaseListItemIndex = purchaseList[bedItem._key]?.findIndex((listItem) => listItem.id === materialId);
                                                                        const nextState = cloneDeep(prevState);
                                                                        nextState[bedItem._key][purchaseListItemIndex].quantity = quantity;
                                                                        return nextState;
                                                                    });
                                                                }}
                                                            />
                                                        </Fragment>
                                                    )}
                                                </p>
                                                <p>{bedItem.quantity}x</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {accessorySummaryItems.length > 0 && (
                            <div className="accessory-summary">
                                <h4>Accessories</h4>
                                <ul>
                                    {accessorySummaryItems.map((accessory, index) => (
                                        <li key={index}>
                                            <p>{accessory.categoryName}</p>
                                            <p>{`${accessory.quantity}x`}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="button-container">
                        <Button
                            icon="find_in_page"
                            onClick={() => {
                                dispatch(summaryDialogActions.openSummaryDialog(orderFormData));
                            }}>
                            Preview details
                        </Button>
                    </div>
                </div>
                <h3>Delivery terms</h3>
                <div className="option-card">
                    <Radio
                        label={DELIVERY_TERMS.default}
                        checked={formData.deliveryTerms === 'default'}
                        onChange={() => {
                            setFormData((prevFormData) => ({
                                ...prevFormData,
                                deliveryTerms: 'default',
                            }));
                        }}
                    />
                </div>
                <div className="option-card">
                    <Radio
                        label={DELIVERY_TERMS.express}
                        checked={formData.deliveryTerms === 'express'}
                        onChange={() => {
                            setFormData((prevFormData) => ({
                                ...prevFormData,
                                deliveryTerms: 'express',
                                deliveryLocation: 'warehouse',
                            }));
                        }}
                    />
                    {formData.deliveryTerms === 'express' && (
                        <div className="express-delivery-options">
                            <p>
                                Express Delivery is only an option when ordering accessories and down.
                                Freight charges will be added to your account.

                                Bed orders will automatically be planned with the fastest possible delivery date.
                            </p>
                            <RadioGroup
                                onChange={(deliveryLocation: DeliveryLocation) => {
                                    setFormData((prevFormData) => ({ ...prevFormData, deliveryLocation }));
                                }}
                                value={formData.deliveryLocation}
                                controls={[
                                    {
                                        label: 'To warehouse',
                                        value: 'warehouse',
                                    },
                                    {
                                        label: 'To store',
                                        value: 'store',
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
                {deliveryAddresses?.length > 0 && (
                    <Fragment>
                        <h3>Delivery address (optional)</h3>
                        <div className="option-card">
                            <Checkbox
                                label="Select delivery address"
                                checked={addressSelectIsVisible}
                                onChange={(value) => {
                                    setAddressSelectIsVisible(value);
                                }}
                            />
                            {addressSelectIsVisible && (
                                <SelectField
                                    label="Delivery address"
                                    value={formData.deliveryTo}
                                    menuItems={deliveryAddresses.map((deliveryAddress) => ({
                                        label: deliveryAddress.name,
                                        value: deliveryAddress.key,
                                    }))}
                                    onChange={(deliveryTo: string) => {
                                        setFormData((prevFormData) => ({ ...prevFormData, deliveryTo }));
                                    }}
                                />
                            )}
                            {selectedDeliveryAddress && (
                                <div className="selected-delivery-address">
                                    <p>{selectedDeliveryAddress.street}</p>
                                    <p>{selectedDeliveryAddress.city}</p>
                                    <p>{selectedDeliveryAddress.country} {selectedDeliveryAddress.zipcode}</p>
                                </div>
                            )}
                        </div>
                    </Fragment>
                )}
            </div>
            <div className="dialog-content-footer">
                <p>Please check that your order is correct.</p>
                <p>When order is confirmed it can't be changed.</p>
            </div>
        </Dialog>
    );
}

function summarizeAccessoryItems(items: OrderFormAccessoryItem[]): AccessorySummaryItem[] {
    const summaryItems: AccessorySummaryItem[] = [];

    items.forEach((item) => {
        const foundIndex = summaryItems.findIndex((summaryItem) => summaryItem.categoryName === item.level1);
        if (foundIndex !== -1) {
            summaryItems[foundIndex].quantity = sum([summaryItems[foundIndex].quantity, item.quantity]);
        } else {
            summaryItems.push({ categoryName: item.level1, quantity: item.quantity });
        }
    });

    return summaryItems;
}

function extractColorImagesFromBedProperties(bedProperties: AuthBedProperties): { [bedType: string]: { [colorCode: string]: string } } {
    return Object.keys(bedProperties).reduce((result, bedType) => {
        const colorConfig = bedProperties[bedType].data?.find((bedProperty) => bedProperty.code === 'color');

        if (colorConfig) {
            const colorConfigData = colorConfig.data as BedPropertyChoiceData;

            result[bedType] = colorConfigData.options.reduce((result, option) => {
                result[option.code] = option.image;
                return result;
            }, {});
        }

        return result;
    }, {});
}

function extractBedNamesFromBedOptions(bedOptions: BedOption[]): { [bedType: string]: string } {
    return bedOptions?.reduce((result, bedOption) => {
        result[bedOption.code] = bedOption.displayName;
        return result;
    }, {});
}

function getDoNotPurchaseItems(purchaseList: PurchaseList, quoteBuilderResponse: { [key: string]: QuoteBuilderResponseItem; }): DoNotPurchaseItem[] {
    const result: { [sku: string]: number } = {};

    Object.keys(quoteBuilderResponse).forEach((key) => {
        quoteBuilderResponse[key].materials.forEach((material) => {
            const purchaseListItem = purchaseList[key].find((listItem) => listItem.id === material.id);
            const diff = material.quantity * quoteBuilderResponse[key].quantity - purchaseListItem.quantity;

            if (diff === 0) {
                return;
            }

            if (result.hasOwnProperty(material.sku)) {
                result[material.sku] += diff;
            } else {
                result[material.sku] = diff;
            }
        });
    });

    return Object.keys(result).map((sku) => ({ materialNo: sku, qty: result[sku] }));
}

function getDoNotPurchaseItemsForGTM(purchaseList: PurchaseList, quoteBuilderResponse: { [key: string]: QuoteBuilderResponseItem; }): { name: string; }[] {
    const result: { [sku: string]: string } = {};

    Object.keys(quoteBuilderResponse).forEach((key) => {
        quoteBuilderResponse[key].materials.forEach((material) => {
            const purchaseListItem = purchaseList[key].find((listItem) => listItem.id === material.id);
            const diff = material.quantity * quoteBuilderResponse[key].quantity - purchaseListItem.quantity;

            if (diff === 0) {
                return;
            }

            if (!result.hasOwnProperty(material.sku)) {
                result[material.sku] = material.metaData.materialName;
            }
        });
    });

    return Object.keys(result).map((sku) => ({ name: result[sku] }));
}
