import React, { PropsWithChildren, ReactElement } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '../../components/formFields/Button';

const DESKTOP_HEADER_HEIGHT = 64;
const MOBILE_HEADER_HEIGHT = 56;

interface CardSectionProps {
    title: string;
    stickyTitle: boolean;
    disabled: boolean;
    expanded: boolean;
    onAdd: () => void;
}

export function CardSection(props: PropsWithChildren<CardSectionProps>): ReactElement {

    const isMobile = useMediaQuery('(max-width: 767px)');
    const headerHeight = isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT;

    return (
        <section className="c--card-section">
            <StickyContainer>
                <Sticky topOffset={props.stickyTitle ? -headerHeight : 100} bottomOffset={headerHeight}>
                    {(config) => (
                        <div
                            className={config.isSticky ? 'title-bar sticky' : 'title-bar'}
                            style={config.isSticky ? { ...config.style, top: config.style.top as number + headerHeight } : config.style}>
                            <div className="container">
                                <div className="inner-wrapper">
                                    <h1>{props.title}</h1>
                                    <Button disabled={props.disabled} icon="add" color="dark" onClick={props.onAdd}>Add</Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Sticky>
                <div className={'card-container ' + (props.expanded ? 'expanded' : '')}>
                    <div className="container">
                        {props.children}
                    </div>
                </div>
            </StickyContainer>
        </section>
    );

}
