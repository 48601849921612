import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const maxCharactersValidator = (length: number): Validator => ({
    validate: (value: string) => {
        if (!value) {
            return null;
        }
        if (value.length <= length) {
            return null;
        }
        return {
            errorMessage: errorMessages.maxCharacters,
        };
    },
});

export const maxCharactersPerLineValidator = (length: number): Validator => ({
    validate: (value: string) => {
        if (!value) {
            return null;
        }
        const lines = value.split('\n');
        const valid = lines.map((line) => {
            return line.length <= length;
        });
        if (valid.every((item) => !!item)) {
            return null;
        }
        return {
            errorMessage: errorMessages.maxCharactersPerLine,
        };
    },
});
