import React from 'react';
import Grid from '@mui/material/Grid';

import TextField from '../../components/formFields/TextField';
import { calculateDiscountedPrice } from '../../functions/calculateDiscountedPrice';
import { formatPrice } from '../../functions/formatPrice';
import { LegacyQuoteAccessory, LegacyQuoteBed } from '../../services/legacyBackendTypes';
import { QuoteItem } from '../../services/backendTypes';

interface Props {
    item: LegacyQuoteBed | LegacyQuoteAccessory | QuoteItem;
    hideEndConsumerPrices: boolean;
    hideWholesalePrices: boolean;
}

export class ItemPrice extends React.Component<Props> {

    getDiscountPrice(endConsumerPrice: number, endConsumerDiscount: number, quotationCurrency: string) {
        if (endConsumerDiscount && endConsumerPrice) {
            const discountedPrice = formatPrice(calculateDiscountedPrice(endConsumerPrice, endConsumerDiscount), quotationCurrency);
            return discountedPrice || null;
        } else {
            return null;
        }
    }

    render() {
        const endConsumerDiscount = this.props.item.endConsumerDiscount ? this.props.item.endConsumerDiscount + '%' : null;
        const endConsumerRecFullPrice = this.props.item.recFullPrice > this.props.item.endConsumerPrice ? formatPrice(this.props.item.recFullPrice, this.props.item.quotationCurrency) : null;
        const wholeSaleFullPrice = this.props.item.fullPrice > this.props.item.price ? formatPrice(this.props.item.fullPrice, this.props.item.currency) : null;
        const discountedPrice = this.getDiscountPrice(this.props.item.endConsumerPrice, this.props.item.endConsumerDiscount, this.props.item.quotationCurrency);

        return (
            <Grid container spacing={2} className="c--item-price">
                {!!this.props.item.campaigns.length && !this.props.hideWholesalePrices && (
                    <Grid item xs={12} lg={3}>
                        <TextField readOnly label="Campaign matched" value={this.props.item.campaigns.join(', ')} />
                    </Grid>
                )}
                {!this.props.hideEndConsumerPrices && (
                    <Grid item xs={12} lg={3}>
                        <TextField
                            readOnly
                            label="Price"
                            lineThrough={!!endConsumerDiscount && this.props.item.valid}
                            className="endconsumer-price-field"
                            value={this.props.item.valid ? formatPrice(this.props.item.endConsumerPrice, this.props.item.quotationCurrency) : '---'}
                            helpText={endConsumerRecFullPrice}
                        />
                    </Grid>
                )}
                {!this.props.hideWholesalePrices && (
                    <Grid item xs={12} lg={3}>
                        <TextField
                            readOnly
                            label="Wholesale price"
                            value={this.props.item.valid ? formatPrice(this.props.item.price, this.props.item.currency) : '---'}
                            className="wholesale-price-field"
                            helpText={wholeSaleFullPrice}
                        />
                    </Grid>
                )}
                {(!this.props.hideEndConsumerPrices && endConsumerDiscount) && (
                    <Grid item xs={12} lg={3}>
                        <TextField readOnly label="Discount" value={endConsumerDiscount} />
                    </Grid>
                )}
                {(!this.props.hideEndConsumerPrices && discountedPrice) && (
                    <Grid item xs={12} lg={3}>
                        <TextField readOnly label="Discounted price" value={this.props.item.valid ? discountedPrice : '---'} />
                    </Grid>
                )}
            </Grid>
        );
    }

}
