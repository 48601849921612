import React, { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as authActions from './actions';
import LoginForm from '../../containers/Login/LoginForm';
import { Dialog } from '../../components/Dialog';
import { ReduxState } from '../../store';

type ReduxProps = ConnectedProps<typeof connector>;

const mapStateToProps = (store: ReduxState) => ({
    dialog: store.auth.loginDialog,
    config: store.app.config,
});

function LoginDialog(props: ReduxProps): ReactElement {

    function closeDialog() {
        props.dispatch(authActions.closeLoginDialog());
    }

    function handleHide() {
        // Do nothing
    }

    return (
        <Dialog
            title="Session timeout"
            size="small"
            open={props.dialog.visible}
            onClose={handleHide}>
            <LoginForm
                onLoginSuccess={() => closeDialog()}
                username={props.config.account.username}
            />
        </Dialog>
    );

}

const connector = connect(mapStateToProps);
export default connector(LoginDialog);
