import { AxiosError } from 'axios';
import { createAction } from '@reduxjs/toolkit';

import { backendService, ErrorData } from '../../services/backendService';
import { sessionStorageService } from '../../services/sessionStorageService';
import { openSummaryDialog } from '../../layouts/Auth/dialogs/SummaryDialog/actions';
import { FilterParameters, PagedQuote, PagedResponse, Quote } from '../../services/backendTypes';
import { AppThunkAction } from '../../store';

export function loadQuotes(customerNo: string, params: FilterParameters, rowToExpand?: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadQuotesPending());
        return backendService.getQuotesByPage(customerNo, params).then(
            (response) => {
                dispatch(setLoadQuotesComplete(response));
                // Expand table row if only one result
                if (response.list.length === 1) {
                    dispatch(loadQuoteAndExpandTableRow(response.list[0].id));
                } else if (rowToExpand) {
                    dispatch(loadQuoteAndExpandTableRow(rowToExpand));
                }
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLoadQuotesRejected(error?.response?.data?.message));
            },
        );
    };
}

export const setLoadQuotesPending = createAction('LOAD_QUOTES_PENDING');

export const setLoadQuotesComplete = createAction<PagedResponse<PagedQuote>>('LOAD_QUOTES_COMPLETE');

export const setLoadQuotesRejected = createAction<string>('LOAD_QUOTES_REJECTED');

export function loadQuoteAndExpandTableRow(id: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadQuotePending(id));
        return backendService.getQuote(id).then((response) => {
            dispatch(setLoadQuoteComplete(response));
            dispatch(expandTableRow(response.id));
        });
    };
}

export function openQuoteSummary(id: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setMoreMenuPending(id));
        return backendService.getQuote(id).then((response) => {
            dispatch(setLoadQuoteComplete(response));
            dispatch(setMoreMenuComplete(id));
            dispatch(openSummaryDialog(response));
        });
    };
}

export const setLoadQuotePending = createAction<number>('LOAD_ORDER_PENDING');

export const setLoadQuoteComplete = createAction<Quote>('LOAD_ORDER_COMPLETE');

export const expandTableRow = createAction<number>('EXPAND_TABLE_ROW');

export const collapseTableRow = createAction<number>('COLLAPSE_TABLE_ROW');

export const setMoreMenuPending = createAction<number>('MORE_MENU_PENDING');

export const setMoreMenuComplete = createAction<number>('MORE_MENU_COMPLETE');

export function openCustomerQuotation(item: PagedQuote, showWholesalePrice: boolean) {
    const jwt = sessionStorageService.getTokenWithoutPrefix();
    const hideCampaign = false === showWholesalePrice ? '&showWholesalePrices=false' : '';
    const url = backendService.urlTo(`en/partner-order/api/pdf/customer-quotation/${item.orderNumber}.pdf?jwt=${jwt}${hideCampaign}&inline=1`);
    window.open(url, '_blank');
}
