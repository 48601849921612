import React from 'react';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

export interface SpinnerProps {
    spinning?: boolean;
    error?: boolean;
    errorMessage?: string;
    className?: string;
    defaultIcon?: string;
    defaultElement?: JSX.Element;
    defaultIconAction?: () => void;
    withText?: boolean;
}

export function combineSpinners(spinners: SpinnerProps[]): SpinnerProps {
    const error = spinners.find((spinner) => spinner && spinner.error === true);
    if (error) {
        return error;
    }
    const spinning = spinners.find((spinner) => spinner && spinner.spinning === true);
    if (spinning) {
        return spinning;
    }
    return {};
}

export class Spinner extends React.Component<SpinnerProps> {

    render() {
        const className = clsx('c--spinner', this.props.className);

        if (this.props.error) {
            return (
                <span className={clsx(className, 'error')}>
                    <Tooltip
                        className="icon"
                        title={!this.props.withText && this.props.errorMessage ? this.props.errorMessage : ''}
                        placement="top">
                        <Icon>error</Icon>
                    </Tooltip>
                    {this.props.withText && !!this.props.errorMessage && (
                        <span className="error-message">{this.props.errorMessage}</span>
                    )}
                </span>
            );
        }
        if (this.props.spinning) {
            return (
                <CircularProgress
                    className={clsx(className, 'spinning')}
                    size={14}
                    thickness={5}
                    color="inherit"
                />
            );
        }
        if (this.props.defaultIcon) {
            return (
                <Icon
                    className={clsx(className, { 'default-icon-action': this.props.defaultIconAction })}
                    onClick={this.props.defaultIconAction}>
                    {this.props.defaultIcon}
                </Icon>
            );
        }
        if (this.props.defaultElement) {
            return this.props.defaultElement;
        }
        return null;
    }

}
