import isPlainObject from 'lodash/isPlainObject';
import values from 'lodash/values';

import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const requiredValidator: Validator = {
    validate: (value: any) => {
        const error = {
            errorMessage: errorMessages.required,
        };
        if (isPlainObject(value)) {
            const valid = values(value).every((objectValue) => objectValue !== undefined && objectValue !== '');
            return valid ? null : error;
        } else {
            const valid = value !== undefined && value !== '';
            return valid ? null : error;
        }
    },
};

export const requiredSizeValidator: Validator = {
    validate: (value: { width: number; length: number; }) => {
        const error = {
            errorMessage: errorMessages.required,
        };
        const valid = value.width !== undefined && value.length !== undefined;
        return valid ? null : error;
    },
};
