import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { UnregisterCallback } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { AxiosError } from 'axios';
import { sprintf } from 'sprintf-js';

import { formService } from '../../services/formService';
import * as createOrderDialogActions from './dialogs/CreateOrderDialog/actions';
import * as orderFormActions from './actions';
import * as authActions from '../../layouts/Auth/actions';
import * as appActions from '../../actions';
import * as formActions from '../../components/formFields/actions';
import { openSummaryDialog } from '../../layouts/Auth/dialogs/SummaryDialog/actions';
import Bed from './cards/Bed/Bed';
import Accessory from './cards/Accessory/Accessory';
import CreateOrderDialog from './dialogs/CreateOrderDialog/CreateOrderDialog';
import CustomerDialog from './dialogs/CustomerDialog/CustomerDialog';
import { CardSection } from './CardSection';
import { TotalQuoteValue } from './TotalQuoteValue';
import SlideAndFade from '../../components/animations/SlideAndFade';
import { can } from '../../components/Can/Can';
import { OrderFormHeader } from './OrderFormHeader';
import MissingOptionsAlert from './MissingOptionsAlert/MissingOptionsAlert';
import { NotificationButton } from './NotificationButton/NotificationButton';
import { RouteLoader } from '../../layouts/Auth/RouteLoader';
import { AppDispatch, ReduxState } from '../../store';
import { OrderFormAccessoryItem, OrderFormBedItem, OrderFormData } from './reducer';
import { AuthPermission, DeliveryData, OrderReason, orderReasons, ServiceType, serviceTypes } from '../../services/backendTypes';
import { groupAccessoryItemsByCategory, prepareQuoteRequestData, prepareTemplateRequestData } from './functions';
import { openDebugDialog } from '../../layouts/Auth/dialogs/DebugDialog/actions';
import { SUCCESS_TOAST_CONFIG } from '../../Snackbar';
import { ErrorData } from '../../services/backendService';
import BottomBar from './BottomBar';
import { CreateTemplateDialog } from './dialogs/CreateTemplateDialog';

export type OrderFormProps = ReduxProps & RouteComponentProps<OrderFormRouteParams, StaticContext, OrderFormLocationState>;
type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };

export interface OrderFormRouteParams {
    quoteId: string;
}

export interface OrderFormLocationState {
    formData: OrderFormData;
}

const mapStateToProps = (store: ReduxState) => ({
    orderForm: store.orderForm,
    app: store.app,
    auth: store.auth,
    countries: store.customerDialog.countries || [],
    form: store.form,
});

class OrderForm extends Component<OrderFormProps> {

    didUpdateQueue: (() => void)[] = [];
    unblock: UnregisterCallback;
    willUnmount = false;
    disableBeforeUnloadDialog = false;

    constructor(props: OrderFormProps) {
        super(props);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.unblock = this.props.history.block(() => {
            if (this.hasChanges()) {
                return 'You have unsaved changes. Are you sure you want to continue?';
            }
        });
    }

    componentDidMount() {
        this.populateForm(this.props);
    }

    componentWillUnmount() {
        this.willUnmount = true;
        this.props.dispatch(orderFormActions.resetOrderForm());
        this.props.dispatch(formActions.markAsPristine());
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        this.unblock();
    }

    componentDidUpdate() {
        if (this.didUpdateQueue.length) {
            for (const func of this.didUpdateQueue) {
                func();
            }
            this.didUpdateQueue = [];
        }
    }

    openSummaryDialog() {
        this.props.dispatch(openSummaryDialog(this.props.orderForm.data));
    }

    hasChanges(): boolean {
        const readOnly = this.props.orderForm.originalData.readOnly || false;

        return this.props.form.dirty && !readOnly && this.disableBeforeUnloadDialog === false;
    }

    populateForm(props: OrderFormProps) {
        const { firstname, lastname } = this.props.app.config.account.contact;

        if (props.match.params.quoteId) {
            this.props.dispatch(orderFormActions.loadQuote(parseInt(props.match.params.quoteId))).then(
                () => {
                    this.props.dispatch(orderFormActions.setFormIsReady());
                },
                (error: AxiosError<ErrorData>) => {
                    if (error.response.data.code === 'DEPRECATED_QUOTE') {
                        this.props.dispatch(orderFormActions.setFormIsUnavailable());
                    }
                },
            );
        } else if (props.history.location.state?.formData) {
            this.props.dispatch(orderFormActions.resetOrderForm({
                ...props.history.location.state?.formData,
                createdBy: { contact: { firstname, lastname } },
            } as OrderFormData));
            this.props.dispatch(orderFormActions.loadRelatedQuoteData()).then(() => {
                this.props.dispatch(orderFormActions.setFormIsReady());
            });
            // Clear history state
            this.props.history.replace(this.props.history.location.pathname);
        } else {
            const params = new URLSearchParams(props.location.search);
            const serviceTypeFromUrl = params.get('orderType') as ServiceType;
            const orderReasonFromUrl = params.get('orderReason') as OrderReason;
            const serviceType: ServiceType = serviceTypes.hasOwnProperty(serviceTypeFromUrl) ? serviceTypeFromUrl : 'standardOrder';
            const reason: OrderReason = orderReasons.hasOwnProperty(orderReasonFromUrl) ? orderReasonFromUrl : null;

            const orderFormData = {
                createdBy: { contact: { firstname, lastname } },
                orderType: {
                    serviceType,
                    reason,
                },
            } as OrderFormData;
            this.props.dispatch(orderFormActions.resetOrderForm(orderFormData));

            this.props.history.replace({
                pathname: this.props.history.location.pathname,
                state: { formData: orderFormData },
            });
            this.props.dispatch(orderFormActions.setFormIsReady());
        }
    }

    handleBeforeUnload(event: BeforeUnloadEvent) {
        if (this.hasChanges()) {
            event.returnValue = 'You have unsaved changes.';
        }
    }

    updateForm(values: Partial<OrderFormData>) {
        if (this.willUnmount) {
            return;
        }
        this.props.dispatch(orderFormActions.updateOrderFormContent(values));
    }

    addBed() {
        this.props.dispatch(orderFormActions.addOrderFormBed());
        this.props.dispatch(formActions.markAsDirtyIfPristine());
    }

    duplicateBed(key: string) {
        this.props.dispatch(orderFormActions.duplicateOrderFormBed(key));
        this.props.dispatch(formActions.markAsDirtyIfPristine());
    }

    removeItem(key: string) {
        this.props.dispatch(orderFormActions.removeOrderFormItem(key));
        this.props.dispatch(formActions.markAsDirtyIfPristine());
    }

    updateBed(key: string, values: Partial<OrderFormBedItem>) {
        this.props.dispatch(orderFormActions.updateOrderFormBeds([{ key, values }]));
    }

    resetBedPrices(key: string) {
        this.props.dispatch(orderFormActions.resetBedPrices(key));
    }

    addAccessory(type: string) {
        this.props.dispatch(orderFormActions.addOrderFormAccessory(type));
        this.props.dispatch(formActions.markAsDirtyIfPristine());
    }

    updateAccessory(key: string, values: Partial<OrderFormAccessoryItem>) {
        this.props.dispatch(orderFormActions.updateOrderFormAccessories([{ key, values }]));
    }

    removeEmptyCards(): boolean {
        let counter = 0;
        this.props.orderForm.data.items.map((item) => {
            if (item.itemType === 'bed' && !item.type) {
                this.removeItem(item._key);
                counter++;
            } else if (item.itemType === 'accessory') {
                const accessoryData = this.props.auth.accessories.find((accessory) => accessory.name === item.level1);
                if (accessoryData && !item[accessoryData.key]) {
                    this.removeItem(item._key);
                    counter++;
                }
            }
        });
        return counter > 0;
    }

    placeOrderButtonPressed(deliveryData: DeliveryData, trackingData) {
        if (this.removeEmptyCards()) {
            this.didUpdateQueue.push(this.createOrder.bind(this));
        } else {
            this.createOrder(deliveryData, trackingData);
        }
    }

    saveQuote() {
        const requestData = prepareQuoteRequestData({
            ...this.props.orderForm.data,
            customerNo: this.props.auth.currentCustomer.key,
        });

        this.props.dispatch(orderFormActions.saveQuote(requestData)).then((quote) => {
            if (!quote || !quote.id) {
                return;
            }

            this.props.dispatch(appActions.openSnackbar('The quote was saved successfully!', SUCCESS_TOAST_CONFIG));
            this.props.dispatch(authActions.loadSidebarQuotes(this.props.auth.currentCustomer.key));

            if (!requestData.id) {
                this.disableBeforeUnloadDialog = true;
                this.props.history.push(sprintf('/auth/%s/quote/%s', this.props.auth.currentCustomer.key, quote.id));
            }
        });
    }

    disableWhenSpinning() {
        return !!this.props.orderForm.saveSpinner.spinning || !!this.props.orderForm.createOrderSpinner.spinning;
    }

    hasItems() {
        for (const item of this.props.orderForm.data.items) {
            // Make sure the card has content because an empty card is considered a valid card but will be removed when saving
            if (item.itemType === 'bed' && item.type !== undefined) {
                return true;
            } else if (item.itemType === 'accessory' && (item.level2 !== undefined || item.name !== undefined)) {
                return true;
            }
        }
        return false;
    }

    createOrder(deliveryData: DeliveryData, trackingData) {
        const requestData = {
            ...prepareQuoteRequestData({
                ...this.props.orderForm.data,
                customerNo: this.props.auth.currentCustomer.key,
            }),
            ...deliveryData,
        };

        this.props.dispatch(orderFormActions.createOrder(this.props.orderForm.data.id, requestData, trackingData)).then((order) => {
            if (!order || !order.id) {
                return;
            }

            this.props.dispatch(appActions.openSnackbar('The order was created successfully!', SUCCESS_TOAST_CONFIG));
            this.props.dispatch(authActions.loadSidebarQuotes(this.props.auth.currentCustomer.key));
            this.props.dispatch(authActions.loadSidebarOrders(this.props.auth.currentCustomer.key));

            this.disableBeforeUnloadDialog = true;
            this.props.history.push(sprintf('/auth/%s/orders?id=%s', this.props.auth.currentCustomer.key, order.id));
        });
    }

    openCreateOrderDialog() {
        if (!formService.isValid('^header-card--')) {
            this.scrollToTop();
            return;
        }

        this.props.dispatch(createOrderDialogActions.openCreateOrderDialog());
    }

    triggerSaveQuote() {
        if (this.props.orderForm.data.purchaseOrderNumber) {
            if (this.removeEmptyCards()) {
                this.didUpdateQueue.push(this.saveQuote.bind(this));
            } else {
                this.saveQuote();
            }
        } else {
            this.scrollToTop();
            this.props.dispatch(appActions.openSnackbar('Please fill in all required fields', {
                severity: 'error',
                autohide: true,
                trueAction: null,
                falseAction: null,
            }));
        }
    }

    scrollToTop() {
        window.scrollTo({ top: 10, behavior: 'smooth' });
    }

    handleDebugClick(key: string) {
        this.props.dispatch(openDebugDialog({
            basic: this.props.orderForm.quoteBuilderResponse[key].materials.map((material) => ({
                blockName: material.metaData.blockName,
                materialName: material.metaData.materialName,
                productName: material.product.name,
                sku: material.sku,
                price: material.price.price,
                fullPrice: material.price.full,
                quantity: material.quantity,
            })),
            advanced: this.props.orderForm.quoteBuilderResponse[key],
        }));
    }

    createTemplate(templateName) {
        const requestData = prepareTemplateRequestData(templateName, this.props.orderForm.data);
        this.props.dispatch(orderFormActions.createTemplate(this.props.auth.currentCustomer.key, requestData)).then(() => {
            this.props.dispatch(orderFormActions.setCreateTemplateDialogClosed());
            this.props.dispatch(appActions.openSnackbar('The template was created successfully!', SUCCESS_TOAST_CONFIG));
        });
    }

    isProcessingMaterials(): boolean {
        return Object.keys(this.props.orderForm.quoteBuilderResponseSpinner).some((key) => {
            return this.props.orderForm.quoteBuilderResponseSpinner[key]?.spinning;
        });
    }

    render(): ReactElement {
        if (this.props.orderForm.formIsUnavailable) {
            return <RouteLoader error errorMessage="Not available" />;
        }
        if (!this.props.orderForm.formIsReady) {
            return <RouteLoader />;
        }

        const readOnly: boolean = this.props.orderForm.originalData.readOnly || false;
        const bedItems = this.props.orderForm.data.items.filter((item) => item.itemType === 'bed') as OrderFormBedItem[];
        const groupedAccessoryItems = groupAccessoryItemsByCategory(this.props.orderForm.data.items);

        return (
            <form className="c--order-form" key={this.props.orderForm.data.id ? this.props.orderForm.data.id : this.props.auth.currentCustomer.key}>
                <div className="page-wrapper">
                    <OrderFormHeader {...this.props} onChange={(values) => this.updateForm(values)} />
                    <MissingOptionsAlert
                        quote={this.props.orderForm.originalData}
                        bedTypeOptions={this.props.auth.beds.data}
                        bedPropertyConfigs={this.props.auth.bedProperties}
                        accessoryPropertyConfigs={this.props.auth.accessories}
                    />
                    <CardSection
                        disabled={readOnly}
                        title="Beds"
                        stickyTitle={!!bedItems?.length}
                        expanded={!!bedItems?.length}
                        onAdd={() => this.addBed()}>
                        <TransitionGroup>
                            {bedItems?.map((bedItem) => (
                                <SlideAndFade key={bedItem._key}>
                                    <Bed
                                        disabled={readOnly}
                                        quoteId={this.props.orderForm.data.id}
                                        data={bedItem}
                                        simplifiedQuoteBuilderResponseItem={this.props.orderForm.simplifiedQuoteBuilderResponse[bedItem._key]}
                                        quoteBuilderResponseSpinner={this.props.orderForm.quoteBuilderResponseSpinner[bedItem._key]}
                                        onAdd={() => this.addBed()}
                                        onDuplicate={() => this.duplicateBed(bedItem._key)}
                                        onRemove={() => {
                                            this.props.dispatch(appActions.confirm('Are you sure?')).then((response) => {
                                                if (response === true) {
                                                    this.removeItem(bedItem._key);
                                                }
                                            });
                                        }}
                                        onChangeBed={() => this.resetBedPrices(bedItem._key)}
                                        onChange={(values) => this.updateBed(bedItem._key, values)}
                                        onDebugClick={() => {
                                            this.handleDebugClick(bedItem._key);
                                        }}
                                    />
                                </SlideAndFade>
                            ))}
                        </TransitionGroup>
                    </CardSection>
                    {this.props.auth.accessories?.map((category) => (
                        <CardSection
                            disabled={readOnly}
                            key={category.name}
                            title={category.name}
                            stickyTitle={!!groupedAccessoryItems[category.name]?.length}
                            expanded={!!groupedAccessoryItems[category.name]?.length}
                            onAdd={() => this.addAccessory(category.name)}>
                            <TransitionGroup>
                                {groupedAccessoryItems[category.name]?.map((accessoryItem) => (
                                    <SlideAndFade key={accessoryItem._key}>
                                        <Accessory
                                            disabled={readOnly}
                                            quoteId={this.props.orderForm.data.id}
                                            data={accessoryItem}
                                            simplifiedQuoteBuilderResponseItem={this.props.orderForm.simplifiedQuoteBuilderResponse[accessoryItem._key]}
                                            quoteBuilderResponseSpinner={this.props.orderForm.quoteBuilderResponseSpinner[accessoryItem._key]}
                                            category={category}
                                            onAdd={() => this.addAccessory(category.name)}
                                            onRemove={() => this.removeItem(accessoryItem._key)}
                                            onChange={(values) => this.updateAccessory(accessoryItem._key, values)}
                                            onDebugClick={() => {
                                                this.handleDebugClick(accessoryItem._key);
                                            }}
                                        />
                                    </SlideAndFade>
                                ))}
                            </TransitionGroup>
                        </CardSection>
                    ))}
                    <TotalQuoteValue
                        data={this.props.orderForm.data}
                        simplifiedQuoteBuilderResponse={this.props.orderForm.simplifiedQuoteBuilderResponse}
                        hideWholesalePrices={this.props.auth.hideWholesalePrices}
                        hideEndConsumerPrices={this.props.auth.hideEndConsumerPrices}
                    />
                    <BottomBar
                        menuItems={[
                            {
                                displayName: 'Preview details',
                                icon: 'find_in_page',
                                disabled: this.disableWhenSpinning(),
                                onClick: this.openSummaryDialog.bind(this),
                                slot: 'left',
                            },
                            {
                                type: 'submit',
                                displayName: 'Create template from quote',
                                icon: 'insert_drive_file',
                                disabled: !this.hasItems() || this.disableWhenSpinning(),
                                onClick: () => {
                                    this.props.dispatch(orderFormActions.setCreateTemplateDialogOpen());
                                },
                                spinner: this.props.orderForm.createTemplateSpinner,
                                slot: 'left',
                            },
                            {
                                type: 'submit',
                                displayName: this.props.orderForm.data.id ? 'Update quote' : 'Create quote',
                                icon: this.props.orderForm.data.id ? 'update' : 'note_add',
                                disabled: !this.hasItems() || this.disableWhenSpinning() || !!this.props.orderForm.simplifiedQuoteBuilderResponse.blockStatus || readOnly,
                                onClick: () => {
                                    this.triggerSaveQuote();
                                },
                                spinner: this.props.orderForm.saveSpinner,
                                slot: 'right',
                                id: 'save-quote-button',
                            },
                            {
                                type: 'submit',
                                displayName: 'Create order',
                                icon: 'local_shipping',
                                disabled: !this.hasItems() || this.disableWhenSpinning() || this.isProcessingMaterials() || !!this.props.orderForm.simplifiedQuoteBuilderResponse.blockStatus,
                                onClick: this.openCreateOrderDialog.bind(this),
                                spinner: this.props.orderForm.createOrderSpinner,
                                slot: 'right',
                                visible: !!this.props.orderForm.data.id && can(this.props.auth.currentCustomer, AuthPermission.CAN_CREATE_ORDER),
                                id: 'create-order-button',
                            },
                        ]}
                    />
                </div>
                <NotificationButton {...this.props} />
                <CreateOrderDialog onPlaceOrderClick={this.placeOrderButtonPressed.bind(this)} />
                <CustomerDialog
                    data={this.props.orderForm.data.endConsumer}
                    quotes={this.props.auth.quotes}
                    onChange={this.updateForm.bind(this)}
                />
                <CreateTemplateDialog
                    open={this.props.orderForm.createTemplateDialogIsOpen}
                    onClose={(name) => {
                        this.props.dispatch(orderFormActions.setCreateTemplateDialogClosed());
                        if (name) {
                            this.createTemplate(name);
                        }
                    }}
                />
            </form>
        );
    }
}

const connector = connect(mapStateToProps);
export default connector(OrderForm);
