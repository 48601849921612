import React from 'react';
import Fade from '@mui/material/Fade';

import Button from '../../components/formFields/Button';

interface Props {
    visible: boolean;
}

export function VersionBanner(props: Props) {
    if (!props.visible) {
        return null;
    }

    return (
        <div className="c--version-banner">
            <Fade in timeout={800}>
                <div className="content-wrapper">
                    <p>A new version of this website has been released. Please refresh your browser to get the new version.</p>
                    <div>
                        <Button
                            color="dark"
                            onClick={() => {
                                window.location.reload();
                            }}>
                            Refresh
                        </Button>
                    </div>
                </div>
            </Fade>
        </div>
    );
}
