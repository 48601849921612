import React, { Fragment, ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useMediaQuery from '@mui/material/useMediaQuery';
import chunk from 'lodash/chunk';

interface SummaryTableProps {
    fields: SummaryTableField[];
    data: SummaryTableDataItem[];
}

export interface SummaryTableField {
    key: string;
    title: string;
}

export interface SummaryTableDataItem {
    [key: string]: string;
}

export function SummaryTable(props: SummaryTableProps): ReactElement {

    const isMobile = useMediaQuery('(max-width: 767px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1025px)');
    const chunkSize = getChunkSize();
    const chunks = chunk(props.data, chunkSize);
    const columnStyle = {
        width: 100 / (chunkSize + 1) + '%',
    };

    function getChunkSize(): number {
        if (isMobile) {
            return 1;
        } else if (isTablet) {
            return 2;
        } else {
            return 4;
        }
    }

    return (
        <div>
            {chunks.map((items, chunkIndex) => (
                <Table key={chunkIndex} className="horizontal-table">
                    <TableBody>
                        <Fragment>
                            <TableRow key="static-element">
                                <Fragment>
                                    <TableCell key="static-element" style={columnStyle}></TableCell>
                                    {items.map((value, itemIndex) => (
                                        <TableCell key={itemIndex} style={columnStyle}><span className="column-number">{(chunkIndex * chunkSize) + (itemIndex + 1)}</span></TableCell>
                                    ))}
                                </Fragment>
                            </TableRow>
                            {props.fields && props.fields.map((field, fieldIndex: number) => (
                                <TableRow key={fieldIndex}>
                                    <Fragment>
                                        <TableCell key="static-element" style={columnStyle}>{field.title}</TableCell>
                                        {items.map((value, itemIndex) => (
                                            <TableCell key={itemIndex} style={columnStyle}>{value[field.key] || '---'}</TableCell>
                                        ))}
                                    </Fragment>
                                </TableRow>
                            ))}
                        </Fragment>
                    </TableBody>
                </Table>
            ))}
        </div>
    );

}
