import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import { getUniqueId } from '../functions/getUniqueId';

export function addKeys(data: any) {
    if (isArray(data)) {
        return data.map((item) => {
            return isPlainObject(item) ? { ...addKeys(item), _key: getUniqueId() } : item;
        });
    }

    if (isPlainObject(data)) {
        return mapValues(data, (value) => addKeys(value));
    }
    return data;
}
