import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';
import { SizeOptions } from '../services/backendTypes';

/**
 * Checks menu items to see if size is available (if custom size is not allowed).
 */

export const sizeExistsValidator = (menuItems: SizeOptions, allowCustomSize?: boolean): Validator => ({
    validate: (value: { width: number | string; length: number | string; }) => {
        const error = {
            errorMessage: errorMessages.sizeExists,
        };
        if (allowCustomSize) {
            return null;
        }
        if (!menuItems) {
            return null;
        }
        const width = Number(value.width) || 0;
        const length = Number(value.length) || 0;
        const concatenated = [].concat(...Object.keys(menuItems).map((key) => menuItems[key]));
        const menuItemExists = !!concatenated.find((menuItem) => {
            return menuItem.length === length && menuItem.width === width;
        });
        return menuItemExists ? null : error;
    },
});
