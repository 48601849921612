import React from 'react';

export const NotificationIcon = (props: any) => (
    <svg {...props} version="1.0" xmlns="http://www.w3.org/2000/svg" width="100.000000pt" height="100.000000pt" viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
        </metadata>
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M450 880 c-11 -11 -20 -31 -20 -44 0 -17 -9 -27 -32 -37 -50 -21 -99 -69 -124 -124 -21 -45 -24 -64 -24 -190 l0 -141 -45 -44 c-32 -31 -45 -52 -45 -72 l0 -28 340 0 340 0 0 28 c0 20 -13 41 -45 72 l-45 44 0 141 c0 126 -3 145 -24 190 -25 55 -74 103 -124 124 -23 10 -32 20 -32 37 0 33 -34 64 -70 64 -17 0 -39 -9 -50 -20z"/>
            <path d="M420 151 c0 -37 35 -66 80 -66 45 0 80 29 80 66 0 17 -8 19 -80 19 -72 0 -80 -2 -80 -19z"/>
        </g>
    </svg>
);
