import React, { Fragment, ReactElement, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Auth from './layouts/Auth/Auth';
import { Login } from './containers/Login/Login';
import * as appActions from './actions';
import { sessionStorageService } from './services/sessionStorageService';
import { AppDispatch, ReduxState } from './store';
import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
import Snackbar from './Snackbar';

import HastensIcon from '../assets/images/hastens-icon.svg';

export type AppProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };

const mapStateToProps = (store: ReduxState) => ({
    app: store.app,
});

function App(props: AppProps): ReactElement {
    const location = useLocation();
    const history = useHistory();
    const disableMaintenanceMode = JSON.parse(window.localStorage.getItem('disableMaintenanceMode'));

    if (window['maintenanceMode'] === true && disableMaintenanceMode !== true) {
        return (
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'rgba(0, 0, 0, 0.25)',
                    fontWeight: 400,
                    padding: '20px',
                    textAlign: 'center',
                }}>
                <Box
                    sx={{
                        height: '100px',
                        width: '100px',
                        backgroundImage: `url(${HastensIcon})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: {
                            xs: '25px',
                            md: '30px',
                        },
                        fontWeight: 600,
                        maxWidth: 600,
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}>
                    Scheduled maintenance
                </Typography>
                <Typography
                    sx={{
                        margin: 0,
                        maxWidth: 600,
                        fontSize: '14px',
                    }}>
                    Coper is having a scheduled downtime due to going live with the new ERP system. We apologize for any inconvenience. For more information, or if you have any questions, please contact customer service.
                </Typography>
            </Box>
        );
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionToken = params.get('token');
        if (sessionToken) {
            sessionStorageService.setToken(sessionToken);
            /**
             * Proof of concept
             */
            window['dataLayer']?.push({
                event: 'login',
                config_user_type: 'customer_service',
            });
        }
        params.delete('token');
        history.replace({ search: params.toString() });
        props.dispatch(appActions.loadConfig());
        props.dispatch(appActions.loadVersion());
    }, []);

    return (
        <Fragment>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                <Route exact path="/login" component={Login} />
                <Route
                    path="/auth/:customerKey"
                    render={(route) => {
                        if (props.app.config === null) {
                            return null;
                        }
                        if (props.app.config.isLoggedIn === true && props.app.config.account) {
                            return <Auth {...route} {...props} />;
                        }
                        return <Redirect to="/login" />;
                    }}
                />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
            <AlertDialog />
            <ConfirmDialog />
            <Snackbar />
        </Fragment>
    );

}

const connector = connect(mapStateToProps);
export default connector(App);
