import { createAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import uniqueId from 'lodash/uniqueId';

import * as appActions from '../../actions';
import { AccessoryCategory, AuthCustomer, BedOption, BedPropertiesResponse, DeleteQuoteResponse, DeliveryAddress, ListQuote, Quote } from '../../services/backendTypes';
import { AppThunkAction } from '../../store';
import { backendService, ErrorData } from '../../services/backendService';
import resolveService from '../../services/resolveService';
import { sessionStorageService } from '../../services/sessionStorageService';
import { convertLegacyQuote } from '../../functions/convertLegacyQuote';
import { SUCCESS_TOAST_CONFIG } from '../../Snackbar';

const loginDialogIds: string[] = [];

export const openSidebar = createAction('OPEN_SIDEBAR');

export const closeSidebar = createAction('CLOSE_SIDEBAR');

export function promtForPassword(): AppThunkAction<Promise<boolean>> {
    const id = uniqueId();

    return (dispatch) => {
        dispatch(openLoginDialog());
        loginDialogIds.push(id);
        return resolveService.createPromise(id);
    };
}

export const openLoginDialog = createAction('OPEN_LOGIN_DIALOG');

export const closeLoginDialog = createAction('CLOSE_LOGIN_DIALOG', () => {
    if (loginDialogIds.length) {
        loginDialogIds.forEach((id) => {
            resolveService.resolvePromise(id, true);
        });
    }
    return { payload: undefined };
});

export const setCurrentCustomer = createAction<AuthCustomer>('SET_CURRENT_CUSTOMER');

export function logout(): AppThunkAction {
    return (dispatch) => {
        backendService.logout();
        dispatch(setLogoutComplete());
    };
}

export const setLogoutComplete = createAction('LOGOUT_COMPLETE');

export function loadSidebarQuotes(customerNo: string): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadSidebarQuotesPending());
        return backendService.getQuotes(customerNo).then(
            (response) => {
                dispatch(setLoadSidebarQuotesComplete({
                    quoteData: response?.quotes.filter((quote) => !quote.autogenerated),
                    onlineQuoteData: response?.quotes.filter((quote) => !!quote.autogenerated),
                }));
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLoadSidebarQuotesRejected(error?.response?.data?.message));
            },
        );
    };
}

export const setLoadSidebarQuotesPending = createAction('LOAD_SIDEBAR_QUOTES_PENDING');

export const setLoadSidebarQuotesComplete = createAction<{ quoteData: ListQuote[]; onlineQuoteData: ListQuote[]; }>('LOAD_SIDEBAR_QUOTES_COMPLETE');

export const setLoadSidebarQuotesRejected = createAction<string>('LOAD_SIDEBAR_QUOTES_REJECTED');

export function lazyloadSidebarQuote(quoteId: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setLazyloadSidebarQuotePending({ quoteId }));
        return backendService.getQuote(quoteId).then(
            (response) => {
                dispatch(setLazyloadSidebarQuoteComplete({ quoteId, data: response }));
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLazyloadSidebarQuoteRejected({ quoteId, errorMessage: error?.response?.data?.message }));
            },
        );
    };
}

export const setLazyloadSidebarQuotePending = createAction<{ quoteId: number; }>('LAZYLOAD_SIDEBAR_QUOTE_PENDING');

export const setLazyloadSidebarQuoteComplete = createAction<{ quoteId: number; data: Quote; }>('LAZYLOAD_SIDEBAR_QUOTE_COMPLETE');

export const setLazyloadSidebarQuoteRejected = createAction<{ quoteId: number; errorMessage: string; }>('LAZYLOAD_SIDEBAR_QUOTE_REJECTED');

export function loadSidebarOrders(customerNo: string): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadSidebarOrdersPending());
        return backendService.getOrders(customerNo).then(
            (response) => {
                dispatch(setLoadSidebarOrdersComplete(response?.quotes));
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLoadSidebarOrdersRejected(error?.response?.data?.message));
            },
        );
    };
}

export const setLoadSidebarOrdersPending = createAction('LOAD_SIDEBAR_ORDERS_PENDING');

export const setLoadSidebarOrdersComplete = createAction<ListQuote[]>('LOAD_SIDEBAR_ORDERS_COMPLETE');

export const setLoadSidebarOrdersRejected = createAction<string>('LOAD_SIDEBAR_ORDERS_REJECTED');

export function lazyloadSidebarOrder(quoteId: number): AppThunkAction {
    return (dispatch) => {
        dispatch(setLazyloadSidebarOrderPending({ quoteId }));
        return backendService.getOrder(quoteId).then(
            (response) => {
                dispatch(setLazyloadSidebarOrderComplete({ quoteId, data: convertLegacyQuote(response.quote) }));
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLazyloadSidebarOrderRejected({ quoteId, errorMessage: error?.response?.data?.message }));
            },
        );
    };
}

export const setLazyloadSidebarOrderPending = createAction<{ quoteId: number; }>('LAZYLOAD_SIDEBAR_ORDER_PENDING');

export const setLazyloadSidebarOrderComplete = createAction<{ quoteId: number; data: Quote; }>('LAZYLOAD_SIDEBAR_ORDER_COMPLETE');

export const setLazyloadSidebarOrderRejected = createAction<{ quoteId: number; errorMessage: string; }>('LAZYLOAD_SIDEBAR_ORDER_REJECTED');

export function deleteQuote(id: number): AppThunkAction<Promise<DeleteQuoteResponse>> {
    return (dispatch) => {
        return backendService.deleteQuote(id).then((response) => {
            dispatch(setDeleteQuoteComplete(response.quoteId));
            dispatch(appActions.openSnackbar('The quote was removed successfully!', SUCCESS_TOAST_CONFIG));
            return response;
        });
    };
}

export const setDeleteQuoteComplete = createAction<number>('DELETE_QUOTE_COMPLETE');

export function loadBeds(customerNumber: string): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadBedsPending());
        return backendService.getBeds(customerNumber).then(
            (response) => {
                dispatch(setLoadBedsComplete(response.data));
            },
            (error: AxiosError<ErrorData>) => {
                dispatch(setLoadBedsRejected(error?.response?.data?.message));
            },
        );
    };
}

export const setLoadBedsPending = createAction('LOAD_BEDS_PENDING');

export const setLoadBedsComplete = createAction<BedOption[]>('LOAD_BEDS_COMPLETE');

export const setLoadBedsRejected = createAction<string>('LOAD_BEDS_REJECTED');

export function loadAccessories(customerNumber: string): AppThunkAction {
    return (dispatch) => {
        dispatch(setLoadAccessoriesPending());
        return backendService.getAccessories(customerNumber).then(
            (response) => {
                dispatch(setLoadAccessoriesComplete(response));
                return response;
            },
            () => {
                dispatch(setLoadAccessoriesRejected());
            },
        );
    };
}

export const setLoadAccessoriesPending = createAction('LOAD_ACCESSORIES_PENDING');

export const setLoadAccessoriesComplete = createAction<AccessoryCategory[]>('LOAD_ACCESSORIES_COMPLETE');

export const setLoadAccessoriesRejected = createAction('LOAD_ACCESSORIES_REJECTED');

export function loadBedProperties(bedCodes: string[]): AppThunkAction<Promise<void[]>> {
    return (dispatch, getState) => {
        const promises = bedCodes.map((bedCode) => {
            dispatch(setLoadBedPropertiesPending({ bedCode }));
            return backendService.getBedProperties(bedCode, { customerNumber: getState().auth.currentCustomer.key }).then(
                (response) => {
                    dispatch(setLoadBedPropertiesComplete({ bedCode, data: response }));
                },
                (error: AxiosError<ErrorData>) => {
                    dispatch(setLoadBedPropertiesRejected({ bedCode, errorMessage: error?.response?.data?.message }));
                },
            );
        });
        return axios.all(promises);
    };
}

export const setLoadBedPropertiesPending = createAction<{ bedCode: string; }>('LOAD_BED_PROPERTIES_PENDING');

export const setLoadBedPropertiesComplete = createAction<{ bedCode: string; data: BedPropertiesResponse; }>('LOAD_BED_PROPERTIES_COMPLETE');

export const setLoadBedPropertiesRejected = createAction<{ bedCode: string; errorMessage: string; }>('LOAD_BED_PROPERTIES_REJECTED');

export function loadCustomerDeliveryAddresses(customerNo: string): AppThunkAction {
    return (dispatch) => {
        return backendService.getDeliveryAddresses(customerNo).then((response) => {
            dispatch(setLoadCustomerDeliveryAddressesComplete(response?.deliveryAddresses));
        });
    };
}

export const setLoadCustomerDeliveryAddressesComplete = createAction<DeliveryAddress[]>('LOAD_CUSTOMER_DELIVERY_ADDRESSES_COMPLETE');

export const hideEndConsumerPrices = createAction<boolean>('HIDE_END_CONSUMER_PRICES');

export const hideWholesalePrices = createAction<boolean>('HIDE_WHOLESALE_PRICES');

export function openCustomerQuotation(incrementId: number, showWholesalePrices: boolean) {
    const hideCampaign = true === showWholesalePrices ? '&showWholesalePrices=false' : '';
    const jwt = sessionStorageService.getTokenWithoutPrefix();
    const url = backendService.urlTo(`en/partner-order/api/pdf/customer-quotation/${incrementId}.pdf?jwt=${jwt}${hideCampaign}&inline=1`);
    window.open(url, '_blank');
}

export function openDeliveryConfirmation(item: { id: number; orderNumber: number; }) {
    const jwt = sessionStorageService.getTokenWithoutPrefix();
    const url = backendService.urlTo(`en/partner-order/api/pdf/delivery-confirmation/${item.orderNumber}.pdf?jwt=${jwt}&inline=1`);
    window.open(url, '_blank');
}
