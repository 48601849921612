import { AnyAction, configureStore, ThunkDispatch, ThunkAction } from '@reduxjs/toolkit';
import promise from 'redux-promise-middleware';

import { app } from './reducer';
import * as layoutReducers from './layouts/reducers';
import * as containerReducers from './containers/reducers';
import * as componentReducers from './components/reducers';

const middleware = [promise];

if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    middleware.push(createLogger({
        collapsed: true,
    }));
}

const middlewareOptions = {
    immutableCheck: false, // TODO: Enable
    serializableCheck: false, // TODO: Enable
};

export const store = configureStore({
    reducer: {
        app,
        ...layoutReducers,
        ...containerReducers,
        ...componentReducers,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(middlewareOptions).concat(middleware),
});

export type ReduxState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<ReduxState, unknown, AnyAction>;
export type AppThunkAction<T = void> = ThunkAction<T, ReduxState, unknown, AnyAction>
