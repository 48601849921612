import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import * as loginActions from './actions';
import Button from '../../components/formFields/Button';
import TextField from '../../components/formFields/TextField';
import { Spinner } from '../../components/Spinner/Spinner';
import { AppDispatch, ReduxState } from '../../store';
import { AuthConfig } from '../../services/backendTypes';

type ReduxProps = ConnectedProps<typeof connector> & { dispatch: AppDispatch };

interface Props {
    username?: string;
    onLoginSuccess?: (config: AuthConfig) => void;
}

interface State {
    username: string;
    password: string;
}

export const MY_HASTENS_URL = window['myHastensUrl'];

const mapStateToProps = (store: ReduxState) => ({
    login: store.login,
});

class LoginForm extends React.Component<Props & ReduxProps, State> {

    state: State = {
        username: '',
        password: '',
    };

    componentDidMount() {
        if (this.props.username) {
            this.setState({
                username: this.props.username,
            });
        }
    }

    submit(event: Event) {
        event.preventDefault();
        this.props.dispatch(loginActions.login(this.state.username, this.state.password)).then((response) => {
            if (response) {
                this.props.onLoginSuccess(response);
            }
        });
    }

    render() {
        return (
            <form className="c--login-form" onSubmit={this.submit.bind(this)}>
                {!this.props.username && (
                    <div className="username">
                        <TextField
                            id="username"
                            label="Email"
                            type="email"
                            value={this.state.username || ''}
                            onChange={(value: any) => this.setState({ username: value })}
                        />
                    </div>
                )}
                <div className="password">
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        value={this.state.password || ''}
                        onChange={(value: any) => this.setState({ password: value })}
                    />
                </div>
                <div className="login-spinner">
                    <Spinner withText {...this.props.login.loginSpinner} />
                </div>
                <div className="actions">
                    <div>
                        <a href={MY_HASTENS_URL + 'en/my-hastens/reset-password'} target="_blank" rel="noreferrer">Forgot Your Password?</a>
                    </div>
                    <div>
                        <Button type="submit" variant="contained">Login</Button>
                    </div>
                </div>
            </form>
        );
    }

}

const connector = connect(mapStateToProps);
export default connector(LoginForm);
