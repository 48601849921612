import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const trimValidator: Validator = {
    validate: (value: string) => {
        const error = {
            errorMessage: errorMessages.invalidCharacters,
        };
        if (!value) {
            return null;
        }
        const valid = !new RegExp('^\\s').test(value) && !new RegExp('\\s$').test(value);
        return valid ? null : error;
    },
};
