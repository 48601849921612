import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Dialog } from '../../../../components/Dialog';
import NumberField from '../../../../components/formFields/NumberField';
import { PurchaseListItem } from './CreateOrderDialog';
import { QuoteBuilderResponseItem } from '../../../../services/backendTypes';

interface Props {
    open: boolean;
    purchaseList: PurchaseListItem[];
    quoteBuilderResponseItem: QuoteBuilderResponseItem;
    onClose: () => void;
    onChangePurchaseList: (materialId: string, quantity: number) => void;
}

export default function EditBedDialog(props: Props) {
    return (
        <Dialog
            title={`Edit bed (${props.quoteBuilderResponseItem?.quantity}x)`}
            className="c--edit-bed-dialog"
            disableContentPadding
            open={props.open}
            onClose={props.onClose}
            actions={[
                {
                    onClick: props.onClose,
                    children: 'Done',
                },
            ]}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Base</TableCell>
                        <TableCell>Material Name</TableCell>
                        <TableCell>Total Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.quoteBuilderResponseItem?.materials?.map((material, index) => {
                        const purchaseListItemIndex = props.purchaseList?.findIndex((listItem) => listItem.id === material.id);
                        const totalItemQuantity = material.quantity * props.quoteBuilderResponseItem.quantity;

                        if (material.metaData.materialName === 'Bed - Kit/Bundle') {
                            return null;
                        }

                        return (
                            <TableRow key={index}>
                                <TableCell>{material.metaData.blockName}</TableCell>
                                <TableCell>{material.metaData.materialName}</TableCell>
                                <TableCell>
                                    <NumberField
                                        min={0}
                                        max={totalItemQuantity}
                                        value={props.purchaseList?.[purchaseListItemIndex].quantity}
                                        onChange={(quantity) => {
                                            if (quantity >= 0 && quantity <= totalItemQuantity) {
                                                props.onChangePurchaseList(material.id, quantity);
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Dialog>
    );
}
