import { Quote, QuoteItem } from '../services/backendTypes';
import { LegacyQuote } from '../services/legacyBackendTypes';

export function convertLegacyQuote(quote: LegacyQuote): Quote {
    const { beds, accessories, ...other } = quote;
    const items: QuoteItem[] = [];

    beds.forEach((bed) => {
        items.push({ ...bed as any, itemType: 'bed' });
    });

    Object.keys(accessories).forEach((categoryName) => {
        accessories[categoryName].forEach((accessory) => {
            items.push({ ...accessory as any, itemType: 'accessory' });
        });
    });

    return { ...other, items };
}
