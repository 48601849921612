import pickBy from 'lodash/pickBy';
import isString from 'lodash/isString';

export interface Validator {
    validate: (value: any) => null | {errorMessage: string};
}

interface ValidationResults {
    [id: string]: string[] | null;
}

interface ChangeHandlers {
    [name: string]: {
        handler: ChangeHandler;
        excludeFilter: string;
    };
}

type ChangeHandler = (valid: boolean) => void;

class FormService {

    private form: ValidationResults = {};
    private changeHandlers: ChangeHandlers = {};

    private runValidators(value: any, validators: Validator[]): string[] | null {
        const errors: string[] = validators
            .map((validator: Validator) => {
                const result = validator.validate(value);
                return result === null ? null : result.errorMessage;
            })
            .filter((error) => {
                return error !== null;
            });
        return errors.length ? errors : null;
    }

    public validate(id: string, value: any, validators: Validator[]): string | null {
        if (id && validators) {
            const errors = this.runValidators(value, validators);
            this.runChangeHandlers();
            this.form[id] = errors;
            return errors ? errors[0] : null;
        } else {
            return null;
        }
    }

    public remove(id: string) {
        if (this.form[id]) {
            delete this.form[id];
        }
        this.runChangeHandlers();
    }

    private includeFields(form: ValidationResults, filter?: string): ValidationResults {
        if (isString(filter)) {
            const regexp = new RegExp(filter);
            return pickBy(form, (value: any, key: string) => {
                return regexp.test(key);
            });
        } else {
            return form;
        }
    }

    private excludeFields(form: ValidationResults, filter?: string): ValidationResults {
        if (isString(filter)) {
            const regexp = new RegExp(filter);
            return pickBy(form, (value: any, key: string) => {
                return !regexp.test(key);
            });
        } else {
            return form;
        }
    }

    public isValid(includeFilter?: string, excludeFilter?: string): boolean {
        const excludeResult = this.excludeFields(this.form, excludeFilter);
        const includeResult = this.includeFields(excludeResult, includeFilter);
        return Object.keys(includeResult).every((key: string) => {
            return includeResult[key] === null;
        });
    }

    public registerChangeHandler(name: string, handler: ChangeHandler, excludeFilter: string) {
        this.changeHandlers[name] = { handler, excludeFilter };
        handler(this.isValid(null, excludeFilter));
    }

    public unregisterChangeHandler(name: string) {
        if (this.changeHandlers[name]) {
            delete this.changeHandlers[name];
        }
    }

    private runChangeHandlers() {
        const keys = Object.keys(this.changeHandlers);
        if (!keys.length) {
            return;
        }
        for (const key of keys) {
            const valid = this.isValid(null, this.changeHandlers[key].excludeFilter);
            this.changeHandlers[key].handler(valid);
        }
    }

}

export const formService = new FormService();
