import { createReducer } from '@reduxjs/toolkit';
import { OrderFormData } from '../../../../containers/reducers';
import { Quote } from '../../../../services/backendTypes';
import * as summaryDialogActions from './actions';

interface SummaryDialogReduxState {
    visible: boolean;
    quote: OrderFormData | Quote;
}

const initialState = {
    visible: false,
    quote: {
        items: [],
    },
} as SummaryDialogReduxState;

export const summaryDialog = createReducer(initialState, (builder) => {
    builder.addCase(summaryDialogActions.openSummaryDialog, (state, action) => {
        state.visible = true;
        state.quote = action.payload;
    });
    builder.addCase(summaryDialogActions.closeSummaryDialog, (state) => {
        state.visible = false;
        state.quote = initialState.quote;
    });
});
