import { ReactElement } from 'react';
import { AuthCustomer, AuthPermission } from '../../services/backendTypes';

interface CanProps {
    customer: AuthCustomer;
    permission: AuthPermission;
    children: ReactElement;
}

export function can(customer: AuthCustomer, permission: AuthPermission): boolean {
    const permissions = customer?.role || {};
    return Boolean(permissions[permission]);
}

export function Can({ customer, permission, children }: CanProps) {
    return can(customer, permission) ? children : null;
}

