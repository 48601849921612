import { errorMessages } from '../constants/errorMessages';
import { Validator } from '../services/formService';

export const disabledValidator = (menuItems: any[], itemValue: string): Validator => ({
    validate: (value: string | number) => {
        const error = {
            errorMessage: errorMessages.disabled,
        };
        if (!menuItems) {
            return null;
        }
        const menuItemIsDisabled = !!menuItems.find((menuItem) => {
            return menuItem.disabled && menuItem[itemValue] === value;
        });
        return menuItemIsDisabled ? error : null;
    },
});
