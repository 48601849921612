export const BED_PROPERTIES = [
    {
        title: 'Type',
        key: 'type',
    },
    {
        title: 'Width',
        key: 'width',
    },
    {
        title: 'Length',
        key: 'length',
    },
    {
        title: 'Color',
        key: 'color',
    },
    {
        title: 'Firmness',
        key: 'firmness',
    },
    {
        title: 'Split base',
        key: 'split_base',
    },
    {
        title: 'Split mattress',
        key: 'split_mattress',
    },
    {
        title: 'Split top mattress',
        key: 'split_top_mattress',
    },
    {
        title: 'Top mattress type',
        key: 'top_mattress_type',
    },
    {
        title: 'Nameplate type',
        key: 'nameplate_type',
    },
    {
        title: 'Nameplate',
        key: 'nameplate',
    },
    {
        title: 'Nameplate left base',
        key: 'left_nameplate',
    },
    {
        title: 'Nameplate right base',
        key: 'right_nameplate',
    },
    {
        title: 'Legs',
        key: 'legs',
    },
    {
        title: 'Middle leg link',
        key: 'middle_leg_link',
    },
    {
        title: 'Quantity',
        key: 'quantity',
    },
];
