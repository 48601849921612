import React, { Component, Fragment, ReactElement } from 'react';

import { formatPrice } from '../../../../functions/formatPrice';
import { Spinner, SpinnerProps } from '../../../../components/Spinner/Spinner';
import { EndConsumerPrice } from '../EndConsumerPrice';
import { OrderFormAccessoryItem, SimplifiedQuoteBuilderResponseItem } from '../../reducer';

export interface AccessoryTotalProps {
    data: OrderFormAccessoryItem;
    simplifiedQuoteBuilderResponseItem: SimplifiedQuoteBuilderResponseItem;
    quoteBuilderResponseSpinner: SpinnerProps;
    disabled: boolean;
    onChangeEndConsumerPrice: (value) => void;
    onChangeEndConsumerDiscount: (value) => void;
    hideWholesalePrices: boolean;
    hideEndConsumerPrices: boolean;
    onDebugClick: () => void;
}

export class AccessoryTotal extends Component<AccessoryTotalProps> {

    static defaultProps = {
        simplifiedQuoteBuilderResponseItem: {},
    };

    render(): ReactElement {
        const { price, discountedPrice, recommendedPrice, currency, valid, appliedCampaigns } = this.props.simplifiedQuoteBuilderResponseItem;

        return (
            <dl className="c--accessory-total">
                {appliedCampaigns?.length > 0 && this.props.hideWholesalePrices === false && [
                    <dt key="0">Campaign:</dt>,
                    <dd key="1">{appliedCampaigns.join(', ')}</dd>,
                ]}
                {(this.props.hideWholesalePrices === false) && [
                    <dt key="0"><b>Wholesale price:</b></dt>,
                    <dd key="1">
                        <Spinner
                            {...this.props.quoteBuilderResponseSpinner}
                            defaultElement={(
                                <Fragment>
                                    <span
                                        className="new-price"
                                        onClick={(event) => {
                                            if (event.altKey) {
                                                this.props.onDebugClick();
                                            }
                                        }}>
                                        {discountedPrice && valid ? formatPrice(discountedPrice, currency) : '---'}
                                    </span>
                                    {discountedPrice !== price && valid && (
                                        <span className="old-price">
                                            <s>{formatPrice(price, currency)}</s>
                                        </span>
                                    )}
                                </Fragment>
                            )}
                        />
                    </dd>,
                ]}
                {(this.props.hideEndConsumerPrices === false) && [
                    <dt key="0"><b>Price:</b></dt>,
                    <dd key="1">
                        <Spinner
                            {...this.props.quoteBuilderResponseSpinner}
                            defaultElement={(
                                <EndConsumerPrice
                                    id={this.props.data._key}
                                    disabled={this.props.disabled || valid === false}
                                    recommendedPrice={valid ? recommendedPrice: 0}
                                    endConsumerPrice={valid ? this.props.data.endConsumerPrice : 0}
                                    endConsumerDiscount={this.props.data.endConsumerDiscount}
                                    quotationCurrency={valid ? this.props.data.quotationCurrency : undefined}
                                    onChangeEndConsumerPrice={this.props.onChangeEndConsumerPrice}
                                    onChangeEndConsumerDiscount={this.props.onChangeEndConsumerDiscount}
                                />
                            )}
                        />
                    </dd>,
                ]}
            </dl>
        );
    }

}


