import React, { PropsWithChildren, useEffect } from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Button from './formFields/Button';

interface Props {
    open: boolean;
    size?: 'small' | 'medium' | 'fullscreen';
    title: string;
    className?: string;
    actionsClassName?: string;
    disableContentPadding?: boolean;
    onOpen?: () => void;
    onClose: () => void;
    actions?: {
        children: string;
        onClick: () => void;
    }[];
}

Dialog.defaultProps = {
    open: false,
    size: 'medium',
    actions: [],
    disableContentPadding: false,
} as Props;

export function Dialog(props: PropsWithChildren<Props>) {

    useEffect(() => {
        if (props.open) {
            document.body.classList.add('dialog-is-open');
            if (props.onOpen) {
                props.onOpen();
            }
        } else {
            document.body.classList.remove('dialog-is-open');
        }
    }, [props.open]);

    return (
        <MuiDialog
            open={props.open}
            onClose={props.onClose}
            className={props.className}
            sx={{
                '@media print': {
                    position: 'static !important',
                },
            }}
            BackdropProps={{
                sx: {
                    '@media print': {
                        display: 'none',
                    },
                },
            }}
            PaperProps={{
                sx: {
                    width: () => {
                        switch (props.size) {
                            case 'fullscreen': {
                                return '100%';
                            }
                            case 'small': {
                                return 600;
                            }
                            default: {
                                return 700;
                            }
                        }
                    },
                    height: () => {
                        return props.size === 'fullscreen' ? '100%' : null;
                    },
                    maxWidth: 'none',
                    maxHeight: 'calc(100% - 30px)',
                    margin: '15px',
                    '@media print': {
                        margin: 0,
                    },
                },
            }}>
            <DialogTitle
                sx={{
                    backgroundColor: '#000030',
                    padding: '30px',
                    color: '#fff',
                    fontWeight: 500,
                    fontSize: '30px',
                    textAlign: 'center',
                }}>
                {props.title}
            </DialogTitle>
            <DialogContent sx={{ padding: props.disableContentPadding ? 0 : '20px 30px 30px' }}>
                {props.children}
            </DialogContent>
            {!!props.actions.length && (
                <DialogActions
                    className={props.actionsClassName}
                    sx={{
                        padding: '18px 30px',
                        '@media print': {
                            display: 'none',
                        },
                    }}>
                    {props.actions.map((action, index) => (
                        <Button key={index} onClick={action.onClick}>
                            {action.children}
                        </Button>
                    ))}
                </DialogActions>
            )}
        </MuiDialog>
    );

}
