import React from 'react';
import MuiRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    id?: string;
    name?: string;
    label: string;
    checked: boolean;
    value?: string;
    onChange: (value) => void;
}

export default function Radio(props: Props) {
    return (
        <FormControlLabel
            label={props.label}
            componentsProps={{
                typography: {
                    sx: {
                        fontWeight: 300,
                        fontSize: '13px',
                    },
                },
            }}
            control={
                <MuiRadio
                    id={props.id}
                    name={props.name}
                    color="primary"
                    size="small"
                    value={props.value}
                    checked={props.checked}
                    onChange={(event) => {
                        props.onChange(event.target.value);
                    }}
                />
            }
        />
    );
}
