import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DatePickerToolbarProps } from '@mui/x-date-pickers/DatePicker';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { ThemeProvider } from '@mui/material/styles';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

import { lightTheme } from '../../muiTheme';

export interface DatePickerProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
}

const dateFormat = 'yyyy-MM-dd';

export function DatePicker(props: DatePickerProps) {
    const outerValue = props.value ? parse(props.value, dateFormat, new Date()) : null;

    return (
        <MobileDatePicker
            label={props.label}
            format={dateFormat}
            openTo="day"
            views={['day']}
            dayOfWeekFormatter={(day) => day}
            slots={{
                dialog: DatePickerDialog,
                toolbar: DatePickerToolbar,
            }}
            slotProps={{
                textField: {
                    variant: 'standard',
                    InputProps: {
                        endAdornment: !props.value ? null : (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    edge="end"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        props.onChange(null);
                                    }}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                },
            }}
            value={outerValue}
            onChange={(value) => {
                if (isValid(value)) {
                    props.onChange(format(value, dateFormat));
                } else {
                    props.onChange(null);
                }
            }}
        />
    );
}

function DatePickerDialog(props: DialogProps) {
    return (
        <ThemeProvider theme={lightTheme}>
            <Dialog {...props} />
        </ThemeProvider>
    );
}

function DatePickerToolbar(props: DatePickerToolbarProps<Date>) {
    return (
        <Box
            sx={{
                gridArea: '1 / 2 / auto / 4',
                padding: '17px 24px',
                backgroundColor: '#000030',
                color: '#fff',
                fontWeight: 400,
            }}>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    fontSize: '16px',
                    opacity: 0.54,
                    minHeight: 24,
                }}>
                {props.value ? format(props.value, 'y') : ''}
            </Box>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    fontSize: '34px',
                    minHeight: 51,
                }}>
                {props.value ? format(props.value, 'EEE, LLL d') : '---'}
            </Box>
        </Box>
    );
}
