import React, { Fragment, MouseEvent, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import Button, { ButtonProps } from './Button';
import { NotAvailableIcon } from '../NotAvailableIcon';

interface Props {
    buttonProps: Omit<ButtonProps, 'onClick'>;
    menuItems: {
        primaryText: string;
        onClick: (event: MouseEvent) => void;
        active?: boolean;
        disabled?: boolean;
        notAvailable?: boolean;
    }[];
}

export default function MenuButton(props: Props) {
    const [anchorEl, setAnchorEl] = useState<Element>(null);

    return (
        <Fragment>
            <Button
                {...props.buttonProps}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget as Element);
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}>
                {props.menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={index}
                        selected={menuItem.active}
                        disabled={menuItem.disabled || menuItem.notAvailable}
                        onClick={(event) => {
                            setAnchorEl(null);
                            if (menuItem.onClick) {
                                menuItem.onClick(event);
                            }
                        }}>
                        {menuItem.notAvailable && (
                            <ListItemIcon sx={{ minWidth: '26px' }}>
                                <NotAvailableIcon size="small" />
                            </ListItemIcon>
                        )}
                        {menuItem.primaryText}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );

}
